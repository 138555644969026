const app = angular.module('editor');

class AccountSettingProfileController {
    constructor($scope, $rootScope, $event, $alert, $storage, ApiService) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$event = $event;
        this.$alert = $alert;
        this.$storage = $storage;
        this.ApiService = ApiService;
        this.$scope.UI = {};

        if ($rootScope.__.CheckLogin('/account/setting/profile')) this.init();
        else $event.on('login', 'AccountSettingProfileController.login', () => this.init());
    }

    init() {
        this.$scope.user = {
            userId: this.$rootScope.__.ME.userId,
            uid: this.$rootScope.__.ME.uid,
            headimgurl: this.$rootScope.__.ME.others.headimgurl,
            nickname: this.$rootScope.__.ME.others.nickname,
            phone: this.$rootScope.__.ME.phone,
            email: this.$rootScope.__.ME.email,
            role: this.$rootScope.__.ME.author,
        };
    }
}

AccountSettingProfileController.$inject = ['$scope', '$rootScope', '$event', '$alert', '$storage', 'ApiService'];
app.controller('AccountSettingProfile', AccountSettingProfileController);

