const app = angular.module('editor');
import GameEditorController from '../GameEditorController';
import GameEditorPreviewSubwayController from './GameEditPreviewSubwayController';

class GameEditorPreviewController extends GameEditorController {
    constructor($scope, $rootScope, $window, $routeParams, $timeout, $event, $alert, $modal, $storage, ApiService) {
        super($scope, $rootScope, $window, $routeParams, $timeout, $event, $alert, $modal, $storage, ApiService);

        $scope.EditorMode.select = 'preview';

        $scope.preview = {
            gameStyle: {},
            selectTab: 'module',
            selectMissionId: '',
            selectModuleId: '',
            selectPropId: '',
            selectAchievementId: '',
            gameMissionModuleIdList: [],
            gameBranchChooseConfig: $storage.getObject('gameBranchChooseConfig_' + $scope._.gameId) || {},
            isObject: v => typeof v === 'object',
            getDataType: v => typeof v,
        };

        this.$scope.$watch('_.editorGame', game_string => {
            if (!game_string) return console.log('游戏数据加载中...');
            this.loadGameData(game_string);
        });
    }


    loadGameData(game_string) {
        let game = JSON.parse(game_string);
        this.$scope.preview.game = game;
        switch (game.gameType) {
            case 'marvel':
                break;
            case 'gugong':
                break;
            case 'chinatown':
                new GameEditorPreviewSubwayController(this.$scope, this.$rootScope, this.$window, this.$routeParams, this.$timeout, this.$event, this.$alert, this.$modal, this.$storage, this.ApiService);
                break;
            case 'subway':
                new GameEditorPreviewSubwayController(this.$scope, this.$rootScope, this.$window, this.$routeParams, this.$timeout, this.$event, this.$alert, this.$modal, this.$storage, this.ApiService);
                break;
            case 'subway2':
                new GameEditorPreviewSubwayController(this.$scope, this.$rootScope, this.$window, this.$routeParams, this.$timeout, this.$event, this.$alert, this.$modal, this.$storage, this.ApiService);
                break;
            default:
                break;
        }
        this.$timeout(() => this._addDragEvent($(".mobile-phone-simulator")), 500);
    }

    _addDragEvent(dom) {
        if (dom.dragEvent) return;
        dom.dragEvent = true;
        dom.bind("mousedown", start);
        let gapY, startY;

        function start(event) {
            if (event.button === 0) {//鼠标左键点击
                gapY = event.clientY;
                startY = dom.scrollTop();
                $(document).bind("mousemove", move);
                $(document).bind("mouseup", stop);
            }
            return false;
        }

        function move(event) {
            dom.scrollTop(startY - (event.clientY - gapY));
            return false;
        }

        function stop() {
            $(document).unbind("mousemove", move);
            $(document).unbind("mouseup", stop);
        }
    }
}

GameEditorPreviewController.$inject = ['$scope', '$rootScope', '$window', '$routeParams', '$timeout', '$event', '$alert', '$modal', '$storage', 'ApiService'];
app.controller('GameEditorPreview', GameEditorPreviewController);

