const app = angular.module('editor');
import GameDetailController from './GameDetailController';

class GameDetailPlayerController extends GameDetailController {
    constructor($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util) {
        super($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util);
        this.$scope.UI = {
            breadcrumb_game_text: {
                cn: '游戏',
                en: 'Games',
            },
            breadcrumb_tab_text: {
                cn: '玩家',
                en: 'Player',
            },
        };
        this.$scope._.tabName = 'player';

        this.$scope._.size = 0;
        this.$scope._.data = [];

        this.initObject().then();

        this.initData().then();
    }

    async initObject() {
        let self = this;
        this.$scope._.Search = {
            value: '',
            async submit() {
                await self.initData(this.value);
            }
        };
        this.$scope._.Player = {
            parseUID(id) {
                return self.$util.addPreZero(id, 7);
            },
            async deleteGameRecord(playerId) {
                self.$modal('即将清除该玩家的游戏记录', '删除后无法恢复，确定删除吗？');
                self.$modal.on_ok = async () => {
                    await self.ApiService.deletePlayerGameRecord(self.$scope._.gameId, playerId);
                    await self.initData();
                    self.$modal.hide();
                };
            }
        };
    }

    async initData(uid) {
        this.$scope._.onPageChange = async page => {
            try {
                this.$scope._.data = await this.ApiService.getGamePlayers(this.$scope._.gameId, page, uid);
                this.$scope.$apply();
            } catch (error) {
                console.error(error);
                this.$alert.error(error.data && error.data.status && error.data.status.message);
            }
        };

        if (!this.$scope._.size) this.$scope._.size = await this.ApiService.countGamePlayers(this.$scope._.gameId);
        if (uid) this.$scope._.size = 1;


        this.$scope._.onPageChange(1);
    }
}

GameDetailPlayerController.$inject = ['$scope', '$rootScope', '$location', '$window', '$routeParams', '$timeout', 'ApiService', '$event', '$alert', '$modal', '$util'];
app.controller('GameDetailPlayer', GameDetailPlayerController);
