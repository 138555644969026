const app = angular.module('editor');

class PluginDetailController {
    constructor($scope, $rootScope, $routeParams, $timeout, $event, $alert, $util, $window, ApiService) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$routeParams = $routeParams;
        this.$timeout = $timeout;
        this.$event = $event;
        this.$alert = $alert;
        this.$util = $util;
        this.$window = $window;
        this.ApiService = ApiService;
        $scope.UI = {
            breadcrumb_game_text: {
                cn: '插件',
                en: 'Plugin',
            },
        };
        this.$scope.pluginId = this.$routeParams.pluginId;

        if ($rootScope.__.CheckLogin('/plugin/list')) this.init();
        else $event.on('login', 'PluginDetailController.login', () => this.init());
    }

    init() {
        this.$scope.plugin = {};
        this.$scope.canUsePluginGames = [];
        this.$scope.usePluginGames = [];

        this.$scope.showCanUsePluginGamesModal = () => {
            $('#plugin_can_game_list_modal').modal('show');
        };

        this.$scope.setGameUsePlugin = async gameId => {
            await this.ApiService.postEditorGamePlugin(gameId, this.$scope.pluginId);
            this.initData().then();
        };

        this.$scope.unsetGameUsePlugin = async gameId => {
            await this.ApiService.deleteEditorGamePlugin(gameId, this.$scope.pluginId);
            this.initData().then();
        };

        this.initData().then();
    }

    async initData() {
        // 插件信息
        this.$scope.plugin = await this.ApiService.getEditorPlugin(this.$scope.pluginId);
        // 加载用户参与的游戏信息
        await this.ApiService._refreshGamesSynopsis();
        // 获得正在使用插件的游戏列表
        this.$scope.usePluginGames = [];
        let usePluginGameIds = await this.ApiService.getEditorPluginGames(this.$scope.pluginId);
        for (let {gameId, createdAt} of usePluginGameIds) {
            this.$scope.usePluginGames.push({
                gameId: gameId,
                gameName: this.$rootScope.__.GAMES[gameId].dev.gameName,
                coverImage: this.$rootScope.__.GAMES[gameId].dev.coverImage,
                releaseName: this.$rootScope.__.GAMES[gameId].dev.releaseName,
                createdAt: createdAt,
            });
        }
        // 获取可使用插件的游戏列表
        this.loadCanUsePluginGames();
        this.$scope.$apply();
    }

    loadCanUsePluginGames() {
        this.$scope.canUsePluginGames = [];
        let conditions = this.$scope.plugin.conditions || [];
        for (let gameId in this.$rootScope.__.GAMES) {
            let game = this.$rootScope.__.GAMES[gameId].dev;
            let can = true;
            for (let condition of conditions) {
                if (condition.key) {
                    let arr = condition.key.split('.');
                    let gameValue = this._getValue(game, arr);
                    if (gameValue !== condition.value) {
                        can = false;
                        break;
                    }
                }
            }
            for (let game of this.$scope.usePluginGames) {
                if (game.gameId === gameId) {
                    can = false;
                    break;
                }
            }
            if (!can) continue;
            this.$scope.canUsePluginGames.push({
                gameId: game.gameId,
                gameName: game.gameName,
                coverImage: game.coverImage,
                releaseName: game.releaseName,
            });
        }
    }

    _getValue(obj, arr) {
        let attr = arr.shift();
        obj = obj[attr];
        if (typeof obj === 'undefined') return obj;
        if (arr.length > 0) return this._getValue(obj, arr);
        return obj;
    }
}

PluginDetailController.$inject = ['$scope', '$rootScope', '$routeParams', '$timeout', '$event', '$alert', '$util', '$window', 'ApiService'];
app.controller('PluginDetail', PluginDetailController);
