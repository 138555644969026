export default class GameEditorPreviewSubwayController {
    constructor($scope, $rootScope, $window, $routeParams, $timeout, $event, $alert, $modal, $storage, ApiService) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$window = $window;
        this.$routeParams = $routeParams;
        this.$timeout = $timeout;
        this.$event = $event;
        this.$alert = $alert;
        this.$modal = $modal;
        this.$storage = $storage;
        this.ApiService = ApiService;

        this.init();
    }

    init() {
        this.$scope.preview.onMissionSelect = missionId => {
            if (missionId !== this.$scope.preview.selectMissionId) {
                this.$scope.preview.selectMissionId = missionId;
                this.loadMissionData(this.$scope.preview.selectMissionId);
                // 回到最上方
                $(".mobile-phone-simulator").scrollTop(0);
            }
        };
        this.$scope.preview.onModuleDblclick = module => {
            this.$scope.preview.selectModuleId = module.moduleId;
        };
        this.$scope.preview.onPropDblclick = propId => {
            this.$scope.preview.selectPropId = propId;
        };
        this.$scope.preview.onAchievementDblclick = achievementId => {
            this.$scope.preview.selectAchievementId = achievementId;
        };
        this.$scope.preview.branchChoose = (module, key) => {
            let storage_gameBranchChooseConfig = this.$storage.getObject('gameBranchChooseConfig_' + this.$scope._.gameId) || {};
            if (storage_gameBranchChooseConfig[module.moduleId] !== key) {
                storage_gameBranchChooseConfig[module.moduleId] = key;
            }
            this.$storage.setObject('gameBranchChooseConfig_' + this.$scope._.gameId, storage_gameBranchChooseConfig);
            if (this.$scope.preview.gameBranchChooseConfig[module.moduleId] !== key) {
                this.$scope.preview.gameBranchChooseConfig[module.moduleId] = key;
                this.loadMissionData(this.$scope.preview.selectMissionId);
            }
        };

        this.$scope.preview.parseResourceUrl = value => {
            if (value.startsWith('http')) return value;
            return this.$scope.preview.game.others.resourcePrefix + value;
        };

        this.$scope.preview.gameStyle = GameEditorPreviewSubwayController.getGameStyle(this.$scope.preview.game);
        this.$scope.preview.selectMissionId = GameEditorPreviewSubwayController.getGameStartMissionId(this.$scope.preview.game);
        this.$scope.preview.gameBranchChooseConfig = GameEditorPreviewSubwayController.parseGameBranchChooseConfig(this.$scope.preview.game, this.$scope.preview.gameBranchChooseConfig);
        this.loadMissionData(this.$scope.preview.selectMissionId);
    }

    loadMissionData(missionId) {
        this.$scope.preview.gameMissionModuleIdList = GameEditorPreviewSubwayController.getGameMissionModuleIdList(
            this.$scope.preview.game,
            missionId,
            this.$scope.preview.gameBranchChooseConfig
        );
    }


    static getGameStyle(game) {
        let style = {};
        style['background'] = `url(${game.data.config.STYLE.background}) no-repeat`;
        style['background-size'] = '100% 100%';
        return style;
    }

    static getGameStartMissionId(game) {
        return game.data.config.START_TASK_ID;
    }

    static getGameMissionModuleIdList(game, missionId, gameBranchChooseConfig) {
        let missionModuleIdList = [];
        let startModuleId = game.data.config.START_MODULE_ID[missionId];
        let endModuleId = game.data.config.END_MODULE_ID[missionId];
        let moduleId = startModuleId;
        let i = 0;
        while (moduleId !== endModuleId) {
            if (i++ > 10000) throw Error('未找到结束模块: ' + endModuleId);
            missionModuleIdList.push(moduleId);
            moduleId = GameEditorPreviewSubwayController._loadNextModuleId(game, moduleId, gameBranchChooseConfig);
        }
        missionModuleIdList.push(endModuleId);
        return missionModuleIdList;
    }

    static _loadNextModuleId(game, moduleId, gameBranchChooseConfig) {
        let module = game.data.modules[moduleId];
        if (!module) throw Error('module不存在:' + moduleId);
        if (module.next) return module.next;
        if (typeof module.value === 'object') {
            if (gameBranchChooseConfig.hasOwnProperty(moduleId)) {
                if (module.type === 'choose') {
                    return Object.values(module.value)[gameBranchChooseConfig[moduleId]];
                }
                if (module.type === 'input') {
                    return module.value[gameBranchChooseConfig[moduleId]];
                }
            }
            return Object.values(module.value)[0];
        }
        if (module.type === 'condition') {
            if (gameBranchChooseConfig.hasOwnProperty(moduleId)) {
                return module.wayOut[gameBranchChooseConfig[moduleId]];
            }
            return Object.values(module.wayOut)[0];
        }
        throw Error('module已经是最终:' + moduleId);
    }

    static parseGameBranchChooseConfig(game, gameBranchChooseConfig) {
        let modules = game.data.modules;
        for (let moduleId in modules) {
            let module = modules[moduleId];
            if (!gameBranchChooseConfig.hasOwnProperty(moduleId)) {
                if (module.type === 'choose') {
                    gameBranchChooseConfig[moduleId] = 0;
                }
                if (module.type === 'input') {
                    gameBranchChooseConfig[moduleId] = Object.keys(module.value)[0];
                }
            }
        }
        return gameBranchChooseConfig;
    }

}
