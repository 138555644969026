const app = angular.module('editor');

class MessageAccountController {
    constructor($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util, $progress) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.$window = $window;
        this.$routeParams = $routeParams;
        this.$timeout = $timeout;
        this.ApiService = ApiService;
        this.$event = $event;
        this.$alert = $alert;
        this.$modal = $modal;
        this.$util = $util;
        this.$progress = $progress;
        this.$scope.UI = {};
        this.$scope.messages = [];
        this.$scope._ = {
            size: 0,
            page: 1,
            readMessage: async (message, index) => {
                if (message.status === 'unread') {
                    let result = await this.ApiService.updateEditorMessageStatus([message.msgId], 'read');
                    if (result.nModified > 0) {
                        this.$scope.messages[index].status = 'read';
                        this.$rootScope.__.UNREAD_MESSAGE_NUMBER = await this.ApiService.countEditorUserMessage('unread');
                        this.$scope.$apply();
                    }
                }
            }
        };
        if ($rootScope.__.CheckLogin('/account/message')) this.init().then();
        else $event.on('login', 'MessageAccountController.login', () => this.init());
    }

    async init() {
        this.$scope._.onPageChange = async page => {
            try {
                this.$scope.messages = await this.ApiService.getEditorUserMessage(page);
                this.$scope.$apply();
            } catch (error) {
                console.error(error);
                this.$alert.error(error.data && error.data.status && error.data.status.message);
            }
        };
        this.$scope._.size = await this.ApiService.countEditorUserMessage();
        this.$scope._.onPageChange(1);
    }

}

MessageAccountController.$inject = ['$scope', '$rootScope', '$location', '$window', '$routeParams', '$timeout', 'ApiService', '$event', '$alert', '$modal', '$util', '$progress'];
app.controller('MessageAccountController', MessageAccountController);