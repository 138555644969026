const app = angular.module('editor');
import GameDetailController from './GameDetailController';

class GameDetailActivityController extends GameDetailController {
    constructor($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util, $user) {
        super($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util, $user);
        this.$scope.UI = {
            breadcrumb_game_text: {
                cn: '游戏',
                en: 'Games',
            },
            breadcrumb_tab_text: {
                cn: '评论',
                en: 'Comment',
            },
        };
        this.$scope._.tabName = 'activity';


        this.$scope.Activity = {
            number: 0,
            data: [],
            delCommentReplay: (commentId, replyId) => {
                this.$modal(`即将删除该回复。`, '删除后无法恢复，确定删除吗？');
                this.$modal.on_ok = async () => {
                    await this.delCommentReplay(commentId, replyId);
                    this.$modal.hide();
                };
            },
            delComment: (commentId) => {
                this.$modal(`即将删除该评论。`, '删除后无法恢复，确定删除吗？');
                this.$modal.on_ok = async () => {
                    await this.delComment(commentId);
                    this.$modal.hide();
                };
            },
            Search: {
                value: '',
                submit: () => {
                    if (!this.$scope.Activity.Search.value) {
                        this.gameCommentList(1, 5).then();
                    } else {
                        this.searchComment().then();
                    }
                }
            },
            onPageChange: page => {
                this.updatePage(page).then();
            },
        };

        // 进入评论管理，自动获取评论列表
        this.gameCommentList(1, 5).then();
    }


    // 翻页
    async updatePage(currentPage = 1, pageSize = 5) {
        if (this.$scope.Activity.Search.value) {
            await this.searchComment(currentPage, pageSize);
        } else {
            await this.gameCommentList(currentPage, pageSize);
        }
    }

    //根据指定昵称搜索评论
    async searchComment(currentPage = 1, pageSize = 5) {
        let result = await this.ApiService.getGameCommunityActivityListByNickname(this.$scope._.gameId, currentPage, pageSize, this.$scope.Activity.Search.value);
        await this.updateData(result);
    }

    // 获取评论列表
    async gameCommentList(currentPage, pageSize) {
        let result = await this.ApiService.gameCommentList(this.$scope._.gameId, currentPage, pageSize);
        await this.updateData(result);
    }

    async updateData({data, number}) {
        for (let activity of data) {
            activity.user = await this.$user.getUserByServer({userId: activity.userId});
            if (activity.replies) {
                for (let reply of activity.replies) {
                    if (reply.commentUserId) {
                        reply.commentUser = await this.$user.getUserByServer({userId: reply.commentUserId});
                    }
                    if (reply.replyUserId) {
                        reply.replyUser = await this.$user.getUserByServer({userId: reply.replyUserId});
                    }
                }
            }
        }
        this.$scope.Activity.number = number;
        this.$scope.Activity.data = data;
        this.$scope.$apply();
    }
}

GameDetailActivityController.$inject = ['$scope', '$rootScope', '$location', '$window', '$routeParams', '$timeout', 'ApiService', '$event', '$alert', '$modal', '$util', '$user'];
app.controller('GameDetailActivity', GameDetailActivityController);
