const app = angular.module('editor');

class XiuMiListController {
    constructor($scope, $rootScope, $event, $location, $alert, $storage, ApiService, $timeout, $window) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$event = $event;
        this.$location = $location;
        this.$alert = $alert;
        this.$storage = $storage;
        this.ApiService = ApiService;
        this.$timeout = $timeout;
        this.$window = $window;
        this.$scope.UI = {};
        this.$scope.Tags = [];

        if ($rootScope.__.CheckLogin('/xiumi/list')) this.init();
        else $event.on('login', 'XiuMiListController.login', () => this.init());
    }

    init() {
        this.$scope.page = 1
        this.$scope.pageSize = 10
        this.$scope.collectionSize = 0
        this.$scope.searchTitle = ''
        this.$scope.copyResource = ''
        this.getXiuMiArticleList(1, this.$scope.pageSize);
        this.$scope.deleteArticle = this.deleteArticle.bind(this);
        this.$scope.showPreviewHTML = this.showPreviewHTML.bind(this);
        this.$scope.toXiuMi = this.toXiuMi.bind(this);
        this.$scope.search = this.search.bind(this);
        this.$scope.refresh = this.refresh.bind(this);
        this.$scope.pageChange = this.pageChange.bind(this);
        this.$scope.copy = this.copy.bind(this);
    }

    search() {
        this.getXiuMiArticleList(1, this.$scope.pageSize, this.$scope.searchTitle);
    }

    refresh() {
        this.getXiuMiArticleList(1, this.$scope.pageSize);
        this.$scope.page = 1;
    }

    pageChange() {
        this.getXiuMiArticleList(this.$scope.page, this.$scope.pageSize)
    }

    async getXiuMiArticleList(page, pageSize, title) {
        let {list, counts, loginUrl} = await this.ApiService.getXiuMiArticleList(page, pageSize, title)
        this.$scope.articles = list
        this.$scope.collectionSize = counts
        this.$scope.loginUrl = loginUrl
        this.$scope.$apply();
    }

    async deleteArticle(from_id) {
        let msg = '确定删除此条资讯！删除后需要前往秀米进行同步操作。';
        if (confirm(msg)) {
            let result = await this.ApiService.deleteXiuMiArticle(from_id);
            if (result.ok === 1) {
                this.$alert('删除成功');
                await this.pageChange();
            } else this.$alert('删除失败请重试');
        } else return false
    }

    showPreviewHTML(article) {
        this.$scope.previewHTML = article.description;
    }

    toXiuMi(type, article) {
        if (confirm('即将离开当前页面\n前往秀米官网')) {
            let toUrl = '';
            switch (type) {
                case 'newArticle':
                    toUrl = this.$scope.loginUrl;
                    break;
                case 'toXiuMi':
                    toUrl = "https://xiumi.us/#/studio/papers";
                    break;
                case 'editArticle':
                    toUrl = article.editUrl;
                    break;
            }
            this.$window.open(toUrl, '_blank')
        } else return false
    }

    copy(resource) {
        this.$scope.copyResource = resource
        // 更新dom是异步的
        setTimeout(() => {
            document.getElementById("inputElement").select()
            try {
                if (document.execCommand('copy', false, null)) {
                    console.log(resource);
                    this.$alert('图片链接复制成功')
                } else this.$alert('图片链接复制失败，请点击图片查看链接')
            } catch (e) {
                console.log(e);
                this.$alert('图片链接复制失败，请点击图片查看链接', 'warning')
            }
        }, 500)
    }
}

XiuMiListController.$inject = ['$scope', '$rootScope', '$event', '$location', '$alert', '$storage', 'ApiService', '$timeout', '$window'];
app.controller('XiuMiList', XiuMiListController);

