const app = angular.module('editor');

class GameListController {
    constructor($scope, $rootScope, $timeout, $event, $alert, $util, $window, ApiService) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.$event = $event;
        this.$alert = $alert;
        this.$util = $util;
        this.$window = $window;
        this.ApiService = ApiService;
        $scope.UI = {
            breadcrumb_game_text: {
                cn: '游戏',
                en: 'Games',
            },
        };
        $rootScope.PageNow = 'game_list';
        $scope.search = {
            value: '',
        };
        $scope._ = {
            showTabLv3: true,
            showTabLv4: true,
            showTabLv5: true,
            GameList: [],   //完整游戏列表
            ShowList: [],   //页面显示的游戏列表
            FilterList: [],   //过滤后的游戏列表
            filter: {
                category: '',
                hard: '',
                themes: '',
                entityPackage: '',
                role: '',
                isMultiGame: '',
            },
            resetFilter: () => {
                $scope._.filter = {
                    category: '',
                    hard: '',
                    themes: '',
                    entityPackage: '',
                    role: '',
                    isMultiGame: '',
                };
                $scope.search.value = '';
                $scope._.filterTable();
            },
            filterTable: type => {
                $scope._.FilterList = $scope._.GameList;
                if (type) {
                    let temp_arr = [];
                    for (let item of $scope._.GameList) {
                        if ($scope._.filter.category && $scope._.filter.category !== item.dev.others.category) {
                            continue;
                        }
                        if ($scope._.filter.hard && $scope._.filter.hard !== item.dev.others.hard) {
                            continue;
                        }
                        if ($scope._.filter.themes && !item.dev.others.themes.includes($scope._.filter.themes)) {
                            continue;
                        }
                        if (typeof $scope._.filter.entityPackage === 'number' && $scope._.filter.entityPackage !== item.dev.others.entityPackage) {
                            continue;
                        }
                        if ($scope._.filter.role && $scope._.filter.role !== item.role) {
                            continue;
                        }
                        if (typeof $scope._.filter.isMultiGame === 'boolean' && $scope._.filter.isMultiGame !== !!item.dev.others.isMultiGame) {
                            continue;
                        }
                        if (item.dev && $scope.search.value && !new RegExp($scope.search.value).test(item.dev.gameId) && !new RegExp($scope.search.value).test(item.dev.gameName)) {
                            continue;
                        }
                        temp_arr.push(item)
                    }
                    $scope._.FilterList = temp_arr;
                }
                $scope._.onPageChange(1);
            },
            getTimeInterval: time => $util.getTimeInterval(time, new Date()),
            onPageChange: page => {
                let skip = (page - 1) * 10;
                $scope._.ShowList = $scope._.FilterList.slice(skip, skip + 10);
                $timeout(() => $scope.$apply())
            }
        };

        if ($rootScope.__.CheckLogin('/game/list')) this.init();
        else $event.on('login', 'GameListController.login', () => this.init());

        $timeout(() => $('.dropdown-toggle').dropdown(), 500);
    }

    init() {
        this.getGamesSynopsis().then();
        this.getShowTabLevel();
        this.initObject().then();

        this.$window.onresize = () => this.getShowTabLevel();
        this.$scope.$on('$locationChangeStart', () => {
            this.$window.onresize = null;
        });
        this.$scope.$watch('_.filter.category', value => {
            if (value) this.$scope._.filterTable('category');
        });
        this.$scope.$watch('_.filter.hard', value => {
            if (value) this.$scope._.filterTable('hard');
        });
        this.$scope.$watch('_.filter.themes', value => {
            if (value) this.$scope._.filterTable('themes');
        });
        this.$scope.$watch('_.filter.entityPackage', value => {
            if (typeof value === 'number') this.$scope._.filterTable('entityPackage');
        });
        this.$scope.$watch('_.filter.role', value => {
            if (value) this.$scope._.filterTable('role');
        });
        this.$scope.$watch('_.filter.isMultiGame', value => {
            if (typeof value === 'boolean') this.$scope._.filterTable('isMultiGame');
        });
        this.$scope.$watch('search.value', (value, old) => {
            if (value || old) this.$scope._.filterTable('gameId');
        });
    }

    async initObject() {
        let self = this;
        this.$scope.Game = {
            top: {
                async set(gameId) {
                    await self.ApiService.postEditorUserGameTop(gameId);
                    self.$rootScope.__.GAMES[gameId].top = Date.now();
                    self.getGamesSynopsis().then();
                },
                async unset(gameId) {
                    await self.ApiService.deleteEditorUserGameTop(gameId);
                    delete self.$rootScope.__.GAMES[gameId].top;
                    self.getGamesSynopsis().then();
                }
            }
        }
    }

    getShowTabLevel() {
        let w = document.body.clientWidth;
        if (w > 1430) this.$scope._.showTabLv5 = true;
        else this.$scope._.showTabLv5 = false;
        if (w > 1020) this.$scope._.showTabLv4 = true;
        else this.$scope._.showTabLv4 = false;
        if (w > 800) this.$scope._.showTabLv3 = true;
        else this.$scope._.showTabLv3 = false;
        this.$timeout(() => this.$scope.$apply(), 0)
    }

    async getGamesSynopsis() {
        let devGames, testGames, proGames;
        try {
            devGames = await this.ApiService.getEditorGamesSynopsis();
            // testGames = await this.ApiService.getTestGamesSynopsis();
            // proGames = await this.ApiService.getProGamesSynopsis();
        } catch (error) {
            console.error(error);
            if (error.status === 550) {
                this.$alert.error('登录超时, 请重新登录!');
                return this.ApiService.logout();
            }
            this.$alert.error(error.data && error.data.status && error.data.status.message);
        }

        for (let game of devGames) {
            if (!this.$rootScope.__.GAMES[game.gameId]) throw Error('异常游戏ID: ' + game.gameId);
            this.$rootScope.__.GAMES[game.gameId].dev = game;
        }
        // for (let game of testGames) {
        //     if (!this.$rootScope.__.GAMES[game.gameId]) throw Error('异常游戏ID: ' + game.gameId);
        //     this.$rootScope.__.GAMES[game.gameId].test = game;
        // }
        // for (let game of proGames) {
        //     if (!this.$rootScope.__.GAMES[game.gameId]) throw Error('异常游戏ID: ' + game.gameId);
        //     this.$rootScope.__.GAMES[game.gameId].pro = game;
        // }
        this.$scope._.GameList = Object.values(this.$rootScope.__.GAMES).filter(g => g.dev).sort((a, b) => b.top || 0 - a.top || 0);
        this.$scope._.filterTable();
    }
}

GameListController.$inject = ['$scope', '$rootScope', '$timeout', '$event', '$alert', '$util', '$window', 'ApiService'];
app.controller('GameList', GameListController);
