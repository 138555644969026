const app = angular.module('editor');

class InformationListController {
    constructor($scope, $rootScope, $event, $location, $alert, $storage, ApiService, $timeout, $window) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$event = $event;
        this.$location = $location;
        this.$alert = $alert;
        this.$storage = $storage;
        this.ApiService = ApiService;
        this.$timeout = $timeout;
        this.$window = $window;
        this.$scope.UI = {};
        this.$scope.Tags = [];

        if ($rootScope.__.CheckLogin('/information/list')) this.init();
        else $event.on('login', 'InformationListController.login', () => this.init());
        $event.on('ws.information_audit', 'InformationListController.ws.information_audit', async data => {
            this.$scope.Tags.push({content: data.content});
            await this.getArticlesAndLoginUrl()
        });
        $event.on('ws.information_delete', 'InformationListController.ws.information_delete', async data => {
            this.$scope.Tags.push({content: data.content});
            await this.getArticlesAndLoginUrl()
        });
    }

    init() {
        this.getArticlesAndLoginUrl();
        this.$scope.deleteArticle = this.deleteArticle.bind(this);
        this.$scope.showPreviewHTML = this.showPreviewHTML.bind(this);
        this.$scope.setting = this.setting.bind(this);
        this.$scope.audit = this.audit.bind(this);
        this.$scope.deleteTag = this.deleteTag.bind(this);
        this.$scope.toXiuMi = this.toXiuMi.bind(this);

    }

    async getArticlesAndLoginUrl() {
        this.$scope.loginUrl = await this.ApiService.getXiuMiLoginUrl();
        this.$scope.articles = [];
        let Informations = await this.ApiService.getEditorUserArticles();
        for (let i of Informations) {
            let article = {...i.data};
            article.author = i.releaseName;
            article.editUrl = `${this.$scope.loginUrl}&mediaid=${article.from_id}`;
            article.previewUrl = `http://ent.xiumi.us/preview_show.php?uid=${i.uid}&show_id=${article.from_id}`;
            article.auditStatus = i.auditStatus;
            article.updateDate = i.updateDate;
            article.status = i.status;
            this.$scope.articles.push(article);
        }
        this.$scope.$apply();
    }

    async deleteArticle(currentArticle) {
        let msg = '确定删除此条资讯！删除后需要前往秀米进行同步操作。';
        if (confirm(msg)) {
            let informationId = currentArticle.article.from_id;
            let result = await this.ApiService.deleteEditorUserArticle(informationId);
            if (result.ok === 1) {
                this.$alert('删除成功');
                await this.getArticlesAndLoginUrl();
            } else this.$alert('删除失败请重试');
        } else return false
    }

    showPreviewHTML(currentArticle) {
        this.$scope.previewHTML = currentArticle.article.description;
    }

    async setting(currentArticle) {
        this.$scope.currentArticle = currentArticle;
        let informationId = currentArticle.article.from_id;
        this.$scope.article = await this.ApiService.getEditorUserArticles(informationId);
        this.$scope.infTypeChange = (this.$scope.article.type === "gameDetail" || this.$scope.article.type === "activity");
        !!this.$scope.article.recommend ? this.$scope.article.recommend = this.$scope.article.recommend.toString() : this.$scope.article.recommend = "0";
        if (!!this.$scope.article.releaseDate) {
            this.$scope.article.releaseDate = new Date(this.$scope.article.releaseDate.split(':')[0] + ':' + this.$scope.article.releaseDate.split(':')[1]);
        } else this.$scope.article.releaseDate = "";
        this.$scope.submit = this.submit.bind(this);
        this.$scope.changeStatus = this.changeStatus.bind(this);
        this.$scope.$apply();
    }

    async audit(currentArticle) {
        let updateMessage = prompt('请填写更新信息');
        switch (updateMessage) {
            case null:
                this.$alert.info('取消提审');
                break;
            case "":
                this.$alert.error('必须填写更新信息');
                break;
            default:
                let informationId = currentArticle.article.from_id;
                let result = await this.ApiService.auditEditorUserArticle(informationId, updateMessage);
                if (result === 'success') {
                    this.$alert('已提交给管理员进行审核');
                    currentArticle.article.auditStatus = 0;
                    this.$scope.$apply();
                } else this.$alert('提交失败');
        }
    }

    deleteTag(index) {
        this.$scope.Tags.splice(index, 1);
    }

    toXiuMi(type, article) {
        if (confirm('即将离开当前页面\n前往秀米官网')) {
            let toUrl = '';
            switch (type) {
                case 'newArticle':
                    toUrl = this.$scope.loginUrl;
                    break;
                case 'toXiuMi':
                    toUrl = "https://xiumi.us/#/studio/papers";
                    break;
                case 'editArticle':
                    toUrl = article.editUrl;
                    break;
            }
            this.$window.open(toUrl, '_blank')
        } else return false
    }

    async submit() {
        let form = document.querySelector('#information');
        if (!form.checkValidity()) {
            form.classList.add('was-validated');
            return
        }
        form.classList.remove('was-validated');
        let article = {...this.$scope.article};
        delete article.data;
        let formData = new FormData();
        let releaseHeadImg = document.querySelector('input[type=file]').files[0];
        formData.append('releaseHeadImg', releaseHeadImg);
        formData.append('article', JSON.stringify(article));
        try {
            let result = await this.ApiService.updateEditorUserArticle(formData);
            if (result[0].ok === 1 && result[1].ok) {
                $('#ModalSetting').modal('toggle');
                this.$alert('配置修改成功');
            } else this.$alert('配置修改失败');
        } catch (e) {
            if (e.status === 561) alert(`不存在Id为${article.gameDetailOfGameId}的游戏，请重新核实。`);
            this.$alert('配置修改失败');
        }
    }

    async changeStatus() {
        let msg, informationId, status;
        informationId = this.$scope.article.informationId;
        switch (this.$scope.article.status) {
            case 0:
                msg = "是否发布上线";
                status = 1;
                break;
            case 1:
                msg = "是否下线";
                status = 0;
                break;
        }
        if (confirm(msg)) {
            let result = await this.ApiService.updateEditorUserArticleStatus(informationId, status);
            if (result[0].ok === 1 && result[1].ok) {
                $('#ModalSetting').modal('toggle');
                this.$scope.currentArticle.article.status = status;
                this.$scope.$apply();
                this.$alert('操作成功');
            } else this.$alert('操作失败');
        }
    }
}

InformationListController.$inject = ['$scope', '$rootScope', '$event', '$location', '$alert', '$storage', 'ApiService', '$timeout', '$window'];
app.controller('InformationList', InformationListController);

