const app = angular.module('editor');

class PluginListController {
    constructor($scope, $rootScope, $event, ApiService) {
        this.$scope = $scope;
        this.ApiService = ApiService;

        $scope.UI = {
            breadcrumb_game_text: {
                cn: '插件',
                en: 'Plugin',
            },
        };

        if ($rootScope.__.CheckLogin('/plugin/list')) this.init();
        else $event.on('login', 'PluginListController.login', () => this.init());
    }

    init() {
        this.$scope.list = [];
        this.initData().then();
    }

    async initData() {
        this.$scope.list = await this.ApiService.getEditorPlugins();
        this.$scope.$apply();
    }

}

PluginListController.$inject = ['$scope', '$rootScope', '$event', 'ApiService'];
app.controller('PluginList', PluginListController);
