const app = angular.module('editor');
import GameEditorController from './GameEditorController';

class GameEditorJsonController extends GameEditorController {
    constructor($scope, $rootScope, $window, $routeParams, $timeout, $event, $alert, $modal, $storage, ApiService) {
        super($scope, $rootScope, $window, $routeParams, $timeout, $event, $alert, $modal, $storage, ApiService);

        $scope.EditorMode.select = 'json';
    }
}

GameEditorJsonController.$inject = ['$scope', '$rootScope', '$window', '$routeParams', '$timeout', '$event', '$alert', '$modal', '$storage', 'ApiService'];
app.controller('GameEditorJson', GameEditorJsonController);

