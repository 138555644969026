export default class GameEditorController {
    constructor($scope, $rootScope, $window, $routeParams, $timeout, $event, $alert, $modal, $storage, ApiService) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$window = $window;
        this.$routeParams = $routeParams;
        this.$timeout = $timeout;
        this.$event = $event;
        this.$alert = $alert;
        this.$modal = $modal;
        this.$storage = $storage;
        this.ApiService = ApiService;

        $scope.UI = {
            breadcrumb_game_text: {
                cn: '游戏',
                en: 'Games',
            },
        };
        $rootScope.PageNow = 'game_edit';
        $scope._ = {
            gameId: $routeParams.gameId,
            editorGame: null,
            editorConfig: null,
            game_data_ready: false,
            unity_ready: false,
            game_update_data_modal: {
                old_data: '',
                new_data: '',
                show: (old_data, new_data) => {
                    $scope._.game_update_data_modal.old_data = old_data;
                    $scope._.game_update_data_modal.new_data = new_data;
                    $('#game_update_data_modal').modal('show');
                }
            },

            imageGroupName: '',
            isGroup: dir => dir.split('/').length < 3,
            inGroup: (name, group) => {
                if (group === '') return true;
                if (group === 'no_group') {
                    if (name.split('/').length < 2) return true;
                } else return name.startsWith(group);
            },
            getGroupName: dir => dir.split('/')[0]
        };

        this.initProperty();
        this.initObject();
        this.initFunction();

        if ($rootScope.__.CheckLogin('/game/edit/' + $scope._.gameId)) this.init();
        else $event.on('login', 'GameEditorController.login', () => this.init());
    }

    init() {
        this.initData().then();
        this.initGameVersion().then();
        this.$event.on('ws.game_audit_submit', 'GameEditorController.ws.game_audit_submit', data => {
            this.initGameVersion().then();
            console.log(data.content);
        });
    }

    initProperty() {
        this.$scope.game = {};
        this.$scope.ModuleTypes = {
            'BGM_audio': '背景音乐',
            'ads': '广告',
            'video': '视频',
            'unlock-mission': '解锁章节',
            'split': '分支',
            'richText': '文本',
            'setPaintingTalk': '立绘',
            'talk': '对话',
            'chapter': '章节起始',
            'switch-chapter': '切换下章',
            'gif': '动图',
            'image': '图片',
            'choose': '选择',
            'input': '答题',
            'hint': '提示',
            'space': '空行',
            'condition': '条件判断',
            'novelStopChoose': '小说卡点',
        };

    }

    initObject() {
        let self = this;
        this.$scope.ResourceLibrary = {
            fileType: '', // image audio video imageAndText
            showType: 'check', // check copyLink
            load: false,
            show(type_str, showType) {
                this.load = true;
                this.fileType = type_str;
                this.showType = showType || 'check';
                console.log('show', this.fileType, this.showType);
                $('#game_edit_show_resource').modal({
                    show: true,
                    backdrop: 'static',
                    keyboard: true
                });
            },
            chooseResource: resource => {
                if (this.$scope.EditorMode.select === 'graphical') gameeditor.window.onCheckResource(resource);
                else this.$scope.onCheckResource(resource);
                $('#game_edit_show_resource').modal('hide');
            },
        };

        let gameEditMode = 'graphical';
        if (this.$rootScope.__.GAMES && this.$rootScope.__.GAMES[this.$scope._.gameId].dev) gameEditMode = this.$rootScope.__.GAMES[this.$scope._.gameId].dev.others.gameEditMode;
        this.$scope.EditorMode = {
            select: gameEditMode,

            switch(mode) {
                let host = window.location.href.replace(window.location.hash, '');
                window.open(host + '#!/game/edit/' + self.$scope._.gameId + '/' + mode, '_blank');
            },
        };

        this.$scope.Catalog = {
            temp_catalog: [],
            temp_modules: {},
            select: {title: 'A', name: 'MissionA', missionId: 'A', moduleId: 'A_1'},
            switch(catalog) {
                this.select = catalog;
            },
            show() {
                if (!self.$scope.game || !self.$scope.game.data) {
                    self.$alert('数据加载中, 请稍后...', 'warning', {time: 1500});
                    return;
                }
                this.temp_catalog = JSON.parse(JSON.stringify(self.$scope.game.data.config.CATALOG));
                this.temp_modules = JSON.parse(JSON.stringify(self.$scope.game.data.modules));
                $('#game_editor_catalog_setting_modal').modal({
                    show: true,
                    backdrop: 'static',
                    keyboard: true
                });
            },
            add() {
                let ele = document.getElementById('game_editor_catalog_setting_modal_body');
                let len = this.temp_catalog.length;
                let start = 'A';
                let missionId = String.fromCharCode(start.charCodeAt() + len);
                let moduleId = missionId + '_1';
                this.temp_modules[moduleId] = {
                    moduleId,
                    type: 'chapter',
                    title: '',
                    value: '',
                    next: '',
                };
                this.temp_catalog.push({missionId, moduleId, title: '', name: '',})
                self.$timeout(() => ele.scrollTop = ele.scrollHeight, 100);
            },
            delete(missionId) {
                for (let index = 0; index < this.temp_catalog.length; index++) {
                    let item = this.temp_catalog[index];
                    if (item.missionId === missionId) {
                        this.temp_catalog.splice(index, 1);
                        break;
                    }
                }
                for (let moduleId in this.temp_modules) {
                    let _missionId = moduleId.split('_')[0];
                    if (_missionId === missionId) {
                        delete this.temp_modules[moduleId];
                    }
                }
            },
            exchange(index) {
                this.temp_catalog[index] = this.temp_catalog.splice(index - 1, 1, this.temp_catalog[index])[0];
            },
            selectResource(type, moduleId, attr) {
                self.$scope.onCheckResource = resource => {
                    this.temp_modules[moduleId][attr] = resource.key;

                    self.$scope.onCheckResource = () => {
                    };
                };
                self.$scope.ResourceLibrary.show(type);
            },
            save() {
                self.$scope.game.data.config.CATALOG = this.temp_catalog;
                self.$scope.game.data.modules = this.temp_modules;
                let {config: {$set: {CATALOG}}} = self.checkCatalog({config: {$set: {}}}, JSON.stringify(self.$scope.game));
                self.$scope.game.data.config.CATALOG = CATALOG;
                let catalog = CATALOG.find(c => c.missionId === this.select.missionId);
                this.switch(catalog);
                self.$event.emit('memory_game_update_' + self.$scope.game.gameId);
                self.$alert('已保存', 'success', {time: 1000});
            }
        };

        this.$scope.ModuleStore = {
            moduleIds: [],
            deleteSelects: {},
            show() {
                if (!self.$scope.game || !self.$scope.game.data) {
                    self.$alert.error('数据正在加载...');
                    return;
                }
                this.deleteSelects = {};
                this.moduleIds = self.__getGameUnmountedModuleIds(self.$scope.game);

                $('#game_editor_module_store_modal').modal({
                    show: true,
                    backdrop: 'static',
                    keyboard: true
                });
            },

            delete(moduleId) {
                this.deleteSelects[moduleId] = 1;
            },

            save() {
                for (let moduleId in this.deleteSelects) {
                    delete self.$scope.game.data.modules[moduleId];
                }
                self.$event.emit('memory_game_update_' + self.$scope.game.gameId);
                self.$alert('已保存', 'success', {time: 1000});
            },
        };


        this.$scope.AwardStore = {
            type: 'task_business',
            temp_props: {},
            selectType(type) {
                this.type = type;
            },
            show() {
                if (!self.$scope.game || !self.$scope.game.data) {
                    self.$alert('数据加载中, 请稍后...', 'warning', {time: 1500});
                    return;
                }
                this.temp_props = JSON.parse(JSON.stringify(self.$scope.game.data.props));
                $('#game_editor_award_store_modal').modal({
                    show: true,
                    backdrop: 'static',
                    keyboard: true
                });
            },
            add() {
                let ele = document.getElementById('game_editor_award_store_modal_body');
                let propId = self.__generatePropId(self.$scope.game, this.type);
                this.temp_props[propId] = {
                    propId,
                    type: this.type,
                    name: '',
                    url: '',
                    value: '',
                    subtitle: '',
                };
                if (this.type === 'task_business') this.temp_props[propId].task_tag = 'main';
                self.$timeout(() => ele.scrollTop = ele.scrollHeight, 100);
            },
            delete(propId) {
                delete this.temp_props[propId];
            },
            selectResource(type, propId, attr) {
                self.$scope.onCheckResource = resource => {
                    this.temp_props[propId][attr] = resource.key;
                    self.$scope.onCheckResource = () => {
                    };
                };
                self.$scope.ResourceLibrary.show(type);
            },
            save() {
                self.$scope.game.data.props = this.temp_props;
                self.$event.emit('memory_game_update_' + self.$scope.game.gameId);
                self.$alert('已保存', 'success', {time: 1000});
            }
        };

        this.$scope.PropStore = {
            type: 'prop',
            temp_props: {},
            selectType(type) {
                this.type = type;
            },
            show() {
                if (!self.$scope.game || !self.$scope.game.data) {
                    self.$alert('数据加载中, 请稍后...', 'warning', {time: 1500});
                    return;
                }
                this.temp_props = JSON.parse(JSON.stringify(self.$scope.game.data.props));
                $('#game_editor_prop_store_modal').modal({
                    show: true,
                    backdrop: 'static',
                    keyboard: true
                });
            },
            add() {
                let ele = document.getElementById('game_editor_prop_store_modal_body');
                let type = this.type;
                if (this.type === 'file') type = 'docfile';
                let propId = self.__generatePropId(self.$scope.game, type);
                this.temp_props[propId] = {
                    propId,
                    type: type,
                    name: '',
                    url: '',
                    value: '',
                    subtitle: '',
                };
                if (this.type === 'file') {
                    // this.temp_props[propId].islock = false;
                    this.temp_props[propId].password = '';
                }
                self.$timeout(() => ele.scrollTop = ele.scrollHeight, 100);
            },
            delete(propId) {
                delete this.temp_props[propId];
            },
            selectResource(type, propId, attr) {
                self.$scope.onCheckResource = resource => {
                    this.temp_props[propId][attr] = resource.key;
                    if (this.type === 'file') this.temp_props[propId].type = resource.type || 'docfile';
                    self.$scope.onCheckResource = () => {
                    };
                };
                self.$scope.ResourceLibrary.show(type);
            },
            save() {
                self.$scope.game.data.props = this.temp_props;
                self.$event.emit('memory_game_update_' + self.$scope.game.gameId);
                self.$alert('已保存', 'success', {time: 1000});
            }
        };

        this.$scope.NPCStore = {
            type: 'NPC',
            temp_props: {},
            selectType(type) {
                this.type = type;
            },
            show() {
                if (!self.$scope.game || !self.$scope.game.data) {
                    self.$alert('数据加载中, 请稍后...', 'warning', {time: 1500});
                    return;
                }
                this.temp_props = JSON.parse(JSON.stringify(self.$scope.game.data.props));
                $('#game_editor_npc_store_modal').modal({
                    show: true,
                    backdrop: 'static',
                    keyboard: true
                });
            },
            add() {
                let ele = document.getElementById('game_editor_npc_store_modal_body');
                let propId = self.__generatePropId({data: {props: this.temp_props}}, this.type);
                if (this.type === 'player' && !this.temp_props.player_default) {
                    propId = 'player_default';
                }
                this.temp_props[propId] = {
                    propId,
                    type: this.type,
                    name: '',
                    value: '',
                    url: '',
                };
                if (this.type === 'NPC') {
                    this.temp_props[propId].nameArray = [];
                    this.temp_props[propId].headImageArray = [];
                }
                if (this.type === 'player') {
                    this.temp_props[propId].nameArray = [];
                    this.temp_props[propId].headImageArray = [];
                    this.temp_props[propId].introHeadImage = '';
                }
                self.$timeout(() => ele.scrollTop = ele.scrollHeight, 100);
            },
            delete(propId) {
                delete this.temp_props[propId];
            },
            selectResource(type, propId, attr, index) {
                self.$scope.onCheckResource = resource => {
                    if (index !== undefined) {
                        this.temp_props[propId][attr][index] = resource.key;
                    } else {
                        this.temp_props[propId][attr] = resource.key;
                    }
                    self.$scope.onCheckResource = () => {
                    };
                };
                self.$scope.ResourceLibrary.show(type);
            },
            addNpcName(propId) {
                if (!this.temp_props[propId].nameArray) this.temp_props[propId].nameArray = [];
                let len = this.temp_props[propId].nameArray.length;
                if (!len) this.temp_props[propId].nameArray.push('');
                if (this.temp_props[propId].nameArray[len - 1]) this.temp_props[propId].nameArray.push('');
            },
            addNpcHeadImage(propId) {
                if (!this.temp_props[propId].headImageArray) this.temp_props[propId].headImageArray = [];
                let len = this.temp_props[propId].headImageArray.length;
                if (!len) {
                    this.temp_props[propId].headImageArray.push('');
                    self.$scope.NPCStore.selectResource("image", propId, "headImageArray", 0);
                }
                if (this.temp_props[propId].headImageArray[len - 1]) {
                    this.temp_props[propId].headImageArray.push('');
                    self.$scope.NPCStore.selectResource("image", propId, "headImageArray", len);
                }
            },
            save() {
                for (let propId in this.temp_props) {
                    let prop = this.temp_props[propId];
                    if (['NPC', 'player'].includes(prop.type)) {
                        prop.nameArray = prop.nameArray.filter(s => s !== '');
                        prop.headImageArray = prop.headImageArray.filter(s => s !== '');
                        if (prop.nameArray && prop.nameArray.length) {
                            if (!prop.nameArray[0]) prop.nameArray.splice(0, 1);
                            if (prop.nameArray.length) prop.name = prop.nameArray[0];
                        }
                        if (prop.headImageArray && prop.headImageArray.length) {
                            if (!prop.headImageArray[0]) prop.headImageArray.splice(0, 1);
                            if (prop.headImageArray.length) prop.headImage = prop.headImageArray[0];
                        }
                    }
                }
                self.$scope.game.data.props = this.temp_props;
                self.$event.emit('memory_game_update_' + self.$scope.game.gameId);
                self.$alert('已保存', 'success', {time: 1000});
            }
        };

        this.$scope.DocStore = {
            type: '档案',
            temp_props: {},
            selectType(type) {
                this.type = type;
            },
            show() {
                if (!self.$scope.game || !self.$scope.game.data) {
                    self.$alert('数据加载中, 请稍后...', 'warning', {time: 1500});
                    return;
                }
                this.temp_props = JSON.parse(JSON.stringify(self.$scope.game.data.props));
                $('#game_editor_doc_store_modal').modal({
                    show: true,
                    backdrop: 'static',
                    keyboard: true
                });
            },
            add() {
                let ele = document.getElementById('game_editor_doc_store_modal_body');
                let propId = self.__generatePropId({data: {props: this.temp_props}}, this.type);
                this.temp_props[propId] = {
                    propId,
                    type: this.type,
                    name: '',
                    value: '',
                    url: '',
                    subtitle: '',
                };
                if (this.type === '档案') {
                    // this.temp_props[propId].islock = false;
                    this.temp_props[propId].password = '';
                }
                if (this.type === '史料') {
                    // this.temp_props[propId].islock = false;
                    this.temp_props[propId].password = '';
                }
                self.$timeout(() => ele.scrollTop = ele.scrollHeight, 100);
            },
            delete(propId) {
                delete this.temp_props[propId];
            },
            selectResource(type, propId, attr) {
                self.$scope.onCheckResource = resource => {
                    this.temp_props[propId][attr] = resource.key;
                    self.$scope.onCheckResource = () => {
                    };
                };
                self.$scope.ResourceLibrary.show(type);
            },
            save() {
                self.$scope.game.data.props = this.temp_props;
                self.$event.emit('memory_game_update_' + self.$scope.game.gameId);
                self.$alert('已保存', 'success', {time: 1000});
            }
        };

        this.$scope.Config = {
            type: 'ui',
            temp_config: {},
            temp_props: {},
            selectType(type) {
                this.type = type;
            },
            profiles: {
                DOC: '资料库',
                TASK: '任务',
                MESSAGE: '消息',
                BAG: '背包',
                ME: '我的信息',
                CATALOGICON: '主菜单按钮图标',
                HINTICON: '提示按钮图标',
                GAMELOG: '主菜单LOGO',
                PROFILE: '用户详情',
                ATTRIBUTE: '属性',
                RANK: '等级',
                ISAPPINFO: '使用APP个人信息',
                NEEDQRCODESCAN: '扫二维码',
                ACHIEVEMENT: '成就',
                CommentModuleToggle: '开放章节评论',
                IsShowChapterProgress: '显示章节进度',
                End: '结局',
            },
            ui_profile: {
                MESSAGE: {
                    image: {
                        imageName: '',
                        imageName_Select: ''
                    },
                    hide: false,
                    text: '消息'
                },
                BAG: {
                    image: {
                        imageName: '',
                        imageName_Select: ''
                    },
                    hide: false,
                    text: '线索'
                },
                TASK: {
                    image: {
                        imageName: '',
                        imageName_Select: ''
                    },
                    hide: false,
                    text: '任务'
                },
                DOC: {
                    image: {
                        imageName: '',
                        imageName_Select: ''
                    },
                    hide: false,
                    text: '资料'
                },
                ME: {
                    image: {
                        imageName: '',
                        imageName_Select: ''
                    },
                    hide: false,
                    text: '我'
                },
                CATALOGICON: {
                    image: {
                        imageName: '',
                        imageName_Select: ''
                    },
                    hide: false,
                    text: ''
                },
                HINTICON: {
                    image: {
                        imageName: '',
                        imageName_Select: ''
                    },
                    hide: false,
                    text: ''
                },
                GAMELOG: {
                    image: '',
                    hide: false,
                    text: ''
                }
            },
            function_profile: {
                PROFILE: true,
                ACHIEVEMENT: true,
                ATTRIBUTE: true,
                RANK: true,
                ISAPPINFO: true,
                NEEDQRCODESCAN: false,
                CommentModuleToggle: true,
                IsShowChapterProgress: true,
                End: false,
            },
            show() {
                if (!self.$scope.game || !self.$scope.game.data) {
                    self.$alert('数据加载中, 请稍后...', 'warning', {time: 1500});
                    return;
                }
                this.temp_config = JSON.parse(JSON.stringify(self.$scope.game.data.config));
                this.temp_props = JSON.parse(JSON.stringify(self.$scope.game.data.props));
                if (self.$scope.game && self.$scope.game.data) {
                    let MY_PROFILE = this.temp_config.MY_PROFILE;
                    for (let key in this.ui_profile) {
                        if (MY_PROFILE.hasOwnProperty(key)) {
                            if (typeof MY_PROFILE[key] !== 'object') {
                                let hide = MY_PROFILE[key] === 'false';
                                MY_PROFILE[key] = this.ui_profile[key];
                                MY_PROFILE[key].hide = hide;
                            }
                        } else {
                            MY_PROFILE[key] = this.ui_profile[key];
                        }
                        MY_PROFILE[key].show = !MY_PROFILE[key].hide;
                    }
                    for (let key in this.function_profile) {
                        if (!MY_PROFILE.hasOwnProperty(key)) {
                            MY_PROFILE[key] = false;
                        }
                    }
                    $('#game_editor_config_modal').modal({
                        show: true,
                        backdrop: 'static',
                        keyboard: true
                    });
                }
            },
            selectResource(type, key, attr) {
                self.$scope.onCheckResource = resource => {
                    if (this.type === 'ui') {
                        if (attr) this.temp_config.MY_PROFILE[key].image[attr] = resource.key;
                        else this.temp_config.MY_PROFILE[key].image = resource.key;
                    }
                    if (this.type === 'other') {
                        if (attr) {
                            this.temp_config[key][attr] = resource.key;
                        } else {
                            this.temp_config[key] = resource.key;
                        }
                    }
                    self.$scope.onCheckResource = () => {
                    };
                };
                self.$scope.ResourceLibrary.show(type);
            },
            setFontStyle(propId, style) {
                let prop = this.temp_props[propId];
                if (prop.fontStyle === style) prop.fontStyle = 'Normal';
                else prop.fontStyle = style;
            },
            setFontAlign(propId, align) {
                let prop = this.temp_props[propId];
                prop.alignment_h = align;
            },
            setFontSize(propId, size) {
                let prop = this.temp_props[propId];
                prop.fontSize = size;
            },
            save() {
                for (let key in this.ui_profile) {
                    this.temp_config.MY_PROFILE[key].hide = !this.temp_config.MY_PROFILE[key].show;
                }
                self.$scope.game.data.config = this.temp_config;
                self.$scope.game.data.props = this.temp_props;
                self.$event.emit('memory_game_update_' + self.$scope.game.gameId);
                self.$alert('已保存', 'success', {time: 1000});
            }
        };

        this.$scope.Search = {
            show() {
                $('#game_editor_search_store_modal').modal({
                    show: true,
                    backdrop: 'static',
                    keyboard: true
                });
            }
        };

        this.$scope.Save = {
            title: '提交修改',
            message: '',
            loading: false,
            update_data: {
                config: {$set: {}, $unset: {}},
                modules: {$set: {}, $unset: {}},
                props: {$set: {}, $unset: {}}
            },
            async submit() {
                this.loading = true;
                let commit = {
                    message: this.message,
                    userId: self.$rootScope.__.ME.userId,
                    username: self.$rootScope.__.ME.others.nickname,
                };
                if (!self.checkGameData(self.$scope._.editorGame)) return;
                self.checkPlayerConfig(self.$scope._.editorGame).then();
                let updateData = this.update_data;
                let updateIndexConfig;
                if (self.$scope.EditorMode.select === 'graphical') {
                    updateIndexConfig = self.getGameUpdateIndexConfig(self.$scope._.baseEditorConfig, self.$scope._.editorConfig);
                }
                try {
                    let editorGame = await self.ApiService.putEditorGameDetail(self.$scope._.gameId, updateData, commit);
                    self.$rootScope.__.GAMES[self.$scope._.gameId].dev = editorGame;
                    self.$scope._.baseEditorGame = self.$scope._.editorGame = editorGame ? JSON.stringify(editorGame, 0, 4) : '{}';
                    self.$alert.success('保存成功, 游戏测试员可以在APP我的卷宗中预览游戏!');
                    $('#game_edit_save_modal').modal('hide');
                    if (self.$scope.EditorMode.select === 'graphical') {
                        let gameEditorIndexConfig = await self.ApiService.putEditorGameEditorConfig(self.$scope._.gameId, updateIndexConfig);
                        self.$scope._.baseEditorConfig = self.$scope._.editorConfig = gameEditorIndexConfig ? JSON.stringify(gameEditorIndexConfig) : '{}';
                        self.$timeout(() => {
                            gameeditor.window.reloadEditor(self.$scope._.editorGame, self.$scope._.editorConfig);
                        }, 50);
                    }
                    // self.$scope.$apply();
                } catch (e) {
                    self.$alert.error(e.data && e.data.status && e.data.status.message);
                }
            },
            show() {
                if (!self.$scope._.editorGame) return;
                try {
                    if (self.$scope.EditorMode.select === "fast") {
                        // graphical和json模式修改的是string:editorGame, fast模式修改的是object:game
                        self.$scope._.editorGame = JSON.stringify(angular.copy(self.$scope.game));
                    }
                    this.message = '游戏内容更新';
                    this.old_game_data = JSON.parse(self.$scope._.baseEditorGame).data;
                    this.update_data = self.getGameUpdateData(self.$scope._.baseEditorGame, self.$scope._.editorGame);
                    this.update_data = self.addAllTextLink(this.update_data, self.$scope._.editorGame);
                    this.update_data = self.checkCatalog(this.update_data, self.$scope._.editorGame);
                    this.loading = false;
                    self.$timeout(() => self.$scope.$apply());
                    $('#game_edit_save_modal').modal({
                        show: true,
                        backdrop: 'static',
                        keyboard: true
                    });
                } catch (error) {
                    console.error('EditorGameController:modal:show:', error);
                    self.$alert.error(error.data && error.data.status && error.data.status.message || error.message);
                }
            },
        };

        // 退出编辑
        this.$scope.Quit = () => {
            this.$rootScope.__.Path('/game/detail/' + this.$scope._.gameId);
            this.$timeout(() => this.$rootScope.$apply());
        };

        // 帮助文档
        this.$scope.HelpDoc = {
            show() {
                $('#game_editor_help_doc_modal').modal({
                    show: true,
                    backdrop: 'static',
                    keyboard: true
                });
            }
        };
    }

    initFunction() {
        this.$scope.getProp = propId => {
            if (!propId) return '';
            return this.$rootScope.__.GAMES[this.$scope._.gameId].dev.data.props[propId];
        };
        this.$scope.getResource = resourceId => {
            if (!resourceId) return '';
            if (resourceId.startsWith('nrk_')) return this.$rootScope.__.GAMES[this.$scope._.gameId].dev.others.resources[resourceId];
            return {name: resourceId};
        };
        this.$scope.getResourceUrl = resource => {
            if (!resource || !resource.name) return;
            if (resource.name.startsWith('http')) return resource.name;
            return `${this.$scope.game.others.resourcePrefix}${resource.name}`;
        };

        this.$scope.onCheckResource = resource => {
            console.log('resource:', resource)
        };
        this.$scope.isObject = v => typeof v === 'object';

        this.$window.EditorModeSwitch = this.$scope.EditorMode.switch;
        this.$window.Quit = this.$scope.Quit;

        this.$window.onTextCopy = text => {
            let aux = document.createElement('input');
            aux.setAttribute('value', text);
            document.body.appendChild(aux);
            aux.select();
            document.execCommand('copy');
            document.body.removeChild(aux);
            return text;
        };

        // 查看素材库
        this.$window.openResourceLibrary = type => {
            this.$scope.ResourceLibrary.show(type)
        };


        this.$window.onbeforeunload = () => false;
        this.$scope.$on('$locationChangeStart', event => {
            if (!confirm('确认退出此页面?系统可能不会保存您所做的更改。')) {
                event.preventDefault();
                return;
            }
            this.$window.onbeforeunload = () => {
            };
            if (this.$scope.EditorMode.select === 'graphical') {
                gameeditor.window.quitEditor();
            }
            this.$event.cancel('ws.game_audit_submit', 'GameEditorController.ws.game_audit_submit');
            this.$timeout.cancel(this.$scope._.timer);
        });
        //编辑器启动完成
        this.$window.OnUnityInitFinished = () => {
            this.$scope._.unity_ready = true;
            this.setGameDataToEditor();
        };
        // 当编辑器游戏数据更新
        this.$window.onEditorGameDataUpdate = (game_str, config_str) => {
            this.$scope._.editorGame = JSON.stringify(JSON.parse(game_str), 0, 4);
            if (config_str) this.$scope._.editorConfig = JSON.stringify(JSON.parse(config_str));
            this.$scope.Save.show();
        };

        this.saveAlert();
        $(document).ready().keydown(function (e) {
            if (e.which === 27) {
                $('.modal.show').eq(0).modal('hide');
            }
        });

    }

    async initData() {
        try {
            let gameId = this.$scope._.gameId;
            await this.initGameSTSToken();
            let editorGame = await this.ApiService.getEditorGameDetail(gameId);
            if (!this.$rootScope.__.GAMES[gameId]) this.$rootScope.__.GAMES[gameId] = {};
            this.$rootScope.__.GAMES[gameId].dev = this.$scope.game = editorGame;
            this.$scope._.baseEditorGame = this.$scope._.editorGame = editorGame ? JSON.stringify(editorGame, 0, 4) : '{}';

            this.$scope._.game_data_ready = true;

            if (this.$scope.EditorMode.select === 'graphical') {
                let editorConfig = await this.ApiService.getEditorGameConfig(gameId);
                this.$scope._.baseEditorConfig = this.$scope._.editorConfig = editorConfig ? JSON.stringify(editorConfig) : '{}';
                this.setGameDataToEditor();
            }

            this.$scope.Catalog.switch(editorGame.data.config.CATALOG && editorGame.data.config.CATALOG[0] || {moduleId: 'A_1'});

            this.$timeout(() => this.$scope.$apply());
            this.$timeout(() => this.$rootScope.$apply());
        } catch (error) {
            console.error(error);
            this.$alert.error(error.data && error.data.status && error.data.status.message);
        }
    }

    async initGameVersion() {
        try {
            let version = await this.ApiService.getGameVersion(this.$scope._.gameId);
            if (!this.$rootScope.__.GAMES[this.$scope._.gameId]) this.$rootScope.__.GAMES[this.$scope._.gameId] = {};
            this.$rootScope.__.GAMES[this.$scope._.gameId].version = version;
            if (version.test && (version.test.auditStatus === 1 || version.test.auditStatus === 2)) {
                this.$alert('游戏审核中, 无法编辑游戏!', 'danger', {time: 36000000});
            }
            this.$rootScope.$apply();
        } catch (e) {
            console.error(e);
        }
    }

    saveAlert(msg) {
        if (msg) this.$alert(msg, 'warning', {time: 300000});
        this.$scope._.timer = this.$timeout(() => this.saveAlert('请注意及时保存!'), 300000);
    }

    setGameDataToEditor() {
        if (this.$scope._.game_data_ready && this.$scope._.unity_ready) {
            gameeditor.window.initGameData(this.$scope._.editorGame, this.$scope._.editorConfig);
        }
    }


    checkGameData(editorGame) {
        let game = JSON.parse(editorGame);
        let warningStr = '';
        let modules = Object.values(game.data.modules);
        // next 对应的module没有配置的
        let lackNext = modules.filter(m => m.next).filter(m => !modules.some(e => e.moduleId === m.next));
        // input choose 对应 value的出口有没有配置的
        let lackValue = modules.filter(m => m.type === 'choose' || m.type === 'input').filter(m => !m.value || !Object.values(m.value) || !Object.values(m.value).every(e => modules.some(em => em.moduleId === e)))
        if (lackNext.length) {
            warningStr += `${JSON.stringify(lackNext.map(m => m.moduleId))}中对应的ceil的next没有配置, `;
        }
        if (lackValue.length) {
            warningStr += `${JSON.stringify(lackValue.map(m => m.moduleId))}中对应的ceil的选项所指示的出口没有对应的ceil与之对应, `
        }
        // attachment 中对应物品没有配置的
        let lackAttachment = modules.filter(m => m.attachment ? m.attachment.length > 0 : false).filter(m => m.attachment.filter(a => !game.data.props[a.key]).length > 0)
        if (lackAttachment.length) {
            warningStr += `${JSON.stringify(lackAttachment.map(m => m.moduleId))}中对应ceil的attachment没有配置, `
        }
        // input和choose紧连在一起的
        let followAnAnder = modules.filter(m => m.type === 'choose' || m.type === 'input').filter(m => m.value && Object.values(m.value) && Object.values(m.value).some(e => game.data.modules && game.data.modules[e] && game.data.modules[e].type && ['choose', 'input'].includes(game.data.modules[e].type)))
        if (followAnAnder.length) {
            warningStr += `${JSON.stringify(followAnAnder.map(m => m.moduleId))}中对应ceil后有紧挨着的input或choose, `
        }
        // input和choose没有配置name的
        if (game.gameType === 'marvel') {
            let lackName = modules.filter(m => m.type === 'choose' || m.type === 'input').filter(m => !m.name);
            if (lackName.length) {
                warningStr += `${JSON.stringify(lackName.map(m => m.moduleId))}中没有配置name, `
            }
        }
        if (warningStr) {
            if (!confirm(warningStr)) return false;
        }
        return true;
    }

    addAllTextLink(update_data, editorGame) {
        let update_arr = Object.values(update_data.modules.$set).concat(Object.values(update_data.modules.$unset));
        if (!update_arr.length) return update_data;
        let modules = JSON.parse(editorGame).data.modules;
        let props = JSON.parse(editorGame).data.props;
        let warning_not_found = '';
        let warning_repeat = '';
        for (let moduleId in modules) {
            let module = modules[moduleId];
            if ('richText' === module.type) {
                if (module.value.includes('<link=unlockprop>')) {
                    if (!module.textLink) module.textLink = {};
                    let baseTextLink = JSON.stringify(module.textLink);
                    module.textLink = {};
                    let word_arr = module.value.match(/<link=unlockprop>.+?<\/link>/g);
                    word_arr = word_arr.map(s => s.substring(17, s.length - 7));
                    for (let name of word_arr) {
                        let propId;
                        let counter = 0;
                        for (let _propId in props) {
                            if (name === props[_propId].name) {
                                propId = _propId;
                                counter++;
                            } else if (name === props[_propId].subtitle) {
                                propId = _propId;
                                counter++;
                            }
                        }
                        if (propId) {
                            module.textLink[name] = {
                                type: 'history',
                                propId: propId
                            };
                            if (counter > 1) {
                                warning_repeat += ` ${moduleId}:${name} `
                            }
                        } else {
                            warning_not_found += ` ${moduleId}:${name} `
                        }
                    }
                    if (JSON.stringify(module.textLink) !== baseTextLink) {
                        update_data.modules.$set[moduleId] = module;
                    }
                }
            }
        }
        if (warning_not_found) {
            this.$alert.error('[未找到热点对应的历史资料] ' + warning_not_found);
        }
        if (warning_repeat) {
            this.$alert.error('[资料名称有重复] ' + warning_repeat);
        }
        return update_data;
    }

    checkCatalog(update_data, editorGame) {
        // let update_arr = Object.values(update_data.modules.$set).concat(Object.values(update_data.modules.$unset));
        // if (!update_arr.length) return update_data;
        let game = JSON.parse(editorGame);
        let missions = {};
        let catalogs = {};
        for (let moduleId in game.data.modules) {
            let missionId = moduleId.split('_')[0];
            if (!missions[missionId]) missions[missionId] = {input: {}, choose: {}, textLink: {}, prop: {}};
            let module = game.data.modules[moduleId];
            if (game.others.isMultiGame && game.others.category !== 'novel' && module.type !== 'chapter') {
                continue;
            }
            if (this.__ignoreModule(game.gameId, moduleId)) {
                continue;
            }
            if (module.type === 'chapter') {
                let backgroundMusic = '';
                if (module.event && module.event.onEnterCenter && module.event.onEnterCenter.effect) {
                    let effect = module.event.onEnterCenter.effect.find(item => item.eventEffectType === 'playBGM');
                    if (effect) backgroundMusic = effect.value;
                }
                catalogs[missionId] = {
                    moduleId: module.moduleId,
                    title: module.title || missionId,
                    name: module.value || 'Mission' + missionId,
                    backgroundImage: module.backgroundImage,
                    canDream: module.canDream,
                    image: module.image,
                    section: module.section,
                    hideWhenLock: module.hideWhenLock || false,
                    backgroundMusic,
                    chatviewConfig: "chinaTownStyle",
                }
            } else if (['input', 'inputSetPaintingTalk'].includes(module.type)) {
                missions[missionId].input[moduleId] = moduleId;
            } else if (['choose', 'chooseSetPaintingTalk', 'mutichooseSetPaintingTalk'].includes(module.type)) {
                missions[missionId].choose[moduleId] = moduleId;
            } else if ('richText' === module.type) {
                if (module.textLink) {
                    let length = Object.keys(module.textLink).length;
                    if (length) missions[missionId].textLink[moduleId] = length;
                }
            }
            if (module.attachment) {
                let prop_size = Object.values(module.attachment).filter(m => m.key && m.key.startsWith('prop_')).length;
                if (prop_size) missions[missionId].prop[moduleId] = prop_size;
            }
        }
        for (let missionId in missions) {
            if (!catalogs[missionId]) throw Error('缺少章节起始模块:' + missionId);
            catalogs[missionId].missionId = missionId;
            catalogs[missionId].input = Object.values(missions[missionId].input);
            catalogs[missionId].choose = Object.values(missions[missionId].choose);
            catalogs[missionId].resourceScore = catalogs[missionId].input.length * 10 + catalogs[missionId].choose.length * 5;
            catalogs[missionId].choose = catalogs[missionId].choose.concat(catalogs[missionId].input);

            let textLinkModuleArray = Object.keys(missions[missionId].textLink);
            if (textLinkModuleArray.length) {
                catalogs[missionId].textLink = textLinkModuleArray;
                catalogs[missionId].resourceScore += eval(Object.values(missions[missionId].textLink).join('+'));
            }

            let propModuleArray = Object.keys(missions[missionId].prop);
            if (propModuleArray.length) {
                catalogs[missionId].prop = propModuleArray;
                catalogs[missionId].resourceScore += eval(Object.values(missions[missionId].prop).join('+'));
            }
        }
        let CATALOG = [];
        for (let item of game.data.config.CATALOG) {
            if (catalogs[item.missionId]) {
                CATALOG.push(catalogs[item.missionId]);
                delete catalogs[item.missionId];
            }
        }
        CATALOG = CATALOG.concat(Object.values(catalogs));
        if (JSON.stringify(CATALOG) !== JSON.stringify(game.data.config.CATALOG)) {
            update_data.config.$set['CATALOG'] = CATALOG;
        }
        return update_data;
    }

    async checkPlayerConfig(editorGame) {
        let game = JSON.parse(editorGame);
        if (!game.others.isMultiGame) return;
        if (game.others.category === 'novel') return;
        let players = {};
        for (let propId in game.data.props) {
            let prop = game.data.props[propId];
            if (prop.type === 'player') {
                players[propId] = {
                    propId: prop.propId,
                    type: prop.type,
                    CharacterName: prop.name,
                    headImage: this.$scope.getResourceUrl(this.$scope.getResource(prop.headImage)),
                    experience: prop.experience,
                }
            }
        }
        delete players.player_default;
        let playerConfig = Object.values(players);
        if (JSON.stringify(playerConfig) !== JSON.stringify(game.others.playerConfig)) {
            game.others.playerConfig = playerConfig;
            game.others.playerNumber = playerConfig.length;
            this.$rootScope.__.GAMES[this.$scope._.gameId][this.$scope._.env] = await this.ApiService.postEditorGameConfig(this.$scope._.gameId, game);
        }
    }

    getGameUpdateData(baseEditorGame, editorGame) {
        let oldGame = JSON.parse(baseEditorGame);
        let newGame = JSON.parse(editorGame);
        // 获取 modules 的更新内容
        let updateModules = _getObjectUpdate(oldGame.data.modules, newGame.data.modules);
        // 获取 props 的更新内容
        let updateProps = _getObjectUpdate(oldGame.data.props, newGame.data.props);
        // 获取 config 的更新内容
        let updateConfig = _getObjectUpdate(oldGame.data.config, newGame.data.config);

        function _getObjectUpdate(old_obj = {}, new_obj = {}) {
            let update = {$set: {}, $unset: {}};
            for (let attr in old_obj) {
                if (old_obj.hasOwnProperty(attr) && !new_obj[attr]) update.$unset[attr] = 1;
            }
            for (let attr in new_obj) {
                if (new_obj.hasOwnProperty(attr) && JSON.stringify(new_obj[attr]) !== JSON.stringify(old_obj[attr])) update.$set[attr] = new_obj[attr];
            }
            return update;
        }

        return {modules: updateModules, props: updateProps, config: updateConfig};
    }

    getGameUpdateIndexConfig(baseEditorConfig, editorConfig) {
        let oldIndexConfig = JSON.parse(baseEditorConfig);
        let newIndexConfig = JSON.parse(editorConfig);
        // 获取更新的indexConfig
        let updateModules = {$set: {}, $unset: {}};
        for (let attr in oldIndexConfig) {
            // 删除的 indexConfig
            if (!newIndexConfig.hasOwnProperty(attr)) updateModules.$unset[attr] = 1;
        }
        for (let attr in newIndexConfig) {
            // 新增的 indexConfig
            // if (!oldIndexConfig.hasOwnProperty(attr)) updateModules.$set[attr] = newIndexConfig[attr];
            // 修改的 indexConfig
            if (JSON.stringify(newIndexConfig[attr]) !== JSON.stringify(oldIndexConfig[attr])) {
                updateModules.$set[attr] = newIndexConfig[attr];
            }
        }
        return updateModules;
    }

    async initGameSTSToken() {
        let credentials = await this.ApiService.getGameSTSToken(this.$scope._.gameId);
        this.$scope._.credentials = credentials;
        this.$scope._.OSSClient = new OSS({
            region: 'oss-cn-beijing',
            accessKeyId: credentials.AccessKeyId,
            accessKeySecret: credentials.AccessKeySecret,
            stsToken: credentials.SecurityToken,
            bucket: 'om-resources',
            endpoint: 'resource.itaotuo.com',
            cname: true,
        });
        this.$timeout(() => this.initGameSTSToken(), new Date(credentials.Expiration) - new Date() - 30000);
    }


    __getGameUnmountedModuleIds(game) {
        let _moduleIds = {};
        let _mountedModules = this.__getGameMountedModules(game);
        for (let moduleId in game.data.modules) {
            _moduleIds[moduleId] = 1;
        }
        for (let moduleId of _mountedModules) {
            delete _moduleIds[moduleId];
        }
        return Object.keys(_moduleIds);
    }


    __getGameMountedModules(game) {
        let CATALOG = game.data.config.CATALOG;
        if (!CATALOG) return [];
        let _mountedModules = [];
        for (let catalog of CATALOG) {
            _mountedModules = _mountedModules.concat(this.__getGameMountedMissionModules(game, catalog.moduleId));
        }
        return _mountedModules;
    }

    __getGameMountedMissionModules(game, moduleId) {
        let _mountedModules = {};
        let _nextModuleIds = [moduleId];
        let counter = 0;
        while (_nextModuleIds.length > 0) {
            let arr = [];
            for (let nextModuleId of _nextModuleIds) {
                if (_mountedModules[nextModuleId]) continue;
                _mountedModules[nextModuleId] = 1;
                arr = arr.concat(this.__getNextModuleId(game.data.modules[nextModuleId]));
            }
            _nextModuleIds = arr;
            if (counter++ > 1000) {
                console.error('__getGameMountedMissionModules', counter, _nextModuleIds)
                break;
            }
        }
        return Object.keys(_mountedModules);
    }

    __getNextModuleId(module) {
        if (!module) return [];
        if ('switch-chapter' === module.type) return [];
        let array = [];
        if (module.next) array.push(module.next);
        if (module.value && typeof module.value === 'object') array = array.concat(Object.values(module.value));
        if (module.wayOut && typeof module.wayOut === 'object') array = array.concat(Object.values(module.wayOut));
        if (module.branch_ids && typeof module.branch_ids === 'object') array = array.concat(Object.values(module.branch_ids));
        if (module.event && typeof module.event === 'object') array = array.concat(Object.values(module.event).filter(m => m.hasOwnProperty('next')).map(m => m.next));

        return array;
    }

    __generatePropId(game, type) {
        if (!game || !type || !game.data || !game.data.props) throw '游戏加载中...';
        let props = game.data.props;
        let max = this[type + 'MaxIndex'] || 0;
        let base = this[type + 'Base'] || (type + '_R');
        if (!max) {
            for (let propId in props) {
                let condition = props[propId].type === type;
                if (type === 'docfile') {
                    condition = ["image", "audio", "video", "docfile"].includes(props[propId].type)
                }
                if (condition) {
                    let arr = propId.split('_');
                    let [index] = arr.splice(-1)
                    if (max < Number(index)) max = Number(index);
                    base = arr.join('_');
                }
            }
        }
        this[type + 'MaxIndex'] = ++max;
        this[type + 'Base'] = base;
        return base + '_' + max;
    }

    __ignoreModule(gameId, moduleId) {
        if (['AOMI@gugong3', 'AOMI@gugong3Test'].includes(gameId)) {
            let moduleIdArray = [
                'A_4462',
                'A_4570',
                'A_4581',
                'A_4582',
                'A_4583',
                'A_4584',
                'A_4585',
                'A_4586',
                'A_4587',
                'A_4588',
                'A_4589',
                'A_4720',
                'B_4662',
                'B_4665',
                'D_1122',
                'D_1123',
                'D_1124',
                'D_1125',
                'D_1126',
                'D_1127',
                'D_1128',
                'D_1214',
                'D_1259',
                'D_1268',
                'D_1269',
                'D_1284',
                'D_1295',
                'D_1296',
                'D_1330',
                'D_1331',
                'D_1332',
                'D_1333',
                'D_1334',
                'D_1335',
                'D_1336',
                'D_1378',
                'D_1379',
                'D_1380',
                'D_1381',
                'D_1382',
                'D_1383',
                'D_1384',
                'D_3366',
                'D_3367',
                'D_3373',
                'D_3374',
                'D_3408',
                'D_3421',
                'D_3463',
                'D_4054',
                'D_4076',
                'E_1927',
                'E_1942',
                'E_1978',
                'E_2007',
                'E_2014',
                'E_2040',
                'E_2060',
                'E_2081',
                'E_2082',
                'E_4311',
                'E_4312',
                'E_4330',
                'E_4357',
                'E_4360',
                'E_4364',
                'E_4367',
                'E_4370',
                'E_4378',
            ];
            return moduleIdArray.includes(moduleId)
        }
        return false;
    }
}
