/**
 * OSSClient.delete 文件删除配有ip白名单， 本地地址需要在oss配置才能删除文件
 */

const app = angular.module('editor');
import GameDetailController from './GameDetailController';

let syncOssFileListIndex = 0;

class GameDetailResourceController extends GameDetailController {

    constructor($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util, $progress) {
        super($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util);
        this.$progress = $progress;
        this.initProperty();
        this.initObject();
        this.initFunction();

        this.$scope.onGameDataLoadSuccess = () => {
            this.initData();
            this.checkData().then();
            if (this.$routeParams.action) {
                if (this.$routeParams.action === 'showImageAndText') {
                    this.$scope._.imageAndText.show();
                }
            }
        };
    }

    initProperty() {
        this.$scope._.pageType = this.$routeParams.pageType || 'image';
        this.$scope._.pageTypeAccept = {
            image: '.jpg,.png',
            gif: '.gif',
            audio: '.mp3,.wav',
            video: '.mp4',
        };

        this.$scope._.tabName = 'resource';
        this.$scope._.envDisabled = true;
        this.$scope._.OSSClient = null;
        this.$scope._.credentials = {};
        this.$scope._.uploader_queue = [];
    }

    initObject() {
        let self = this;
        // 批处理
        this.$scope._.Batch = {
            data: {},
            size: 0,

            add(resource) {
                this.data[resource.key] = resource;
                this.size++;
            },
            remove(resource) {
                delete this.data[resource.key];
                this.size--;
            },
            clear() {
                this.data = {};
                this.size = 0;
            },
            has(resource) {
                return this.data.hasOwnProperty(resource.key);
            },

            Delete: {
                show: false,
                async ok() {
                    await self.deleteResources(self.$scope._.Batch.data);
                    this.show = false;
                    self.$scope._.Batch.clear();
                },
                cancel() {
                    this.show = false;
                }
            },
            Move: {
                show: false,
                to: '',
                async ok() {
                    if (!this.to) {
                        self.$alert.warning('未选择组');
                        return;
                    }
                    let update = {};
                    for (let key in self.$scope._.Batch.data) {
                        let _resource = self.$util.clone(self.$scope._.Batch.data[key]);
                        let groupName = self.$scope._.getResourceGroup(_resource);
                        if (groupName) {
                            if ('no_group' === this.to) {
                                _resource.newName = _resource.name.replace(groupName + '/', '');
                            } else {
                                _resource.newName = _resource.name.replace(groupName, this.to);
                            }
                        } else {
                            if ('no_group' === this.to) {
                                _resource.newName = _resource.name;
                            } else {
                                _resource.newName = this.to + '/' + _resource.name;
                            }

                        }
                        if (_resource.name !== _resource.newName) {
                            update[key] = _resource;
                        }
                    }
                    console.log(update)

                    if (Object.keys(update).length) await self.renameResources(update);

                    this.to = '';
                    this.show = false;
                    self.$scope._.Batch.clear();
                },
                cancel() {
                    this.to = '';
                    this.show = false;
                }
            }
        };

        // 图片分组
        this.$scope._.Group = {
            select: '',
            data: {},
            no_group: {},
            number: {no_group: 0},
            names: [],

            initGroup(dirs) {
                this.names = dirs
                    .map(s => s.name.substring(0, s.name.length - 1))
                    .filter(s => s.split('/').length < 2);
                this.initGroupData();
            },
            initGroupData() {
                let images = self.getResourcesByType('image', self.$rootScope.__.GAMES[self.$scope._.gameId].dev.others.resources);
                this.data = {};
                this.no_group = {};
                this.number = {no_group: 0};
                let _images = JSON.parse(JSON.stringify(images));
                // 分组图片
                for (let name of this.names) {
                    this.data[name] = {};
                    this.number[name] = 0;
                    for (let key in _images) {
                        if (_images[key].name.startsWith(name + '/')) {
                            this.data[name][key] = _images[key];
                            this.number[name]++;
                            delete _images[key];
                        }
                    }
                }
                // 未分组图片
                for (let key in _images) {
                    this.no_group[key] = _images[key];
                    this.number.no_group++;
                }
                if (this.select) this.switch(this.select);
            },
            switch(name) {
                this.select = name;
                if (!name) {
                    self.$scope.resources.images = self.getResourcesByType('image', self.$rootScope.__.GAMES[self.$scope._.gameId].dev.others.resources);
                    self.$scope._.Group.initGroupData();
                } else if ('no_group' === name) {
                    self.$scope.resources.images = this.no_group;
                } else self.$scope.resources.images = this.data[name];
                self.$scope.resources.imageNumber = self.$util.getObjectSize(self.$scope.resources.images);
                self.$scope._.Batch.clear();
                self.$timeout(() => self.$scope.$apply());
            },

            Add: {
                show: false,
                name: '',

                popup($event) {
                    this.show = !this.show;
                    if (this.show) self.$timeout(() => $('#add-group-input').focus(), 150);
                    $event.stopPropagation()
                },
                async ok() {
                    if (!this.name) return self.$alert.warning('不能为空');
                    if (self.$scope._.Group.names.includes(this.name)) return self.$alert.warning('分组已存在');
                    self.$scope._.Group.names.push(this.name);
                    try {
                        let path = `puzzle/games/${self.$scope._.gameId}/${this.name}/`;
                        let result = await self.$scope._.OSSClient.put(path, new Buffer(''));
                        let name = result.name.substring(String(`puzzle/games/${self.$scope._.gameId}/`).length, result.name.length - 1);
                        self.$scope._.Group.data[name] = {};
                        self.$scope._.Group.number[name] = 0;
                        self.$scope._.Group.initGroupData();
                    } catch (e) {
                        console.error(e);
                        this.$alert.error(e.data && e.data.status && e.data.status.message);
                    }
                    this.name = '';
                    this.show = false;
                },
                cancel() {
                    this.newName = '';
                    this.show = false;
                }
            },
            Rename: {
                show: false,
                newName: '',
                async ok(newName, name) {
                    // console.log('group Rename', newName, name);
                    if (newName === name) {
                        this.newName = '';
                        this.show = false;
                        return;
                    }
                    if (!newName) return self.$alert.warning('不能为空');
                    if (self.$scope._.Group.data.hasOwnProperty(newName)) return self.$alert.warning('已存在的分组名称');
                    let newPath = `puzzle/games/${self.$scope._.gameId}/${newName}/`;
                    let path = `puzzle/games/${self.$scope._.gameId}/${name}/`;
                    await self.$scope._.OSSClient.copy(newPath, path);
                    await self.$scope._.OSSClient.delete(path);

                    let update = {};
                    for (let key in self.$scope._.Group.data[name]) {
                        let resource = self.$scope._.Group.data[name][key];
                        let _resource = self.$util.clone(resource);
                        _resource.newName = _resource.name.replace(name, newName);
                        update[key] = _resource
                    }

                    await self.renameResources(update);
                    self.$scope._.Group.switch(newName);
                    this.newName = '';
                    this.show = false;
                },
                cancel() {
                    this.newName = '';
                    this.show = false;
                }
            },
            Delete: {
                show: false,
                async ok(name) {
                    // console.log('group Delete', name);
                    let index = self.$scope._.Group.names.indexOf(name);
                    if (index < 0) return self.$alert.warning('不存在分组');
                    self.$scope._.Group.names.splice(index, 1);
                    try {
                        let path = `puzzle/games/${self.$scope._.gameId}/${name}/`;
                        await self.$scope._.OSSClient.delete(path);
                        delete self.$scope._.Group.data[name];
                        delete self.$scope._.Group.number[name];
                        self.$scope._.Group.switch();
                        self.$scope._.Group.initGroupData();
                    } catch (e) {
                        console.error(e);
                        this.$alert.error(e.data && e.data.status && e.data.status.message);
                    }
                    this.show = false;
                },
                cancel() {
                    this.show = false;
                }
            }
        };

        // 资源(图片)
        this.$scope._.Resource = {
            blur(resource) {
                self.$timeout(() => {
                    resource.focus = false;
                }, 150);
            },

            Rename: {
                select: '',
                newName: '',

                show(resource) {
                    this.select = resource.key;
                    this.newName = resource.name;
                    self.$timeout(() => $('#rename-' + resource.key).focus(), 150);
                },
                async ok(resource) {
                    if (!this.newName) {
                        self.$alert.warning('名称不能为空');
                        return;
                    }
                    if (this.newName === resource.name) {
                        this.select = '';
                        this.newName = '';
                        return;
                    }
                    let arr = this.newName.split('.');
                    let format = arr[arr.length - 1].toLowerCase();
                    if (self.$rootScope.__.getGameResourceType({format}) !== resource.type) {
                        self.$alert.warning('名称不合法');
                        return;
                    }
                    let _resource = self.$util.clone(resource);
                    _resource.newName = this.newName;
                    await self.renameResources({[_resource.key]: _resource});

                    this.select = '';
                    this.newName = '';
                    self.$timeout(() => self.$scope.$apply());
                }
            },
            MoveGroup: {
                select: '',
                toGroupName: '',

                async ok(resource) {
                    if (!this.toGroupName) {
                        self.$alert.warning('没有选择组');
                        return;
                    }

                    let _resource = self.$util.clone(resource);
                    let groupName = self.$scope._.getResourceGroup(resource);
                    if (groupName) {
                        if ('no_group' === this.toGroupName) {
                            _resource.newName = _resource.name.replace(groupName + '/', '');
                        } else {
                            _resource.newName = _resource.name.replace(groupName, this.toGroupName);
                        }
                    } else {
                        _resource.newName = this.toGroupName + '/' + _resource.name;
                    }
                    await self.renameResources({[_resource.key]: _resource});

                    this.select = '';
                    this.toGroupName = '';
                },
                cancel() {
                    this.select = '';
                    this.toGroupName = '';
                }
            },
            Delete: {
                select: '',

                async ok(resource) {
                    console.log('Resource Delete', this.select);
                    await self.deleteResources({[resource.key]: resource});
                    this.select = '';
                },
                cancel() {
                    this.select = '';
                }
            },
            GifInterval: {
                select: '',
                interval: 0.02,

                show(resource) {
                    this.interval = resource.interval || 0.02;
                    this.select = resource.key;
                    $('#game_resource_gif_interval_modal').modal();
                },
                async ok() {
                    let resource = self.$scope.resources.gifs[this.select];
                    resource.interval = this.interval;
                    await self.postGameResources({[this.select]: resource});
                },
                cancel() {
                    this.select = '';
                }
            },
        };

        // 图文
        this.$scope._.imageAndText = {
            name: '',
            gameValue: [],
            gameValueModel: {key: '', value: '', type: ''},
            show() {
                self.$scope._.imageAndText.name = '';
                self.$scope._.imageAndText.gameValue = [];
                $('#game_detail_resource_imageAndText_create_modal').modal('show');
                if (self.$scope._.imageAndText.richTextEditor) return;
                console.log('******create editor2222')
                self.$scope._.imageAndText.richTextEditor = new Jodit('#editor', {
                    "height": "200px",
                    "language": "zh_cn",
                    "defaultActionOnPaste": "insert_only_text",
                    "disablePlugins": "color",
                    "buttons": "source,bold,strikethrough,underline,italic,|,fontsize,brush,|,align,undo,redo,|",
                    "buttonsMD": "source,bold,strikethrough,underline,italic,|,fontsize,brush,|,align,undo,redo,|",
                    "buttonsSM": "source,bold,strikethrough,underline,italic,|,fontsize,brush,|,align,undo,redo,|",
                    "buttonsXS": "source,bold,strikethrough,underline,italic,|,fontsize,brush,|,align,undo,redo,|",
                    "askBeforePasteHTML": false,
                    "askBeforePasteFromWord": false,
                });
            },
            onChooseImageResource: resource => {
                this.$scope._.imageAndText.gameValueModel.key = 'image';
                this.$scope._.imageAndText.gameValueModel.type = 'baseInfo';
                if (!resource) this.$scope._.imageAndText.gameValueModel.value = '';
                else this.$scope._.imageAndText.gameValueModel.value = this.$rootScope.__.getGameResourceUrl(this.$scope._.gameId, resource);
                // else this.$scope._.imageAndText.gameValueModel.value = resource.key;

                $('#game_detail_resource_imageAndText_create_image_modal').modal('hide');
                this.$scope._.imageAndText.addGameValue(this.$scope._.imageAndText.gameValueModel);
            },
            editorItem: index => {
                let item = this.$scope._.imageAndText.gameValue[index];
                item.index = index;
                item.status = 'edit';

                this.$scope._.imageAndText.gameValueModel = item;
                switch (item.key) {
                    case 'empty':
                        $('#game_detail_resource_imageAndText_create_empty_modal').modal('show');
                        break;
                    case 'title':
                        $('#game_detail_resource_imageAndText_create_title_modal').modal('show');
                        break;
                    case 'content':
                        this.$scope._.imageAndText.richTextEditor.selection.area.innerHTML = RTtoHTML(item.value);
                        $('#game_detail_resource_imageAndText_create_content_modal').modal('show');
                        break;
                    case 'image':
                        $('#game_detail_resource_imageAndText_create_image_modal').modal('show');
                        break;

                }
            },
            deleteItem: index => {
                this.$scope._.imageAndText.gameValue.splice(index, 1);
            },
            createEditor: (index, prefix = '') => {
                if (this.$scope._.imageAndText.rtEditor && Object.keys(this.$scope._.imageAndText.rtEditor).length != 0) {
                    this.$scope._.imageAndText.rtEditor.destruct()
                    this.$scope._.imageAndText.rtEditor = {}
                }
                console.log('******create editor')
                let newId = '#editor' + index + prefix;
                this.$scope._.imageAndText.rtEditor = new Jodit(newId, {
                    "width": "100%",
                    "language": "zh_cn",
                    "defaultActionOnPaste": "insert_only_text",
                    "disablePlugins": "color",
                    "buttons": "source,bold,strikethrough,underline,italic,|,fontsize,brush,|,align,undo,redo,|,insertUnderlineX",
                    "buttonsMD": "source,bold,strikethrough,underline,italic,|,fontsize,brush,|,align,undo,redo,|,insertUnderlineX",
                    "buttonsSM": "source,bold,strikethrough,underline,italic,|,fontsize,brush,|,align,undo,redo,|,insertUnderlineX",
                    "buttonsXS": "source,bold,strikethrough,underline,italic,|,fontsize,brush,|,align,undo,redo,|,insertUnderlineX",
                    "askBeforePasteHTML": false,
                    "askBeforePasteFromWord": false,
                    controls: {
                        insertUnderlineX: {
                            name: 'insertUnderlineX',
                            iconURL: 'https://resource.itaotuo.com/lib/jodit/xhx.png',
                            exec: function (editor) {
                                let text = editor.selection.getHTML();
                                if (text) {
                                    let html = '<x>' + text + '</x>';
                                    editor.selection.insertHTML(html);
                                    editor.selection.remove();
                                }

                            }
                        }
                    }
                });
                this.$scope._.imageAndText.rtEditor.selection.focus();
            },
            destructEditor: () => {
                console.log(this.$scope._.imageAndText.rtEditor)
                if (this.$scope._.imageAndText.rtEditor && Object.keys(this.$scope._.imageAndText.rtEditor).length != 0) {
                    this.$scope._.imageAndText.rtEditor.destruct()
                    this.$scope._.imageAndText.rtEditor = {}
                }
            }
            ,
            //增加数值模型
            addGameModel: (key, potNum) => {
                this.$scope._.imageAndText.gameValueModel.key = key;
                switch (key) {
                    case 'empty':
                        //$('#game_detail_resource_imageAndText_create_empty_modal').modal('show');
                        this.$scope._.imageAndText.addGameValue({
                            key: 'empty',
                            value: '',
                            potNum
                        })
                        break;
                    case 'title':
                        //$('#game_detail_resource_imageAndText_create_title_modal').modal('show');
                        this.$scope._.imageAndText.addGameValue({
                            key: 'title',
                            value: '',
                            potNum
                        })
                        break;
                    case 'content':
                        //$('#game_detail_resource_imageAndText_create_content_modal').modal('show');
                        this.$scope._.imageAndText.addGameValue({
                            key: 'content',
                            value: '',
                            potNum
                        })
                        break;
                    case 'image':
                        if (potNum) {
                            this.$scope._.imageAndText.addGameValue({
                                key: 'image',
                                value: 'https://resource.itaotuo.com/puzzle/logo.svg',
                                potNum
                            })
                        } else {
                            $('#game_detail_resource_imageAndText_create_image_modal').modal('show');
                        }
                        break;
                }
            },
            selectModelShow: index => {
                let showStatus = this.$scope._.imageAndText.gameValue[index].show;
                if (showStatus) {
                    this.$scope._.imageAndText.gameValue[index].show = false
                } else {
                    this.$scope._.imageAndText.gameValue[index].show = true
                }
                console.log(showStatus)
            }
            ,
            //添加游戏值
            addGameValue: value => {
                if (value.key === 'content') {
                    let data = {
                        key: value.key,
                        value: value.value,
                        type: 'baseInfo',
                        show: false
                    };
                    if (value.status === 'edit') {
                        this.$scope._.imageAndText.gameValue[value.index] = data;
                    } else {
                        if (value.potNum) {
                            this.$scope._.imageAndText.gameValue.splice(value.potNum, 0, data)
                        } else {
                            this.$scope._.imageAndText.gameValue.push(data)
                        }

                    }
                } else {
                    let data = {
                        key: value.key,
                        value: value.value,
                        type: 'baseInfo',
                        show: false
                    };
                    if (value.status === 'edit') {
                        this.$scope._.imageAndText.gameValue[value.index] = data;
                    } else {
                        if (value.potNum) {
                            this.$scope._.imageAndText.gameValue.splice(value.potNum, 0, data)
                        } else {
                            this.$scope._.imageAndText.gameValue.push(data)
                        }
                    }
                }
                //添加完成隐藏添加框
                value.potNum ? this.$scope._.imageAndText.gameValue[value.potNum - 1].show = false : '';
                this.$scope._.imageAndText.richTextEditor ? this.$scope._.imageAndText.richTextEditor.selection.area.innerHTML = '' : '';
                //添加完成后清除值
                this.$scope._.imageAndText.gameValueModel.key = '';
                this.$scope._.imageAndText.gameValueModel.value = '';
                this.$scope._.imageAndText.gameValueModel.status = '';


                $('#game_detail_resource_imageAndText_create_empty_modal').modal('hide');
                $('#game_detail_resource_imageAndText_create_title_modal').modal('hide');
                $('#game_detail_resource_imageAndText_create_content_modal').modal('hide');
                $('#game_detail_resource_imageAndText_create_image_modal').modal('hide');

            },

            postGameImageAndText: () => {
                let name = this.$scope._.imageAndText.name;
                if (!name) return this.$alert('请输入图文名称');

                let formatGameValue = this.$scope._.imageAndText.gameValue.map(item => {
                    if (item.key == 'content') {
                        item.value = HTMLtoRT(item.value)
                    }
                    let res = {
                        key: item.key,
                        value: item.value,
                        type: item.type
                    }
                    return res
                });

                this.ApiService.postGameImageAndText(this.$scope._.gameId, name, formatGameValue)
                    .then(game => {
                        this.$rootScope.__.GAMES[this.$scope._.gameId].dev.others.resources = game.others.resources;
                        this.initData();
                    })
                    .catch(error => {
                        console.error(error);
                        this.$alert.error(error.data && error.data.status && error.data.status.message);
                    });
                $('#game_detail_resource_imageAndText_create_modal').modal('hide');
            },

            putGameImageAndText: () => {
                let resId = this.$scope._.imageAndText.resId;
                let name = this.$scope._.imageAndText.name;
                if (!name) return this.$alert('请输入图文名称');
                if (!resId) return this.$alert('参数错误, 未找到resId');
                //格式化提交过来的数据
                let formatGameValue = this.$scope._.imageAndText.gameValue.map(item => {
                    if (item.key == 'content') {
                        item.value = HTMLtoRT(item.value)
                    }
                    let res = {
                        key: item.key,
                        value: item.value,
                        type: item.type
                    }
                    return res
                });

                //处理提交上来的数据
                this.ApiService.putGameImageAndText(this.$scope._.gameId, resId, name, formatGameValue)
                    .then(game => {
                        this.$rootScope.__.GAMES[this.$scope._.gameId].dev.others.resources = game.others.resources;
                        this.initData();
                    })
                    .catch(error => {
                        console.error(error);
                        this.$alert.error(error.data && error.data.status && error.data.status.message);
                    });
                $('#game_detail_resource_imageAndText_update_modal').modal('hide');
            },
        };

        // 历史
        this.$scope._.ResourcesLog = {
            page: 1,
            loaded: false,
            total: [],
            list: [],

            async init(page = 1) {
                let {total, list} = await self.ApiService.getGameResourcesLog(self.$scope._.gameId, page);
                this.total = total;
                if (list.length < 20) this.loaded = true;
                this.list = this.list.concat(list);
                self.$scope.$apply();
            },

            show() {
                $('#game_resource_update_history_modal').modal('show');
            },

            getTitle(update) {
                let post_title = '';
                let delete_title = '';
                if (!update) return '';
                if (update.$set) {
                    post_title += '上传/更新: ';
                    post_title += Object.values(update.$set).map(resource => resource.name).join(', ');
                }
                if (update.$unset) {
                    delete_title += '删除: ';
                    let keys = Object.keys(update.$unset).map(key => key.split('.')[key.split('.').length - 1]);
                    delete_title += Object.values(update.$unset).map((name, index) => name === 1 ? keys[index] : name).join(', ');
                }
                return post_title + ' ' + delete_title;
            },

            getContent(update) {
                let data = {
                    '上传/更新': [],
                    '删除': [],
                };
                if (!update) return data;
                if (update.$set) {
                    data['上传/更新'] = Object.values(update.$set).map(resource => resource.name);
                }
                if (update.$unset) {
                    let keys = Object.keys(update.$unset).map(key => key.split('.')[key.split('.').length - 1]);
                    data['删除'] = Object.values(update.$unset).map((name, index) => name === 1 ? keys[index] : name);
                }
                return data;
            },

            getNextPageLog() {
                this.page++;
                this.init(this.page);
            },
        }
    }

    initFunction() {
        this.$scope._.onBlankClick = () => {
            if (this.$scope._.Group.Add.show) this.$scope._.Group.Add.show = false;
            if (this.$scope._.Group.Rename.show) this.$scope._.Group.Rename.show = false;
            if (this.$scope._.Group.Delete.show) this.$scope._.Group.Delete.show = false;

            if (this.$scope._.Batch.Move.show) {
                this.$scope._.Batch.Move.show = false;
                this.$scope._.Batch.Move.to = '';
            }
            if (this.$scope._.Batch.Delete.show) this.$scope._.Batch.Delete.show = false;

            if (this.$scope._.Resource.Delete.select) {
                this.$scope._.Resource.Delete.select = '';
            }
            if (this.$scope._.Resource.MoveGroup.select) {
                this.$scope._.Resource.MoveGroup.select = '';
                this.$scope._.Resource.MoveGroup.toGroupName = '';
            }
        };

        this.$scope._.getResourceGroup = resource => resource.name.includes('/') ? resource.name.split('/')[0] : '';

        this.$scope._.deleteResourceInUploaderQueue = $index => this.$scope._.uploader_queue.splice($index, 1);

        this.$scope._.info_modal = {
            res: {},
            show: res => {
                this.$scope._.info_modal.res = res;
                $('#game_detail_resource_info_modal').modal('show');
            },
            isImage: path => {
                if (!path) return false;
                let arr = path.split('.');
                let format = arr[arr.length - 1].toLowerCase();
                return ['jpg', 'jpeg', 'png', 'gif', 'svg', 'bmp'].includes(format);
            },
            isAudio: path => {
                if (!path) return false;
                let arr = path.split('.');
                let format = arr[arr.length - 1].toLowerCase();
                return ['mp3'].includes(format);
            },
            isVideo: path => {
                if (!path) return false;
                let arr = path.split('.');
                let format = arr[arr.length - 1].toLowerCase();
                return ['mp4'].includes(format);
            }
        };

        this.$scope._.onUpload = async () => {
            // await this.syncOssFileList();
            await this.initData();
        };
        this.$scope._.onUploadBtnClick = type => {
            if ("imageAndText" === type) {
                this.$scope._.imageAndText.show();
            } else {
                $('#game_detail_resource_upload_modal').modal('show');
                // document.getElementById('inputFile').disabled = '';
            }
        };
        this.$scope._.parseFileName = path => {
            if (!path) return '';
            return path.replace('puzzle/games/' + this.$scope._.gameId + '/', '');
        };
        this.$scope._.parseFileUrl = name => {
            if (!name) return '';
            return `https://resource.itaotuo.com/puzzle/games/${this.$scope._.gameId}/${name}`;
        };
        this.$scope._.parseFileKey = path => {
            let name = path.replace('puzzle/games/' + this.$scope._.gameId + '/', '');
            return 'nrk_' + this.getStringSign(name);
        };

        this.$scope._.parseFileFormat = path => {
            if (path.endsWith('/')) return 'dir';
            let arr = path.split('.');
            return arr[arr.length - 1];
        };
        this.$scope._.parseFileSize = size => Number(size) / 1024;
        this.$scope._.parseUploadStatusName = status => {
            if (status === 0) return '上传中…';
            if (status === 1) return '上传成功';
            if (status === 2) return '上传失败';
            if (status === 3) return '文件格式不支持';
        };
        this.$scope._.parseUploadStatusClass = status => {
            if (status === 0) return 'text-secondary';
            if (status === 1) return 'text-success';
            if (status === 2) return 'text-danger';
        };


        this.$scope._.showImageAndTextInfoModal = resource => {
            this.$scope._.onSelectImageAndTextInfo = resource;
            $('#game_detail_resource_imageAndText_info_modal').modal('show');

        };
        this.$scope._.onUpdateImageAndText = resource => {
            console.log(resource)

            //先格式化为html文本
            resource.value.map(item => {
                if (item.key == 'content') {
                    item.value = RTtoHTML(item.value)
                }
                let res = {
                    key: item.key,
                    value: item.value,
                    type: item.type,
                    show: false
                }
                return res
            })
            this.$scope._.imageAndText.gameValue = resource.value;

            this.$scope._.imageAndText.name = resource.name;
            this.$scope._.imageAndText.resId = resource.key;
            $('#game_detail_resource_imageAndText_info_modal').modal('hide');
            $('#game_detail_resource_imageAndText_update_modal').modal('show');
        };

        this.$scope._.deleteGameImageAndText = resource => {
            this.$modal(`确定删除此图文吗?。`, '删除后无法恢复，确定删除吗？');
            this.$modal.on_ok = async () => {
                await this.deleteResources({[resource.key]: resource});
                this.$modal.hide();
            };
        };

        this.$scope._.copyResourceUrlToClipboard = resource => {
            let result = this.$util.copy2Clipboard(this.$rootScope.__.getGameResourceUrl(this.$scope._.gameId, resource));
            if ('success' === result) {
                this.$alert.success('复制成功');
            } else {
                this.$alert.error('复制失败');
                console.error(result);
            }
        };

        this.$scope._.getBytesLength = string => {
            if (!string) return 0;
            if ('object' === typeof string) string = JSON.stringify(string);
            let num = string.length;
            for (let i = 0; i < string.length; i++) {
                if (string.charCodeAt(i) > 255) {
                    num++;
                }
            }
            return num / 1024;
        };

        // window.onUploadFile = this.onUploadFile.bind(this);
    }

    initData() {
        this.$scope.resources = {
            images: this.getResourcesByType('image', this.$rootScope.__.GAMES[this.$scope._.gameId].dev.others.resources),
            gifs: this.getResourcesByType('gif', this.$rootScope.__.GAMES[this.$scope._.gameId].dev.others.resources),
            audios: this.getResourcesByType('audio', this.$rootScope.__.GAMES[this.$scope._.gameId].dev.others.resources),
            videos: this.getResourcesByType('video', this.$rootScope.__.GAMES[this.$scope._.gameId].dev.others.resources),
            imageAndTexts: this.getResourcesByType('imageAndText', this.$rootScope.__.GAMES[this.$scope._.gameId].dev.others.resources),
            dirs: this.getResourcesByType('dir', this.$rootScope.__.GAMES[this.$scope._.gameId].dev.others.resources),
        };
        this.$scope.resources.imageNumber = this.$util.getObjectSize(this.$scope.resources.images);
        this.$scope.resources.gifNumber = this.$util.getObjectSize(this.$scope.resources.gifs);
        this.$scope.resources.audioNumber = this.$util.getObjectSize(this.$scope.resources.audios);
        this.$scope.resources.videoNumber = this.$util.getObjectSize(this.$scope.resources.videos);
        this.$scope.resources.imageAndTextNumber = this.$util.getObjectSize(this.$scope.resources.imageAndTexts);

        this.$scope._.Group.initGroup(Object.values(this.$scope.resources.dirs));
        this.$scope._.ResourcesLog.init();
        this.$timeout(() => this.$scope.$apply());
    }

    async checkData() {
        try {
            await this.initGameSTSToken();
            await this.syncOssFileList();
        } catch (error) {
            console.error(error);
            this.$alert.error(error.data && error.data.status && error.data.status.message);
        }

    }

    // async onUploadFile() {
    //     let files = document.getElementById('inputFile').files;
    //     let group = '';
    //     if ('image' === this.$scope._.pageType && this.$scope._.Group.select && this.$scope._.Group.select !== 'no_group') {
    //         group = '/' + this.$scope._.Group.select;
    //     }
    //     // document.getElementById('inputFile').disabled = 'disabled';
    //     let pattern = new RegExp("[`+*~^&()=|{}':;',\\[\\]<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
    //     for (let file of files) {
    //         let _path = `puzzle/games/${this.$scope._.gameId}${group}/${file.name}`;
    //         this.$scope._.uploader_queue.push({name: _path, status: 0, size: file.size});
    //         let index = this.$scope._.uploader_queue.length - 1;
    //         if (pattern.test(file.name)) {
    //             this.$scope._.uploader_queue[index].status = 2;
    //             this.$alert.error('文件名不允许有特殊字符!');
    //             continue;
    //         }
    //         try {
    //             await this.$scope._.OSSClient.multipartUpload(_path, file);
    //             this.$scope._.uploader_queue[index].status = 1;
    //         } catch (error) {
    //             this.$scope._.uploader_queue[index].status = 2;
    //             console.error(error);
    //         }
    //         this.$scope.$apply();
    //     }
    //     await this.syncOssFileList();
    // }

    // 同步OSS资源库数据
    async syncOssFileList() {
        let _index = syncOssFileListIndex++;
        this.$progress();
        console.log('on GameDetailResourceController.js syncOssFileList id:', _index);
        let list = await this.listDir('puzzle/games/' + this.$scope._.gameId + '/');
        let $set = {};
        let $unset = {};
        let keys = {};
        // let dirs = [];
        let resources = this.$rootScope.__.GAMES[this.$scope._.gameId].dev.others.resources || {};
        // 检查新增资源
        for (let item of list) {
            let format = this.$scope._.parseFileFormat(item.name);
            let type = this.$rootScope.__.getGameResourceType({format});
            if (!type) continue;
            let key = this.$scope._.parseFileKey(item.name);
            if (!resources[key]) {
                let name = item.name.replace('puzzle/games/' + this.$scope._.gameId + '/', '');
                key = 'nrk__' + this.getStringKey(name);
            }
            keys[key] = 1;
            let resource = resources[key];
            if (!resource || !resource.lastModified || resource.lastModified < item.lastModified) {
                let re = {
                    key,
                    name: this.$scope._.parseFileName(item.name),
                    lastModified: item.lastModified,
                    size: item.size,
                    type,
                    format,
                };
                if (resource) {
                    if (resource.interval) re.interval = resource.interval;
                }
                if (this.$scope._.info_modal.isImage(item.name)) {
                    try {
                        let imgInfo = await GameDetailResourceController.getImageInfoByOSS(this.$scope._.parseFileUrl(re.name));
                        re.ImageHeight = imgInfo.ImageHeight.value;
                        re.ImageWidth = imgInfo.ImageWidth.value;
                        console.log(re)
                        // if (re.ImageWidth > 2048 || re.ImageHeight > 2048) {
                        //     this.$alert.error(`图片${re.name}尺寸超出2048×2048, 将无法用于游戏内容!`)
                        // }
                        // if (re.size > 1048576) {
                        //     this.$alert.error(`图片${re.name}大小超出1M, 将无法用于游戏内容!`)
                        // }
                    } catch (error) {
                        console.error(error);
                    }
                }
                if (this.$scope._.info_modal.isAudio(item.name)) {
                    try {
                        let url = 'https://resource.itaotuo.com/' + item.name;
                        re.duration = await this.getAudioDuration(url);
                    } catch (error) {
                        console.error(error);
                    }
                }
                if (this.$scope._.info_modal.isVideo(item.name)) {
                    try {
                        let url = 'https://resource.itaotuo.com/' + item.name;
                        let frame_url = url + '?x-oss-process=video/snapshot,t_1,f_jpg,m_fast';
                        let imgInfo = await this.getImageSize(frame_url);
                        re.duration = await this.getVideoDuration(url);

                        re.ImageHeight = imgInfo.ImageHeight;
                        re.ImageWidth = imgInfo.ImageWidth;
                    } catch (error) {
                        console.error(error);
                    }
                }
                $set[key] = re;
            }
        }
        // 更新新增资源
        if (this.$util.getObjectSize($set)) await this.postGameResources($set);
        // 检查删除资源
        for (let key in resources) {
            let type = resources[key].type;
            if (!type) continue;
            if ('imageAndText' === type) continue;
            if (resources.hasOwnProperty(key) && !keys[key]) $unset[key] = resources[key];
        }
        // 更新删除资源
        if (this.$util.getObjectSize($unset)) {
            // await this.deleteResources($unset);
        }
        console.log('end GameDetailResourceController.js syncOssFileList id:', _index);
        this.$progress.end();
    }

    async initGameSTSToken() {
        let credentials = await this.ApiService.getGameSTSToken(this.$scope._.gameId);
        this.$scope._.OSSClient = new OSS({
            region: 'oss-cn-beijing',
            accessKeyId: credentials.AccessKeyId,
            accessKeySecret: credentials.AccessKeySecret,
            stsToken: credentials.SecurityToken,
            bucket: 'om-resources',
            endpoint: 'resource.itaotuo.com',
            cname: true,
        });
        this.$timeout(() => this.initGameSTSToken(), new Date(credentials.Expiration) - new Date() - 30000);
    }

    async getImageSize(url) {
        let imageDom = new Image();
        imageDom.src = url;
        return new Promise(resolve => {
            imageDom.onload = function () {
                let ImageWidth = imageDom.naturalWidth || imageDom.width;
                let ImageHeight = imageDom.naturalHeight || imageDom.height;
                resolve({ImageWidth, ImageHeight});
            }
        })
    }

    async getAudioDuration(url) {
        let audioDom = new Audio();
        audioDom.src = url;
        return new Promise(resolve => {
            audioDom.onloadedmetadata = function () {
                resolve(audioDom.duration);
            }
        })
    }

    async getVideoDuration(url) {
        let videoDom = document.createElement('video');
        videoDom.src = url;
        return new Promise(resolve => {
            videoDom.onloadedmetadata = function () {
                resolve(videoDom.duration);
            }
        })
    }

    async listDir(dir, marker, arr = []) {
        let value = await this.$scope._.OSSClient.list({
            prefix: dir,
            marker: marker,
            'max-keys': 1000
        });
        if (!value.objects) return arr;
        for (let item of value.objects) arr.push(item);
        if (value.isTruncated) return await this.listDir(dir, value.nextMarker, arr);
        else return arr;
    }

    async postGameResources(resources) {
        console.log('post', Object.values(resources).map(r => r.name));
        let game = await this.ApiService.postGameResources(this.$scope._.gameId, resources);
        this.$rootScope.__.GAMES[this.$scope._.gameId].dev.others.resources = game.others.resources;
        // if (document.getElementById('inputFile')) document.getElementById('inputFile').disabled = '';
        this.initData();
    }

    static async getImageInfoByOSS(url) {
        let data = await OSS.urllib.request(url + '?x-oss-process=image/info', {method: 'GET'});
        return JSON.parse(data.data);
    }

    async renameResources(resources) {
        try {
            for (let key in resources) {
                let path = `puzzle/games/${this.$scope._.gameId}/${resources[key].name}`;
                let newPath = `puzzle/games/${this.$scope._.gameId}/${resources[key].newName}`;
                await this.$scope._.OSSClient.copy(newPath, path);
            }
            await this.deleteResources(resources);
            await this.syncOssFileList();
        } catch (e) {
            console.error(e);
            this.$alert.error(e.data && e.data.status && e.data.status.message);
        }
    }

    async deleteResources(resources) {
        console.log('delete', Object.values(resources).map(r => r.name));
        let $unset = {};
        for (let key in resources) {
            $unset[key] = resources[key].name;
        }
        try {
            let game = await this.ApiService.deleteGameResources(this.$scope._.gameId, $unset);
            this.$rootScope.__.GAMES[this.$scope._.gameId].dev.others.resources = game.others.resources;
            this.initData();
        } catch (e) {
            console.error(e);
            this.$alert.error(e.data && e.data.status && e.data.status.message);
        }
    }

    getStringSign(string) {
        let temp = encodeURIComponent((Buffer.from(string)).toString('base64')).replace(/%../g, '');
        if (temp.length > 28) {
            let str = '', d = Math.floor((temp.length - 12) / 16);
            for (let i = 3; i < temp.length - 8; i += (d + 1)) str += temp[i];
            return temp.substring(0, 4) + str.substring(0, 16) + temp.substring(temp.length - 8);
        }
        return temp;
    }

    /**
     * getStringKey
     * @param string {String}
     */
    getStringKey(string) {
        let result = '';
        for (let i = 0; i < string.length; i++) {
            let num = string.charCodeAt(i);
            let s_62 = '';
            let dict = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
            if (dict.includes(string[i])) result += string[i];
            else {
                do {
                    s_62 = dict.charAt(num % 62) + s_62;
                    num = Math.floor(num / 62);
                } while (num > 0)
                result += s_62;
            }
        }
        return result;
    }

    getResourcesByType(type, resources) {
        if (!resources) return {};
        let _resources = JSON.parse(JSON.stringify(resources));
        let temp = {};
        for (let key in _resources)
            if (type === _resources[key].type)
                temp[key] = _resources[key];
        return temp;
    }
}

GameDetailResourceController.$inject = ['$scope', '$rootScope', '$location', '$window', '$routeParams', '$timeout', 'ApiService', '$event', '$alert', '$modal', '$util', '$progress'];
app.controller('GameDetailResource', GameDetailResourceController);
