const app = angular.module('editor');
import GameEditorController from './GameEditorController';

class GameEditorGraphicalController extends GameEditorController {
    constructor($scope, $rootScope, $window, $routeParams, $timeout, $event, $alert, $modal, $storage, ApiService) {
        super($scope, $rootScope, $window, $routeParams, $timeout, $event, $alert, $modal, $storage, ApiService);

        $scope.EditorMode.select = 'graphical';
        $scope.graphical = {};

        let isDebug = $routeParams.debug === 'true';
        if (isDebug) {
            if (!/debug/.test($rootScope.__.GAME_EDITOR_URL)) {
                $rootScope.__.GAME_EDITOR_URL = $rootScope.__.GAME_EDITOR_URL
                    .replace('gameeditor.html', 'gameeditor-debug.html')
                    .replace('gameeditor-profiler.html', 'gameeditor-debug.html')
                    .replace('gameeditor-profiler1.html', 'gameeditor-debug.html')
            }
            console.log('进入debug模式')
        }

        let ispProfiler = $routeParams.profiler === 'true';
        if (ispProfiler) {
            if (!/profiler/.test($rootScope.__.GAME_EDITOR_URL)) {
                $rootScope.__.GAME_EDITOR_URL = $rootScope.__.GAME_EDITOR_URL
                    .replace('gameeditor.html', 'gameeditor-profiler.html')
                    .replace('gameeditor-debug.html', 'gameeditor-profiler.html')
                    .replace('gameeditor-profiler1.html', 'gameeditor-profiler.html')
            }
            console.log('进入profiler模式')
        }

        let ispProfiler1 = $routeParams.profiler1 === 'true';
        if (ispProfiler1) {
            if (!/profiler1/.test($rootScope.__.GAME_EDITOR_URL)) {
                $rootScope.__.GAME_EDITOR_URL = $rootScope.__.GAME_EDITOR_URL
                    .replace('gameeditor.html', 'gameeditor-profiler1.html')
                    .replace('gameeditor-debug.html', 'gameeditor-profiler1.html')
                    .replace('gameeditor-profiler.html', 'gameeditor-profiler1.html')
            }
            console.log('进入profiler1模式')
        }

    }
}

GameEditorGraphicalController.$inject = ['$scope', '$rootScope', '$window', '$routeParams', '$timeout', '$event', '$alert', '$modal', '$storage', 'ApiService'];
app.controller('GameEditorGraphical', GameEditorGraphicalController);

