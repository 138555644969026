const app = angular.module('editor');

class LoginController {
    constructor($scope, $rootScope, $routeParams, $location, $alert, $event, $ws, ApiService) {
        let redirect = $routeParams.redirect;
        $scope.showQR = true;
        $scope.UI = {
            nav_brand_text: {
                cn: '谜案馆-作者平台'
            },
        };
        $scope.login = {
            user: {phone: '', password: '', remember: true},
            submit: () => {
                ApiService.login($scope.login.user)
                    .then(user => {
                        console.log(user.others.nickname + ', account login success');
                        $location.path(redirect || '/game/list').search();
                    })
                    .catch(error => {
                        console.error('login, ', error.data.status);
                        if (error.data && error.data.status && error.data.status.code === 550) {
                            $alert.error('用户名或密码错误!');
                        } else {
                            $alert.error(error.data && error.data.status && error.data.status.message);
                        }
                    });
            }
        };
        $scope.reGenerateLoginQrcode = () => {
            $ws.connect();
            $ws.generateLoginQrcode().then();
        }
        let qrcode = new QRCode(document.getElementById('login_qrcode'), {
            width: 190,
            height: 190,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        });
        if ($rootScope.__.CheckLogin()) return $location.path(redirect || '/game/list');
        // else $event.on('login', 'LoginController.login', () => $location.path(redirect || '/dashboard'));

        $('[data-toggle="tooltip"]').tooltip();

        // ws 获取登录二维码
        $scope.reGenerateLoginQrcode();
        $event.on('ws.generate_login_qrcode', 'LoginController.ws.generate_login_qrcode', data => {
            $scope.$apply(() => $scope.showQR = true);
            console.log('ws on generate_login_qrcode:', data.content);
            qrcode.makeCode(data.content);
        })
        $event.on('ws.qrcode_login', 'LoginController.ws.qrcode_login', async data => {
            if (data.code === 200) {
                let user = await ApiService.loginByToken(data.data);
                console.log(user.others.nickname + ', qrcode login success');
                // $location.path(redirect || '/dashboard').search();
            }
        })

        $event.on('login_qrcode_timeout', 'LoginController.login_qrcode_timeout', () => {
            qrcode.clear();
            $scope.$apply(() => $scope.showQR = false);
        })
        $event.on('ws_close', 'LoginController.ws_close', () => {
            qrcode.clear();
            $scope.$apply(() => $scope.showQR = false);
        })
    }
}

LoginController.$inject = ['$scope', '$rootScope', '$routeParams', '$location', '$alert', '$event', '$ws', 'ApiService'];

app.controller('LoginCtrl', LoginController);