const app = angular.module('editor');

class IndexController {
    constructor($scope, $rootScope, $location, $event, $alert, ApiService) {

        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.$event = $event;
        this.$alert = $alert;
        this.ApiService = ApiService;

        $scope.UI = {
            nav_brand_text: {
                cn: '谜案馆-作者平台'
            },
            nav_dashboard_text: {
                cn: '概览',
                en: 'Dashboard',
            },
            nav_game_text: {
                cn: '游戏',
                en: 'Game',
            },
            nav_article_text: {
                cn: '图文',
                en: 'article',
            },
            nav_statistical_text: {
                cn: '统计',
                en: 'Statistical',
            },
            nav_plugin_text: {
                cn: '插件',
                en: 'Plugin',
            },
            nav_account_menu_title_text: {
                cn: '登录账号:',
                en: 'Signed in as',
            },
            nav_account_menu_your_game_text: {
                cn: '您的游戏',
                en: 'your games',
            },
            nav_account_menu_setting_text: {
                cn: '个人资料',
                en: 'Setting',
            },
            nav_account_menu_help_text: {
                cn: '帮助',
                en: 'Help',
            },
            nav_account_menu_sign_out_text: {
                cn: '退出登录',
                en: 'Sign out',
            },
        };
        $scope.search = {
            value: '',
            keydown: event => {
                if (event && event.keyCode === 13) {
                    console.log('index search:', $scope.search.value);
                }
            }
        };

        $scope.logout = () => ApiService.logout();

        $event.on('ws.game_collaboration_delete', 'IndexController.ws.game_collaboration_delete', async data => {
            $alert(data.content);
            await this.ApiService.reloadMeDataOnDeleteGamePermissions(data.attachment.gameId);
        });
        $event.on('ws.game_collaboration_add', 'IndexController.ws.game_collaboration_add', async data => {
            $alert(data.content);
            await this.ApiService.reloadMeDataOnAddGamePermissions(data.attachment.gameId, data.attachment.editorUserId);
        });
        $event.on('ws.game_tester_add', 'IndexController.ws.game_tester_add', data => console.log(data.content));
        $event.on('ws.game_tester_delete', 'IndexController.ws.game_tester_delete', data => console.log(data.content));
        $event.on('ws.game_status_pro', 'IndexController.ws.game_status_pro', data => $alert(data.content));

        $('.dropdown-toggle').dropdown()

        // if ($rootScope.__.CheckLogin('/dashboard')) this.init().then();
        // else $event.on('login', 'IndexController.login', () => this.init());
        $event.on('login', 'IndexController.login', () => this.init());
    }

    async init() {
        this.$rootScope.__.UNREAD_MESSAGE_NUMBER = await this.ApiService.countEditorUserMessage('unread');
    }
}

IndexController.$inject = ['$scope', '$rootScope', '$location', '$event', '$alert', 'ApiService'];

app.controller('IndexCtrl', IndexController);
