import ECharts from 'echarts';

const app = angular.module('editor');
import GameDetailController from './GameDetailController';

class GameDetailAnalysisController extends GameDetailController {
    constructor($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util) {
        super($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util);

        this.$scope.UI = {
            breadcrumb_game_text: {
                cn: '游戏',
                en: 'Games',
            },
            breadcrumb_tab_text: {
                cn: '分析',
                en: 'Analysis',
            },
        };
        this.$scope._.tabName = 'analysis';

        this.$scope._.tab = 'OVERVIEW';
        this.$scope._.tabs = {
            OVERVIEW: '概况',
            GAME_DAILY_ADD_PLAYER: '游戏每日增长玩家',
            GAME_DAILY_ACTIVE_PLAYER: '游戏每日活跃玩家',
            PLAYER_GAME_INPUT_PASS_INFO: '玩家游戏关卡通关信息',
        };
        this.$scope._.timeRange = {
            begin: {
                time: "",
                min: "",
                max: ""
            },
            end: {
                time: "",
                min: "",
                max: ""
            },
        };
        this.$scope._.overview = {
            TotalPlayer: "",
            AddPlayerToday: "",
            ActivePlayerToday: ""
        };
        this.init().then();
    }


    async init() {
        await this.initObject();
        this.initTimeRange();
        this.$scope.$watch('_.tab', tab => {
            switch (tab) {
                case 'OVERVIEW':
                    this.GameDailyOverview();
                    break;
                case 'GAME_DAILY_ADD_PLAYER':
                    this.changeTimeOfGameDaily();
                    //this.$scope.GameDailyAddPlayer.init();
                    break;
                case 'GAME_DAILY_ACTIVE_PLAYER':
                    this.changeTimeOfGameDaily();
                    //this.$scope.GameDailyActivePlayer.init();
                    break;
                case 'PLAYER_GAME_INPUT_PASS_INFO':
                    this.$scope.PlayerGameInputPassInfo.init();
                    break;
            }
        });
        this.$scope.$watch('_.timeRange.begin.time', time => {
            if (!!time) this.$scope._.timeRange.end.min = this.getFormatDate(time)
        });
        this.$scope.$watch('_.timeRange.end.time', time => {
            if (!!time) this.$scope._.timeRange.begin.max = this.getFormatDate(time)
        });
        this.$scope.changeTimeOfGameDaily = this.changeTimeOfGameDaily.bind(this);
        this.$scope.changeWeekOfGameDaily = this.changeWeekOfGameDaily.bind(this);
        this.$scope.changeMonthOfGameDaily = this.changeMonthOfGameDaily.bind(this);
    }

    async initObject() {
        let self = this;
        this.$scope.GameDailyAddPlayer = {
            chart: ECharts.init($('#game_daily_add_player_chart')[0]),
            option: {
                tooltip: {
                    trigger: 'axis',
                },
                toolbox: {
                    show: true,
                    feature: {
                        magicType: {type: ['line', 'bar']},
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    name: '日期',
                    axisTick: {
                        alignWithLabel: true,
                    },
                    data: []
                },
                yAxis: {
                    type: 'value',
                    name: '玩家数',
                    minInterval: 1,
                },
                series: {
                    type: 'line',
                    name: '玩家数',
                    cursor: 'pointer',
                    data: [],
                }
            },
            async init() {
                this.chart.showLoading();
                let data = await self.getGameDailyAddPlayerStatistic();
                this.option.xAxis.data = [];
                this.option.series.data = [];
                for (let item of data) {
                    this.option.xAxis.data.push(item.date_str.substring(5));
                    this.option.series.data.push(item.data);
                }
                this.chart.hideLoading();
                this.chart.setOption(this.option);
            }
        };

        this.$scope.GameDailyActivePlayer = {
            chart: ECharts.init($('#game_daily_active_player_chart')[0]),
            option: {
                tooltip: {
                    trigger: 'axis',
                },
                toolbox: {
                    show: true,
                    feature: {
                        magicType: {type: ['line', 'bar']},
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    name: '日期',
                    axisTick: {
                        alignWithLabel: true,
                    },
                    data: []
                },
                yAxis: {
                    type: 'value',
                    name: '玩家数',
                    minInterval: 1,
                },
                series: {
                    type: 'line',
                    name: '玩家数',
                    cursor: 'pointer',
                    data: [],
                }
            },
            async init() {
                this.chart.showLoading();
                let data = await self.getGameDailyActivePlayerStatistic();
                this.option.xAxis.data = [];
                this.option.series.data = [];
                for (let item of data) {
                    this.option.xAxis.data.push(item.date_str.substring(5));
                    this.option.series.data.push(item.data);
                }
                this.chart.hideLoading();
                this.chart.setOption(this.option);
            }
        };

        this.$scope.PlayerGameInputPassInfo = {
            names: {},
            chart: ECharts.init($('#player_game_input_pass_info_chart')[0]),
            option: {
                tooltip: {
                    trigger: 'axis',
                    formatter: params => `${params[0].name}<br/>${self.$scope.PlayerGameInputPassInfo.names[params[0].name]}<br/>${params[0].seriesName}: ${params[0].value}${params[0].seriesName === '平均用时' ? '分钟' : ''}<br/>`
                },
                legend: {
                    selectedMode: 'single',
                    data: ['通关玩家数', '平均得分', '平均用时']
                },
                toolbox: {
                    show: true,
                    feature: {
                        magicType: {type: ['line', 'bar']},
                        saveAsImage: {}
                    }
                },
                dataZoom: {
                    type: 'inside',
                    start: 0,
                    end: 100
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    name: '模块',
                    axisTick: {
                        alignWithLabel: true,
                    },
                    minorSplitLine: {
                        show: true
                    },
                    data: []
                },
                yAxis: {
                    type: 'value',
                    minInterval: 1,
                    axisLabel: {
                        formatter: '{value}'
                    }
                },
                series: [
                    {
                        type: 'bar',
                        name: '通关玩家数',
                        cursor: 'pointer',
                        data: [],
                        tooltip: {
                            trigger: 'axis',
                        }
                    },
                    {
                        type: 'bar',
                        name: '平均得分',
                        cursor: 'pointer',
                        data: []
                    },
                    {
                        type: 'bar',
                        name: '平均用时',
                        cursor: 'pointer',
                        data: [],
                    },
                ]
            },
            async init() {
                this.chart.showLoading();
                let data = await self.getPlayerGameInputPassInfoStatistic();
                if (!data) data = {data: []};
                this.option.xAxis.data = [];
                this.option.series.map(sere => {
                    sere.data = [];
                    return sere;
                });
                for (let item of data.data) {
                    this.names[item.moduleId] = item.value;
                    this.option.xAxis.data.push(item.moduleId);
                    this.option.series[0].data.push(item.number);
                    this.option.series[1].data.push(Math.round(item.time / 60000));
                    this.option.series[2].data.push(item.score);
                }
                this.chart.hideLoading();
                this.chart.setOption(this.option);
            }
        };
    }

    initTimeRange() {
        this.$scope._.timeRange.end.max = this.getFormatDate(Date.now());
        this.$scope._.timeRange.end.time = new Date((Math.floor(Date.now() / 86400000) - 1) * 86400000);
        this.$scope._.timeRange.begin.time = new Date((Math.floor(Date.now() / 86400000 - 8)) * 86400000);
    }

    async getGameDailyAddPlayerStatistic(start = this.getDefaultDate().start, end = this.getDefaultDate().end) {
        let type = 'GAME_DAILY_ADD_PLAYER'; //游戏每日增长玩家
        return this.ApiService.getGameAnalysis(this.$scope._.gameId, type, start, end);
    }

    async getGameDailyActivePlayerStatistic(start = this.getDefaultDate().start, end = this.getDefaultDate().end) {
        let type = 'GAME_DAILY_ACTIVE_PLAYER'; //游戏每日活跃玩家
        return this.ApiService.getGameAnalysis(this.$scope._.gameId, type, start, end);
    }

    async getPlayerGameInputPassInfoStatistic(start = this.getDefaultDate().start, end = this.getDefaultDate().end) {
        let type = 'PLAYER_GAME_INPUT_PASS_INFO'; //玩家游戏关卡通关信息(各关卡通关玩家数 平均时间 平均分数)
        return await this.ApiService.getGameAnalysis(this.$scope._.gameId, type, start, end);
    }

    async GameDailyOverview(start = this.getDefaultDate().start, end = this.getDefaultDate().end) {
        let type = 'OVERVIEW'; //游戏每日概况
        let date = await this.ApiService.getGameAnalysis(this.$scope._.gameId, type, start, end);
        this.$scope._.overview = {...date}
    }

    async changeWeekOfGameDaily() {
        if (!this.$scope._.timeRange.end.time) this.$scope._.timeRange.end.time = new Date((Math.floor(Date.now() / 86400000) - 1) * 86400000);
        this.$scope._.timeRange.begin.time = new Date(new Date(this.$scope._.timeRange.end.time).getTime() - 86400000 * 7);
        await this.changeTimeOfGameDaily();
    }

    async changeMonthOfGameDaily() {
        if (!this.$scope._.timeRange.end.time) this.$scope._.timeRange.end.time = new Date((Math.floor(Date.now() / 86400000) - 1) * 86400000);
        this.$scope._.timeRange.begin.time = new Date(new Date(this.$scope._.timeRange.end.time).getTime() - 86400000 * 30);
        await this.changeTimeOfGameDaily()
    }

    async changeTimeOfGameDaily() {
        if (!this.$scope._.timeRange.end.time) this.$scope._.timeRange.end.time = new Date((Math.floor(Date.now() / 86400000) - 1) * 86400000);
        if (this.$scope._.tab === "GAME_DAILY_ADD_PLAYER") {
            this.$scope.GameDailyAddPlayer.chart.showLoading();
            let data = await this.getGameDailyAddPlayerStatistic(this.$scope._.timeRange.begin.time, this.$scope._.timeRange.end.time);
            this.$scope.GameDailyAddPlayer.option.xAxis.data = [];
            this.$scope.GameDailyAddPlayer.option.series.data = [];
            for (let item of data) {
                this.$scope.GameDailyAddPlayer.option.xAxis.data.push(item.date_str.substring(5));
                this.$scope.GameDailyAddPlayer.option.series.data.push(item.data);
            }
            this.$scope.GameDailyAddPlayer.chart.hideLoading();
            this.$scope.GameDailyAddPlayer.chart.setOption(this.$scope.GameDailyAddPlayer.option);
        } else {
            this.$scope.GameDailyActivePlayer.chart.showLoading();
            let data = await this.getGameDailyActivePlayerStatistic(this.$scope._.timeRange.begin.time, this.$scope._.timeRange.end.time);
            this.$scope.GameDailyActivePlayer.option.xAxis.data = [];
            this.$scope.GameDailyActivePlayer.option.series.data = [];
            for (let item of data) {
                this.$scope.GameDailyActivePlayer.option.xAxis.data.push(item.date_str.substring(5));
                this.$scope.GameDailyActivePlayer.option.series.data.push(item.data);
            }
            this.$scope.GameDailyActivePlayer.chart.hideLoading();
            this.$scope.GameDailyActivePlayer.chart.setOption(this.$scope.GameDailyActivePlayer.option);
        }
    }

    getDefaultDate(time = new Date()) {
        //最近一周
        let start = new Date(new Date(time.toDateString()) - 604800001);
        let end = new Date(time.toDateString());
        return {start, end};
    }

    getFormatDate(time) {
        time = new Date(time);
        let year = time.getFullYear();
        let month = (time.getMonth() + 1) < 10 ? "0" + (time.getMonth() + 1) : time.getMonth() + 1;
        let day = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
        return year + "-" + month + "-" + day
    }
}

GameDetailAnalysisController.$inject = ['$scope', '$rootScope', '$location', '$window', '$routeParams', '$timeout', 'ApiService', '$event', '$alert', '$modal', '$util'];
app.controller('GameDetailAnalysis', GameDetailAnalysisController);
