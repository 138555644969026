const app = angular.module('editor');
import GameDetailController from './GameDetailController';

class GameDetailEvaluationController extends GameDetailController {
    constructor($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util) {
        super($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util);
        this.$scope.UI = {
            breadcrumb_game_text: {
                cn: '游戏',
                en: 'Games',
            },
            breadcrumb_tab_text: {
                cn: '评价',
                en: 'Evaluation',
            },
        };
        this.$scope._.tabName = 'evaluation';


        this.$scope._.sort = 'date_-1';
        this.$scope._.sortTypes = {
            'date_-1': '最新',
            'score_-1': '评分最高',
            'score_1': '评分最低',
        };

        this.$scope._.carefully_evaluations = [];
        this.$scope._.evaluations = [];
        this.$scope._.size = 0;
        this.$scope._.getTimeInterval = time => this.$util.getTimeInterval(time, new Date());


        this.init().then();
        this.initObject();
    }

    async init() {
        this.$scope._.onPageChange = async page => {
            let [sort, sortType] = this.$scope._.sort.split('_');
            await this.getGameEvaluations(page, sort, sortType);
            this.$scope.$apply();
        };

        this.$scope.$watch('_.sort', async (newVal, oldVal) => {
            if (!oldVal || oldVal === newVal) return;
            let [sort, sortType] = this.$scope._.sort.split('_');
            await this.getGameEvaluations(1, sort, sortType);
            this.$scope.$apply();
        });

        await this.getCarefullyGameEvaluations();
    }

    initObject() {
        let self = this;
        this.$scope.Evaluations = {
            carefully: {
                async set(evaluationId) {
                    try {
                        await self.ApiService.postGameEvaluationCarefully(self.$scope._.gameId, evaluationId);
                        await self.getCarefullyGameEvaluations();
                        await self.getGameEvaluations();
                        self.$scope.$apply();
                    } catch (e) {
                        self.$alert.error(e.data && e.data.status && e.data.status.message);
                    }

                },
                async unset(evaluationId) {
                    try {
                        await self.ApiService.deleteGameEvaluationCarefully(self.$scope._.gameId, evaluationId);
                        await self.getCarefullyGameEvaluations();
                        await self.getGameEvaluations();
                        self.$scope.$apply();
                    } catch (e) {
                        self.$alert.error(e.data && e.data.status && e.data.status.message);
                    }
                }
            }
        };
    }

    async getCarefullyGameEvaluations() {
        this.$scope._.carefully_evaluations = await this.ApiService.getCarefullyGameEvaluations(this.$scope._.gameId);
    }

    async getGameEvaluations(page, sort, sortType) {
        let result = await this.ApiService.getGameEvaluations(this.$scope._.gameId, page, sort, sortType);
        this.$scope._.evaluations = result.data;
        this.$scope._.size = result.total;
    }

}

GameDetailEvaluationController.$inject = ['$scope', '$rootScope', '$location', '$window', '$routeParams', '$timeout', 'ApiService', '$event', '$alert', '$modal', '$util'];
app.controller('GameDetailEvaluation', GameDetailEvaluationController);
