const app = angular.module('editor');

class AccountSettingPreferenceController {
    constructor($scope, $rootScope, $event, $alert, $storage, ApiService) {
        console.log('on AccountSettingPreferenceController');
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$event = $event;
        this.$alert = $alert;
        this.$storage = $storage;
        this.ApiService = ApiService;
        this.$scope.UI = {};

        if ($rootScope.__.CheckLogin('/account/setting/preference')) this.init();
        else $event.on('login', 'AccountSettingPreferenceController.login', () => this.init());
    }

    init() {
        this.$scope._ = {
            editorUser: {
                others: {
                    preference: {
                        language: this.$rootScope.__.ME.others && this.$rootScope.__.ME.others.preference && this.$rootScope.__.ME.others.preference.language || 'zh-cn',
                    }
                }
            },
            tab: 'preference',
            submit: async () => {
                try {
                    let language = this.$scope._.editorUser.others.preference.language;
                    await this.ApiService.postEditorUserPreference({language});
                    if (!this.$rootScope.__.ME.others) this.$rootScope.__.ME.others = {};
                    if (!this.$rootScope.__.ME.others.preference) this.$rootScope.__.ME.others.preference = {};
                    this.$rootScope.__.ME.others.preference.language = language;
                    this.$storage.setObject('__ME', this.$rootScope.__.ME);
                    this.$alert.success('修改成功');
                } catch (error) {
                    console.error(error);
                    this.$alert.error(error.data && error.data.status && error.data.status.message);
                }
            }
        };
    }
}

AccountSettingPreferenceController.$inject = ['$scope', '$rootScope', '$event', '$alert', '$storage', 'ApiService'];
app.controller('AccountSettingPreference', AccountSettingPreferenceController);

