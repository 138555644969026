const app = angular.module('editor');
import GameDetailController from './GameDetailController';

class GameDetailPluginController extends GameDetailController {
    constructor($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util, $progress) {
        super($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util);

        this.$scope.UI = {
            breadcrumb_game_text: {
                cn: '游戏',
                en: 'Games',
            },
            breadcrumb_tab_text: {
                cn: '插件',
                en: 'Plugin',
            },
        };
        this.$scope._.tabName = 'plugin';
        this.$scope._.envDisabled = true;

        this.init().then();
    }

    async init() {
        this.initObject();
        await this.$scope._.Plugin.init();
        if (this.$routeParams.action) {
            let plugin = this.$scope._.Plugin.list.find(p => p.pluginId === this.$routeParams.action)
            if (plugin) this.$scope._.Plugin.select(plugin);
        }
        this.$scope.onGameDataLoadSuccess = () => {
            this.$scope.CDKMake.unlockId = this.$rootScope.__.GAMES[this.$scope._.gameId].dev.unlockId;
            this.$scope.CommentPreventSpoiler.init();
            this.$scope.GameSaleData.init(this.$rootScope.__.GAMES[this.$scope._.gameId].dev);
        };
    }

    initObject() {
        let self = this;

        this.$scope._.showType = 'list'; // list detail
        this.$scope._.selectPlugin = null;

        this.$scope._.Plugin = {
            list: [],
            async init() {
                let usePluginGames = await self.ApiService.getEditorGamePlugins(self.$scope._.gameId);
                for (let usePluginGame of usePluginGames) {
                    let {name, icon, info} = usePluginGame;
                    this.list.push({
                        pluginId: usePluginGame.pluginId,
                        name: name,
                        icon: icon,
                        info: info,
                    });
                }
            },
            select(plugin) {
                self.$scope._.showType = 'detail';
                self.$scope._.selectPlugin = plugin;
            },
            open(pluginId) {
                self.$location.path(`/game/detail/${self.$scope._.gameId}/plugin`).search({action: pluginId});
            },
            backList() {
                self.$scope._.showType = 'list';
                self.$scope._.selectPlugin = null;
                self.$location.path(`/game/detail/${self.$scope._.gameId}/plugin`).search({});
            }
        };

        this.$scope.CDKMake = {
            unlockId: null,
            number: null,
            max: 0,
            counter: 0,
            histories: [],
            async init() {
            },
            async make() {
            },
            async download() {
            },
        }

        this.$scope.CDKFind = {
            scanType: 'qrcode', // qrcode NO
            code: {},
            players: {},
            qrcodeurl: '',
            NO: '',
            qrcode_content: '',
            qrcode_status: '',
            errmessage: '',

            init() {
                this.code = {};
                this.players = {};
                this.qrcodeurl = '';
                this.NO = '';
                this.qrcode_content = '';
                this.qrcode_status = '';
                this.errmessage = '';
            },

            async find() {
                await this.getCodeInfo({NO: this.NO});
                self.$scope.$apply();
            },

            async getCodeInfo({NO, content}) {
                try {
                    let code = await self.ApiService.getCDK(self.$scope._.gameId, {NO, content});
                    this.code = code;
                    if (code.userIds) {
                        for (let userId in code.userIds) {
                            let player = await self.ApiService.getPlayerInfo({userId});
                            if (!player) player = {userId};
                            player.useTime = code.userIds[userId];
                            this.players[userId] = player;
                        }
                    }
                } catch (e) {
                    this.errmessage = e.data.status.message;
                }
            },
            async deletePlayer(playerId) {
                if (!this.players[playerId] && !this.players[playerId].uid) return alert("该用户可能已注销, 或者为国际服用户,目前无法删除!");
                let msg = "是否删除此用户的游戏权限？";
                if (confirm(msg)) {
                    let NO = this.code.NO;
                    await self.ApiService.deleteUserGamePermission(playerId, this.code.cdk);
                    this.init();
                    await this.getCodeInfo({NO});
                }
            }
        }

        this.$scope.CommentPreventSpoiler = {

            array: [],
            status: {},
            _selectOldValue: '',
            _selectNewValue: '',
            _showAddInput: false,
            _addValue: '',

            async init() {
                try {
                    let array = await self.ApiService.getGameSensitiveAnswer(self.$scope._.gameId);
                    self.$scope.$apply(() => {
                        this.array = array;
                    });
                } catch (e) {
                    console.error(e);
                    self.$alert.error(e.data);
                }

            },

            async edit(index) {
                this.status[index] = 'edit';
                this._selectOldValue = this.array[index];
                this._selectNewValue = this.array[index];
            },

            async cancel(index) {
                delete this.status[index];
                this.array[index] = this._selectOldValue;
                this._selectOldValue = '';
                this._selectNewValue = '';
            },

            async showAddInput() {
                console.log('_showAddInput', this._showAddInput)
                this._showAddInput = true;
            },

            async add() {
                let index = this.array.length;
                let status = await self.ApiService.putGameSensitiveAnswer(self.$scope._.gameId, index, this._addValue);
                // console.log(status)
                self.$scope.$apply(() => {
                    this.array[index] = this._addValue;
                    this._showAddInput = false;
                    this._addValue = '';
                });
            },

            async cancelAdd() {
                this._showAddInput = false;
                this._addValue = '';
            },

            async update(index) {
                delete this.status[index];
                try {
                    let status = await self.ApiService.putGameSensitiveAnswer(self.$scope._.gameId, index, this._selectNewValue);
                    self.$scope.$apply(() => {
                        this.array[index] = this._selectNewValue;
                    });
                    // console.log(status)
                } catch (e) {
                    this.errmessage = e.data.status.message;
                }
            },

            async delete(index) {
                delete this.status[index];
                try {
                    let status = await self.ApiService.deleteGameSensitiveAnswer(self.$scope._.gameId, index);
                    self.$scope.$apply(() => {
                        this.array.splice(index, 1);
                    });
                } catch (e) {
                    this.errmessage = e.data.status.message;
                }
            },
        }

        this.$scope.GameSaleData = {
            saleVolume: 0,
            saleAmount: 0,
            async init(game) {
                this.saleVolume = game.saleVolume || 0;
                this.saleAmount = game.saleAmount || 0;
            },
            async submit() {
                try {
                    await self.ApiService.putAuthorGameSaleData(self.$scope._.gameId, this.saleVolume, this.saleAmount);
                } catch (e) {
                    console.error(e);
                    self.$alert.error(e.data);
                }
            },
        }

        this.$scope.$watch('CDKFind.scanType', () => this.$scope.CDKFind.init());

        this.$scope.$watch('CDKFind.files', async files => {
            this.$scope.CDKFind.init();
            if (files) {
                this.$scope.CDKFind.qrcodeurl = URL.createObjectURL(files[0]);
                qrcode.decode(this.$scope.CDKFind.qrcodeurl);
                qrcode.callback = async content => {
                    if (content === 'error decoding QR Code') {
                        this.$scope.CDKFind.qrcode_status = 'fail';
                    } else {
                        console.log(content)
                        this.$scope.CDKFind.qrcode_status = 'success';
                        await this.$scope.CDKFind.getCodeInfo({content});
                    }

                    this.$scope.$apply(() => {
                        this.$scope.CDKFind.qrcode_content = content;
                    });
                }
            }
        });

    }
}

GameDetailPluginController.$inject = ['$scope', '$rootScope', '$location', '$window', '$routeParams', '$timeout', 'ApiService', '$event', '$alert', '$modal', '$util', '$progress'];
app.controller('GameDetailPlugin', GameDetailPluginController);