const app = angular.module('editor');

export default class GameDetailController {
    constructor($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util, $user) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.$window = $window;
        this.$routeParams = $routeParams;
        this.$timeout = $timeout;
        this.ApiService = ApiService;
        this.$event = $event;
        this.$alert = $alert;
        this.$modal = $modal;
        this.$util = $util;
        this.$user = $user;
        this.$scope.UI = {
            breadcrumb_game_text: {
                cn: '游戏',
                en: 'Games',
            },
            breadcrumb_tab_text: {
                cn: '详情',
                en: 'Detail',
            },
        };
        $rootScope.PageNow = 'game_detail';
        this.$scope._ = {
            env: 'dev',
            gameId: this.$routeParams.gameId,
            envs: {dev: '开发版本'},
            envDisabled: false,
            tabName: 'detail',
            _histories: {},
            modal: {
                title: '更新说明',
                message: '',
                submit: () => {
                }
            },
            getTimeInterval: time => this.$util.getTimeInterval(time, new Date()),
            releaseTestGame: () => {
                this.$scope._.modal.submit = async () => {
                    try {
                        let test_game = await this.ApiService.releaseDevGameToTest(this.$scope._.gameId, this.$scope._.modal.message);
                        // if (!this.$rootScope.__.GAMES[this.$scope._.gameId]) this.$rootScope.__.GAMES[this.$scope._.gameId] = {};
                        // this.$rootScope.__.GAMES[this.$scope._.gameId].test = test_game;
                        // this.$scope._.envs.test = '测试版本';
                        // this.$scope._.env = 'test';
                        $('#modal').modal('hide');
                        this.$scope._.modal.submit = () => {
                        };
                        this.$scope._._histories.test = await this.ApiService.getTestGameHistory(this.$scope._.gameId, 1);
                    } catch (error) {
                        console.error(error);
                        this.$alert.error(error.data && error.data.status && error.data.status.message);
                        $('#modal').modal('hide');
                        this.$scope._.modal.submit = () => {
                        };
                    }
                };
                $('#modal').modal('show');
            },
            toEditGamePage: () => {
                let gameEditMode = 'graphical';
                if (this.$rootScope.__.GAMES[this.$scope._.gameId].dev) gameEditMode = this.$rootScope.__.GAMES[this.$scope._.gameId].dev.others.gameEditMode;
                let tabName = gameEditMode;
                if (!['graphical', 'json', 'fast', 'preview'].includes(tabName)) tabName = 'graphical';
                this.$rootScope.__.Path(`/game/edit/${this.$scope._.gameId}/${tabName}`);
            },
            auditProGame: () => {
                this.$scope._.modal.submit = async () => {
                    try {
                        await this.ApiService.auditTestGameToPro(this.$scope._.gameId, this.$scope._.modal.message);
                        this.$alert.info('已提交');
                        this.$rootScope.__.GAMES[this.$scope._.gameId].version.test.auditStatus = 1;
                        this.$rootScope.$apply();
                        $('#modal').modal('hide');
                        this.$scope._.modal.submit = () => {
                        }
                    } catch (error) {
                        console.error(error);
                        this.$alert.error(error.data && error.data.status && error.data.status.message);
                        $('#modal').modal('hide');
                        this.$scope._.modal.submit = () => {
                        }
                    }
                };
                $('#modal').modal('show');
            },
            cancelAuditProGame: () => {
                this.$modal('取消审核', '确认取消审核?');
                this.$modal.on_ok = async () => {
                    try {
                        await this.ApiService.cancelAuditTestGameToPro(this.$scope._.gameId);
                        this.$alert.info('已取消');
                        this.$rootScope.__.GAMES[this.$scope._.gameId].version.test.auditStatus = 0;
                        this.$rootScope.$apply();
                        this.$modal.hide();
                    } catch (error) {
                        console.error(error);
                        this.$alert.error(error.data && error.data.status && error.data.status.message);
                        this.$modal.hide();
                    }
                }
            },
        };

        if ($rootScope.__.CheckLogin('/game/detail/' + $scope._.gameId)) this.initGameDetail();
        else $event.on('login', 'GameDetailDynamicController.login', () => this.initGameDetail());
    }

    initGameDetail() {
        this.initGameVersion().then();
        this.initGameHistories().then();
        this.loadGameSynopsis().then(() => this.$scope.onGameDataLoadSuccess && this.$scope.onGameDataLoadSuccess());

        this.$event.on('ws.game_release_test', 'GameDetailController.ws.game_release_test', async data => {
            console.log(data.content);
            this.initGameVersion().then();
            this.$scope._._histories.test = await this.ApiService.getTestGameHistory(this.$scope._.gameId, 1);
        });
        this.$event.on('ws.game_release_pro', 'GameDetailController.ws.game_release_pro', async data => {
            console.log(data.content);
            this.$scope._._histories.pro = await this.ApiService.getProGameHistory(this.$scope._.gameId, 1);
        });
        this.$event.on('ws.game_audit_cancel', 'GameDetailController.ws.game_audit_cancel', data => {
            this.initGameVersion().then();
            console.log(data.content);
        });
        this.$event.on('ws.game_audit_start', 'GameDetailController.ws.game_audit_start', data => {
            this.initGameVersion().then();
            console.log(data.content);
        });
        this.$event.on('ws.game_audit_submit', 'GameDetailController.ws.game_audit_submit', data => {
            this.initGameVersion().then();
            console.log(data.content);
        });
        this.$event.on('ws.game_audit_result', 'GameDetailController.ws.game_audit_result', async data => {
            this.$alert(data.content);
            this.initGameHistories().then();
            this.loadGameSynopsis().then();
            this.initGameVersion().then();
        });

        this.$scope.$on('$locationChangeStart', event => {
            this.$event.cancel('ws.game_release_test', 'GameDetailController.ws.game_release_test');
            this.$event.cancel('ws.game_release_pro', 'GameDetailController.ws.game_release_pro');
            this.$event.cancel('ws.game_audit_cancel', 'GameDetailController.ws.game_audit_cancel');
            this.$event.cancel('ws.game_audit_start', 'GameDetailController.ws.game_audit_start');
            this.$event.cancel('ws.game_audit_submit', 'GameDetailController.ws.game_audit_submit');
            this.$event.cancel('ws.game_audit_result', 'GameDetailController.ws.game_audit_result');
        });
    }

    async initGameHistories() {
        this.$scope._._histories = {
            dev: await this.ApiService.getEditorGameHistory(this.$scope._.gameId, 1),
            test: await this.ApiService.getTestGameHistory(this.$scope._.gameId, 1),
            pro: await this.ApiService.getProGameHistory(this.$scope._.gameId, 1),
        };
        this.$rootScope.$apply();
    }

    async initGameVersion() {
        try {
            let version = await this.ApiService.getGameVersion(this.$scope._.gameId);
            if (!this.$rootScope.__.GAMES[this.$scope._.gameId]) this.$rootScope.__.GAMES[this.$scope._.gameId] = {};
            this.$rootScope.__.GAMES[this.$scope._.gameId].version = version;
            this.$rootScope.$apply();
        } catch (e) {
            console.error(e);
        }
    }

    async loadGameSynopsis() {
        try {
            let devGame = await this.ApiService.getEditorGameSynopsis(this.$scope._.gameId);
            let releaseDate = new Date(devGame.releaseDate);
            releaseDate.setSeconds(0);
            releaseDate.setMilliseconds(0);
            devGame.releaseDate = releaseDate;
            if (devGame) {
                if (!this.$rootScope.__.GAMES[this.$scope._.gameId]) this.$rootScope.__.GAMES[this.$scope._.gameId] = {};
                this.$rootScope.__.GAMES[this.$scope._.gameId].dev = devGame;
                if (!!this.$rootScope.__.GAMES[this.$scope._.gameId].dev) this.$scope._.envs.dev = '开发版本';
            }
        } catch (error) {
            console.error(error);
            this.$alert.error(error.data && error.data.status && error.data.status.message);
        }
        this.$rootScope.$apply();
    }
}

GameDetailController.$inject = ['$scope', '$rootScope', '$location', '$window', '$routeParams', '$timeout', 'ApiService', '$event', '$alert', '$modal', '$util', '$user'];
app.controller('GameDetail', GameDetailController);