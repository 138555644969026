const app = angular.module('editor');

class NewMultiplayerGameController {
    constructor($scope, $rootScope, $location, $routeParams, $alert, $event, ApiService) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.$routeParams = $routeParams;
        this.$alert = $alert;
        this.$event = $event;
        this.ApiService = ApiService;
        $scope.UI = {
            breadcrumb_game_text: {
                cn: '游戏',
                en: 'Games',
            },
        };
        if ($rootScope.__.CheckLogin('/game/multiplayer/new')) this.init();
        else $event.on('login', 'NewMultiplayerGameController.login', () => this.init());
    }

    init() {
        let self = this;
        this.$scope._ = {
            submit: () => {
                if (!this.check(this.$scope.game)) return;
                if (this.$scope.game.others.category === 'novel') {
                    this.$scope.game.others.templatesGameType = 'Novel';
                } else if (this.$scope.game.others.category === 'penetralium') {
                    this.$scope.game.others.templatesGameType = 'AVG';
                } else if (this.$scope.game.others.category === 'script') {
                    this.$scope.game.others.templatesGameType = 'AVG';
                }
                this.ApiService.newGame(this.$scope.game)
                    .then(game => {
                        // console.log(game)
                        this.$rootScope.__.GAMES[game.gameId] = {
                            role: 'creator',
                            status: {dev: 0},
                            gameId: game.gameId
                        };
                        this.$location.path('/game/detail/' + game.gameId);
                    })
                    .catch(error => {
                        if (error.data.status.code === 586) {
                            this.$alert.error('游戏识别代号已被占用!');
                            return;
                        }
                        this.$alert.error(error.data && error.data.status && error.data.status.message);
                    });
            },
            onFunctionChooseClick: (t1, t2) => {
                this.$scope.game.data.config[t1][t2] = !this.$scope.game.data.config[t1][t2];
            }
        };
        this.$scope.Theme = {
            list: ['现代', '悬疑', '奇幻', '仙侠', '历史', '校园', '童话', '推理', '科幻', '古风', '其他'],
            choose: [],
            showSelect: false,
            isHasTheme(themes) {
                return self.$scope.game.others.themes.includes(themes)
            },
            onchange() {
                if (self.$scope.game.others.themes.length + this.choose.length > 3) {
                    self.$alert.error('题材选择数量不能超过3个');
                } else {
                    self.$scope.game.others.themes = self.$scope.game.others.themes.concat(this.choose);
                }
                this.showSelect = false;
                this.choose = [];
            }
        }
        this.$scope.game = {
            gameName: '',
            subtitle: '',
            desc: '',
            gameType: 'template',
            data: {
                config: {
                    DATABASE: {
                        NEWS: false,
                        DOC: false,
                        HISTORY: false
                    },
                    MY_PROFILE: {
                        PROFILE: false,
                        ACHIEVEMENT: false,
                        ATTRIBUTE: false,
                        RANK: false,
                        BAG: false,
                        TASK: false
                    }
                }
            },
            others: {
                category: 'novel',
                hard: '简单',
                themes: [],
                charge: 0,
                scancode: false,
                entityPackage: 0,
                taobaoAddress: '',
                taobaoZhongchouLink: '',
                modianZhongchouLink: '',
                isMultiGame: true,
                templatesGameType: 'Novel', // Talk Novel AVG
                templatesGameStyle: 'Simple', // Classic Scifi Simple
                gameEditMode: 'graphical', // graphical fast
            }
        };
    }

    check(game) {
        if (!game.gameName) {
            this.$alert.warn('请填写游戏名称');
            return false;
        }
        if (!game.gameType) {
            this.$alert.warn('请选择游戏类型');
            return false;
        }
        return true;
    }
}

NewMultiplayerGameController.$inject = ['$scope', '$rootScope', '$location', '$routeParams', '$alert', '$event', 'ApiService'];
app.controller('NewMultiplayerGameController', NewMultiplayerGameController);
