const app = angular.module('editor');

class AccountSettingContactController {
    constructor($scope, $rootScope, $routeParams, $location, $event, $alert, $storage, ApiService) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$routeParams = $routeParams;
        this.$location = $location;
        this.$event = $event;
        this.$alert = $alert;
        this.$storage = $storage;
        this.ApiService = ApiService;
        this.$scope.UI = {};

        if ($rootScope.__.CheckLogin('/account/setting/contact')) this.init();
        else $event.on('login', 'AccountSettingContactController.login', () => this.init());
    }

    init() {
        this.$scope.email = this.$rootScope.__.ME.email;
        this.$scope.haveEmail = !!this.$rootScope.__.ME.email;

        this.$scope.phone = this.$rootScope.__.ME.phone;
        this.$scope.havePhone = !!this.$rootScope.__.ME.phone;

        if (this.$scope.havePhone && this.$scope.haveEmail) {
            this.$location.path(this.$routeParams.redirect || '/game/list');
        }

        this.$scope.submit = async () => {
            if (!this.$scope.email || !this.$scope.phone) {
                this.$alert.error('联系方式必须填写!');
                return;
            }
            if (this.$scope.havePhone && this.$scope.haveEmail) {
                this.$location.path(this.$routeParams.redirect || '/game/list');
                return;
            }
            let params = {};
            if (!this.$scope.haveEmail) {
                params.email = this.$scope.email;
            }
            if (!this.$scope.havePhone) {
                params.phone = this.$scope.phone;
            }
            try {
                await this.ApiService.postAuthorAccountContact(params);
                this.$rootScope.__.ME.email = this.$scope.email;
                this.$rootScope.__.ME.phone = this.$scope.phone;
                this.$storage.setObject('__ME', this.$rootScope.__.ME);
                this.$alert.success('保存成功');
                this.init();
            } catch (error) {
                console.error(error);
                this.$alert.error(error.data && error.data.status && error.data.status.message);
            }
        }
    }
}

AccountSettingContactController.$inject = ['$scope', '$rootScope', '$routeParams', '$location', '$event', '$alert', '$storage', 'ApiService'];
app.controller('AccountSettingContact', AccountSettingContactController);

