const app = angular.module('editor');
import GameDetailController from './GameDetailController';

class GameDetailDynamicController extends GameDetailController {
    constructor($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util) {
        super($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util);
        this.$scope.UI = {
            breadcrumb_game_text: {
                cn: '游戏',
                en: 'Games',
            },
            breadcrumb_tab_text: {
                cn: '动态',
                en: 'Dynamic',
            },
        };
        this.init().then();
    }

    async init() {
        this.$scope._.tabName = 'dynamic';
        this.$scope._.envDisabled = true;
        this.$scope._.page = {dev: 1, test: 1, pro: 1};
        this.$scope._.loaded = {dev: false, test: false, pro: false};
        this.$scope._.histories = {dev: [], test: {}, pro: []};
        this.$scope._.historyGame = {};
        this.$scope._.historyGames = {};
        this.$scope._.gameUpdateLogs = {};
        this.$scope._.getNextPageHistory = async () => {
            this.$scope._.page[this.$scope._.env]++;
            await this.getEditorGameHistory(this.$scope._.page[this.$scope._.env]);
            await this.getTestGameHistory(this.$scope._.page[this.$scope._.env]);
            await this.getProGameHistory(this.$scope._.page[this.$scope._.env]);
        };
        this.$scope._.history_modal = {
            show: async version => {
                if (this.$scope._.historyGames[version]) {
                    this.$scope._.historyGame = this.$scope._.historyGames[version];
                    $('#dynamic_history_modal').modal('show');
                    this.$timeout(() => this.$scope.$apply());
                    return;
                }
                try {
                    this.$scope._.historyGames[version] = await this.ApiService.getEditorGameHistoryDetail(this.$scope._.gameId, version);
                    this.$scope._.historyGame = this.$scope._.historyGames[version];
                    $('#dynamic_history_modal').modal('show');
                    this.$timeout(() => this.$scope.$apply());
                } catch (error) {
                    console.error(error);
                    this.$alert.error(error.data && error.data.status && error.data.status.message);
                }
            }
        };
        this.$scope._.update_log_modal = {
            show: async version => {
                this.$scope._.update_log_modal.version = version;
                if (this.$scope._.gameUpdateLogs[version]) {
                    $('#dynamic_update_log_modal').modal('show');
                    this.$timeout(() => this.$scope.$apply());
                    return;
                }
                try {
                    this.$scope._.gameUpdateLogs[version] = await this.ApiService.getEditorGameUpdateLogDetail(this.$scope._.gameId, version);
                    $('#dynamic_update_log_modal').modal('show');
                    this.$timeout(() => this.$scope.$apply());
                } catch (error) {
                    console.error(error);
                    this.$alert.error(error.data && error.data.status && error.data.status.message);
                }
            }
        };

        await this.getEditorGameHistory(this.$scope._.page.dev);
        await this.getTestGameHistory(this.$scope._.page.test);
        await this.getProGameHistory(this.$scope._.page.pro);
        this.$timeout(() => this.$scope.$apply());
    }

    async getEditorGameHistory(page) {
        try {
            let histories = await this.ApiService.getEditorGameHistory(this.$scope._.gameId, page);
            if (histories.length < 20) this.$scope._.loaded.dev = true;
            this.$scope._.histories.dev = this.$scope._.histories.dev.concat(histories);
        } catch (error) {
            console.error(error);
            this.$alert.error(error.data && error.data.status && error.data.status.message);
        }
    }

    async getTestGameHistory(page) {
        try {
            if (this.$scope._.loaded.test === true) return;
            let histories = await this.ApiService.getTestGameHistory(this.$scope._.gameId, page);
            if (histories.length < 20) this.$scope._.loaded.test = true;
            for (let history of histories) {
                this.$scope._.histories.test[history.version] = history;
            }
        } catch (error) {
            console.error(error);
            this.$alert.error(error.data && error.data.status && error.data.status.message);
        }
    }

    async getProGameHistory(page) {
        try {
            if (this.$scope._.loaded.pro === true) return;
            let histories = await this.ApiService.getProGameHistory(this.$scope._.gameId, page);
            if (histories.length < 20) this.$scope._.loaded.pro = true;
            for (let history of histories) {
                this.$scope._.histories.pro[history.version] = history;
            }
        } catch (error) {
            console.error(error);
            this.$alert.error(error.data && error.data.status && error.data.status.message);
        }
    }

}


GameDetailDynamicController.$inject = ['$scope', '$rootScope', '$location', '$window', '$routeParams', '$timeout', 'ApiService', '$event', '$alert', '$modal', '$util', '$progress'];
app.controller('GameDetailDynamic', GameDetailDynamicController);