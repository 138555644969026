const app = angular.module('editor');

class CommunityTeamSettingController {
    constructor($scope, $rootScope, $event, $alert, $storage, ApiService) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$event = $event;
        this.$alert = $alert;
        this.$storage = $storage;
        this.ApiService = ApiService;
        this.$scope.UI = {};

        if ($rootScope.__.CheckLogin('/community/team/setting')) this.init();
        else $event.on('login', 'CommunityTeamSettingController.login', () => this.init());
    }

    async init() {
        this.$scope.declaration = '';
        this.$scope.submit = async () => {
            if (this.$scope.declaration) {
                await this.ApiService.putAuthorCommunityTeamDeclaration(this.$scope.declaration);
                this.$alert.success('修改成功');
            }
        };
        let declaration = await this.ApiService.getAuthorCommunityTeamDeclaration();
        if (declaration) this.$scope.declaration = declaration;
        this.$scope.$apply();
    }
}

CommunityTeamSettingController.$inject = ['$scope', '$rootScope', '$event', '$alert', '$storage', 'ApiService'];
app.controller('CommunityTeamSetting', CommunityTeamSettingController);

