const app = angular.module('editor');

class HomeController {
    constructor($scope, $window, $timeout, $alert) {
        $scope.UI = {};
        $scope._ = {
            open: url => url && $window.open(url, '_blank')
        };
        $scope.type_select = 'talk';
        $scope.game_select = 'marvel';
        $scope.showData = () => {
            $('#home_modal').modal('show')
        };

        let images = [
            'https://resource.itaotuo.com/puzzle/editor/images/%E5%AF%B9%E8%AF%9D%E4%BD%93icon%202%281%29.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E6%96%87%E6%AE%B5icon%281%29.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E8%B0%9C%E9%A2%98icon%281%29.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E8%A7%86%E9%A2%91icon%281%29.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E9%9F%B3%E9%A2%91icon%281%29.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E5%9B%BE%E7%89%87icon.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E5%AF%B9%E8%AF%9D.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E5%AD%97%E6%AE%B5.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E8%B0%9C%E9%A2%98.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E8%A7%86%E9%A2%91.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E9%9F%B3%E9%A2%91.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E5%9B%BE%E7%89%87.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E6%BC%AB%E5%A8%81.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E9%87%91%E6%A6%9C%E9%A2%98%E5%90%8D.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E4%BE%A6%E6%8E%A2%E7%AC%94%E8%AE%B0.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E5%B0%91%E5%A5%B3%E8%AF%B4%E6%98%8E%E4%B9%A6.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E5%A6%82%E6%84%8F%E7%90%B3%E7%90%85.png',
            'https://resource.itaotuo.com/puzzle/editor/images/%E5%BC%95%E5%AF%BC%E8%80%85.png',
            'https://resource.itaotuo.com/puzzle/editor/images/text_marvel.png',
            'https://resource.itaotuo.com/puzzle/editor/images/text_gugong2.png',
            'https://resource.itaotuo.com/puzzle/editor/images/text_chinatown.png',
            'https://resource.itaotuo.com/puzzle/editor/images/text_shaonvshuomingshu.png',
            'https://resource.itaotuo.com/puzzle/editor/images/text_gugong.png',
            'https://resource.itaotuo.com/puzzle/editor/images/text_yindaozhe.png',
            'https://resource.itaotuo.com/puzzle/editor/images/marvel.png',
            'https://resource.itaotuo.com/puzzle/editor/images/gugong2.png',
            'https://resource.itaotuo.com/puzzle/editor/images/gugong.png',
            'https://resource.itaotuo.com/puzzle/editor/images/chinatown.png',
            'https://resource.itaotuo.com/puzzle/editor/images/shaonvshuomingshu.png',
            'https://resource.itaotuo.com/puzzle/editor/images/yindaozhe.png',
            'https://resource.itaotuo.com/puzzle/editor/images/button.png',
            'https://resource.itaotuo.com/puzzle/editor/images/button1-select.png',
            'https://resource.itaotuo.com/puzzle/editor/images/button2.png',
            'https://resource.itaotuo.com/puzzle/editor/images/button2-select.png',
        ];
        images.forEach(url => {
            let img = new Image();
            img.src = url;
        })
    }

}

HomeController.$inject = ['$scope', '$window', '$timeout'];
app.controller('HomeController', HomeController);