const app = angular.module('editor');

class DashboardController {
    constructor($scope, $rootScope, $event, $util, ApiService) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$event = $event;
        this.$util = $util;
        this.ApiService = ApiService;

        $scope.UI = {
            nav_brand_text: {
                cn: '谜案馆-作者平台'
            },
            nav_dashboard_text: {
                cn: '仪表盘',
                en: 'Dashboard',
            },
        };
        $scope._ = {
            gameNumber: 0,
            lately: [],
            getTimeInterval: time => $util.getTimeInterval(time, new Date())
        };

        if ($rootScope.__.CheckLogin('/game/list')) this.init();
        else $event.on('login', 'DashboardController.login', () => this.init());
    }

    init() {
        this.getGamesSynopsis().then()
    }

    async getGamesSynopsis() {
        let devGames = await this.ApiService.getEditorGamesSynopsis();
        let testGames = await this.ApiService.getTestGamesSynopsis();
        let proGames = await this.ApiService.getProGamesSynopsis();

        for (let game of devGames) {
            if (!this.$rootScope.__.GAMES[game.gameId]) throw Error('异常游戏ID: ' + game.gameId);
            this.$rootScope.__.GAMES[game.gameId].dev = game;
        }
        for (let game of testGames) {
            if (!this.$rootScope.__.GAMES[game.gameId]) throw Error('异常游戏ID: ' + game.gameId);
            this.$rootScope.__.GAMES[game.gameId].test = game;
        }
        for (let game of proGames) {
            if (!this.$rootScope.__.GAMES[game.gameId]) throw Error('异常游戏ID: ' + game.gameId);
            this.$rootScope.__.GAMES[game.gameId].pro = game;
        }

        let lately = [];
        this.$scope._.gameNumber = 0;
        for (let gameId in this.$rootScope.__.GAMES) {
            if (!this.$rootScope.__.GAMES.hasOwnProperty(gameId)) continue;
            let g = this.$rootScope.__.GAMES[gameId];
            if (!!g.dev) {
                this.$scope._.gameNumber++;
                lately.push({gameId: gameId, updateDate: g.dev.others.updateDate})
            }
        }
        this.$scope._.lately = lately.sort((a, b) => new Date(b.updateDate) - new Date(a.updateDate)).slice(0, 4);
        this.$rootScope.$apply();
    }
}

DashboardController.$inject = ['$scope', '$rootScope', '$event', '$util', 'ApiService'];
app.controller('DashboardController', DashboardController);
