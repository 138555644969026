const app = angular.module('editor');
import GameDetailController from './GameDetailController';

class GameDetailSettingsController extends GameDetailController {
    constructor($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util, $progress) {
        super($scope, $rootScope, $location, $window, $routeParams, $timeout, ApiService, $event, $alert, $modal, $util);

        this.$progress = $progress;

        this.$scope.UI = {
            breadcrumb_game_text: {
                cn: '游戏',
                en: 'Games',
            },
            breadcrumb_tab_text: {
                cn: '管理',
                en: 'Settings',
            },
        };

        this.$scope.onGameDataLoadSuccess = () => {
            this.init().then();
        };
        $window.document.onChangeFile = this.onChangeFile.bind(this);
    }

    async init() {
        let self = this;
        this.$scope._.tabName = 'settings';
        this.$scope._.envDisabled = true;
        this.$scope._.tab = 'game';
        this.$scope._.game = {
            saving: false,
            author: null,
            authorFind: {
                uid: '',
                info: '',
                show: false,
                user: {},
                find() {
                    this.show = false;
                    this.user = {headimgurl: 'https://resource.itaotuo.com/puzzle/white.jpg'};
                    if (!this.uid) return;
                    this.info = '';
                    self.ApiService.getAuthorAccountByUID(this.uid)
                        .then(user => {
                            this.show = true;
                            this.user = user;
                        })
                        .catch(error => {
                            this.show = true;
                            if (error.status === 550) {
                                this.info = '未查找到作者';
                            } else {
                                console.error(error);
                                self.$alert.error(error.data && error.data.status && error.data.status.message);
                            }
                        })
                },
                async select(user) {
                    this.show = false;
                    if (self.$rootScope.__.GAMES[self.$scope._.gameId][self.$scope._.env].releaseId !== user.userId) {
                        self.$rootScope.__.GAMES[self.$scope._.gameId][self.$scope._.env].releaseId = user.userId;
                        self.$scope._.game.author = await self.ApiService.getAuthorAccountByUserId(user.userId);
                        self.$timeout(() => self.$scope.$apply());
                    }
                }
            },
            Theme: {
                list: ['现代', '悬疑', '奇幻', '仙侠', '历史', '校园', '童话', '推理', '科幻', '古风', '其他'],
                choose: [],
                showSelect: false,
                isHasTheme(themes) {
                    return self.$rootScope.__.GAMES[self.$scope._.gameId][self.$scope._.env].others.themes.includes(themes)
                },
                onchange() {
                    if (self.$rootScope.__.GAMES[self.$scope._.gameId][self.$scope._.env].others.themes.length + this.choose.length > 3) {
                        self.$alert.error('题材选择数量不能超过3个');
                    } else {
                        self.$rootScope.__.GAMES[self.$scope._.gameId][self.$scope._.env].others.themes = self.$rootScope.__.GAMES[self.$scope._.gameId][self.$scope._.env].others.themes.concat(this.choose);
                    }
                    this.showSelect = false;
                    this.choose = [];
                }
            },
            addImages: () => {
                let len = this.$rootScope.__.GAMES[this.$scope._.gameId][this.$scope._.env].images.length;
                this.$rootScope.__.GAMES[this.$scope._.gameId][this.$scope._.env].images.push('');
                self.$scope._.game.ResourceSelector.show("images." + len);
            },
            deleteImages: ($index) => {
                this.$rootScope.__.GAMES[this.$scope._.gameId][this.$scope._.env].images.splice($index, 1);
            },
            onChangeGameEditMode: () => {
                this.$modal('修改游戏编辑模式可能会导致未知问题, 请慎重选择!');
                this.$modal.on_ok = () => this.$modal.hide();
                this.$modal.cancel_text = '';
            },
            // TODO: 编辑游戏保存
            save: async () => {
                let form = document.getElementById('gameSettingForm');
                if (form.checkValidity() === false) return form.classList.add('was-validated');
                form.classList.remove('was-validated');
                let game = this.$rootScope.__.GAMES[this.$scope._.gameId][this.$scope._.env];
                try {
                    self.$progress();
                    self.$scope._.game.saving = true;
                    let devGame = await this.ApiService.postEditorGameConfig(this.$scope._.gameId, game);
                    let releaseDate = new Date(devGame.releaseDate);
                    releaseDate.setSeconds(0);
                    releaseDate.setMilliseconds(0);
                    devGame.releaseDate = releaseDate;
                    this.$rootScope.__.GAMES[this.$scope._.gameId][this.$scope._.env] = devGame;
                    this.$alert.success('修改成功!');
                    self.$scope._.game.saving = false;
                    self.$progress.end();
                    this.initGameVersion().then();
                } catch (error) {
                    console.error(error);
                    this.$alert.error(error.data && error.data.status && error.data.status.message);
                    self.$scope._.game.saving = false;
                    self.$progress.end();
                }
            },
            ResourceSelector: {
                type: '',
                field: '',
                show: (field, type) => {
                    this.$scope._.game.ResourceSelector.type = type || 'image';
                    this.$scope._.game.ResourceSelector.field = field;
                    $('#game_resource_modal').modal({
                        show: true,
                        backdrop: 'static',
                    });
                },
                onselect: (resource) => {
                    let fields = this.$scope._.game.ResourceSelector.field.split('.');
                    let game = this.$rootScope.__.GAMES[this.$scope._.gameId][this.$scope._.env];
                    if (!game.data.config.STYLE) game.data.config.STYLE = {};
                    if (!game.data.config.MUSIC) game.data.config.MUSIC = {};
                    if (resource) {
                        if (fields.length === 1) game[fields[0]] = resource.url || '';
                        if (fields.length === 2) game[fields[0]][fields[1]] = resource.url || '';
                        if (fields.length === 3) game[fields[0]][fields[1]][fields[2]] = resource.url || '';
                        if (fields.length === 4) game[fields[0]][fields[1]][fields[2]][fields[3]] = resource.url || '';
                    }
                    $('#game_resource_modal').modal('hide');
                }
            }

        };
        try {
            this.$scope._.game.author = await self.ApiService.getAuthorAccountByUserId(this.$rootScope.__.GAMES[this.$scope._.gameId][this.$scope._.env].releaseId);
        } catch (e) {
            console.error(e);
        }
        this.$scope._.updateLog = {
            addVersionLog: ($index) => {
                if (!this.$rootScope.__.GAMES[this.$scope._.gameId][this.$scope._.env].others.updateLog) {
                    this.$rootScope.__.GAMES[this.$scope._.gameId][this.$scope._.env].others.updateLog = [];
                }
                this.$rootScope.__.GAMES[this.$scope._.gameId][this.$scope._.env].others.updateLog.splice($index + 1, 0, {
                    change: []
                });
            },
            deleteVersionLog: ($index) => {
                this.$rootScope.__.GAMES[this.$scope._.gameId][this.$scope._.env].others.updateLog.splice($index, 1);
            },
            save: async () => {
                let game = this.$rootScope.__.GAMES[this.$scope._.gameId][this.$scope._.env];
                try {
                    this.$rootScope.__.GAMES[this.$scope._.gameId][this.$scope._.env] = await this.ApiService.postEditorGameConfig(this.$scope._.gameId, game);
                    this.$alert.success('修改成功!');
                } catch (error) {
                    console.error(error);
                    this.$alert.error(error.data && error.data.status && error.data.status.message);
                }
            }
        };

        this.$scope.Announcement = {
            count: 0,
            limit: 20,
            list: [],
            page: 1,
            userPublicInfos: {},
            createModal: {
                content: '',
                h5link: '',
                status: '0',
                show() {
                    $('#game-announcement-create-modal').modal('show');
                },
                async submit() {
                    if (!this.content) {
                        self.$alert.error('内容不能为空');
                        return;
                    }
                    try {
                        await self.ApiService.postAuthorGameAnnouncement(self.$scope._.gameId, this.content, this.h5link, this.status);
                        self.$scope.Announcement.setData();
                        $('#game-announcement-create-modal').modal('hide');
                    } catch (e) {
                        self.$alert.error(e.message);
                    }

                    this.content = '';
                    this.h5link = '';
                    this.status = '0';
                }
            },
            detailModal: {
                announcement: {},
                baseAnnouncement: {},
                show(announcement) {
                    this.baseAnnouncement = announcement;
                    this.announcement = JSON.parse(JSON.stringify(announcement));
                    $('#game-announcement-detail-modal').modal('show');
                },
                async submit() {
                    if (!this.announcement.content) {
                        self.$alert.error('内容不能为空');
                        return;
                    }
                    let update = {};
                    if (this.baseAnnouncement.content !== this.announcement.content) {
                        update.content = this.announcement.content;
                    }
                    if (this.baseAnnouncement.h5link !== this.announcement.h5link) {
                        update.h5link = this.announcement.h5link;
                    }
                    if (this.baseAnnouncement.status !== this.announcement.status) {
                        update.status = String(this.announcement.status);
                    }
                    if (!Object.keys(update).length) {
                        $('#game-announcement-detail-modal').modal('hide');
                        return;
                    }
                    try {
                        await self.ApiService.putAuthorGameAnnouncement(this.announcement.announcementId, update.content, update.h5link, update.status);
                        self.$scope.Announcement.setData();
                        $('#game-announcement-detail-modal').modal('hide');
                    } catch (e) {
                        self.$alert.error(e.message);
                    }
                }
            },
            openH5link(h5link) {
                window.open(h5link, '_blank');
            },
            async setData() {
                let result = await self.ApiService.getAuthorGameAnnouncementList(self.$scope._.gameId, this.page, this.limit);
                this.count = result.count;
                this.list = result.list;
                for (let item of this.list) {
                    if (item.publisherId) {
                        item.publisher = await this.getUserPublicInfo(item.publisherId);
                    }
                    for (let _item of item.editLog) {
                        await this.getUserPublicInfo(_item.userId);
                    }
                }
                self.$scope.$apply();
            },
            onPageChange(page) {
                self.$scope.Announcement.page = page;
                self.$scope.Announcement.setData();
            },
            async deleteAnnouncement(announcementId) {
                self.$modal(`删除后无法恢复, 确定删除此公告？`);
                self.$modal.on_ok = async () => {
                    try {
                        await self.ApiService.deleteAuthorGameAnnouncement(announcementId);
                        self.$modal.hide();
                        self.$scope.Announcement.setData();
                    } catch (e) {
                        self.$alert.error(e.message);
                        self.$modal.hide();
                    }
                };
            },
            async getUserPublicInfo(userId) {
                let user = this.userPublicInfos[userId];
                if (!user) user = await self.ApiService.getPlayerInfo({userId});
                this.userPublicInfos[userId] = user || {userId};
                return this.userPublicInfos[userId];
            }
        };

        this.$scope._.collaborators = {
            showFindCollaborator: false,
            isCollaborator: false,
            list: [],
            uid: '',
            user: {headimgurl: 'https://resource.itaotuo.com/puzzle/white.jpg'},
            info: '',
            role: 'editor',
            find: () => {
                this.$scope._.collaborators.showFindCollaborator = false;
                this.$scope._.collaborators.user = {headimgurl: 'https://resource.itaotuo.com/puzzle/white.jpg'};
                let uid = this.$scope._.collaborators.uid;
                if (!uid) return;
                this.$scope._.collaborators.info = '';
                this.ApiService.getAuthorAccountByUID(uid)
                    .then(user => {
                        this.$scope._.collaborators.showFindCollaborator = true;
                        this.$scope._.collaborators.user = user;
                        if (this.$scope._.collaborators.list.find(item => user.userId === item.userId)) {
                            this.$scope._.collaborators.isCollaborator = true;
                        } else {
                            this.$scope._.collaborators.isCollaborator = false;
                        }
                    })
                    .catch(error => {
                        this.$scope._.collaborators.showFindCollaborator = true;
                        if (error.status === 550) {
                            this.$scope._.collaborators.info = '未查找到作者';
                        } else {
                            console.error(error);
                            this.$alert.error(error.data && error.data.status && error.data.status.message);
                        }
                    })
            },
            add: user => {
                this.$scope._.collaborators.update({
                    userId: user.userId,
                    role: this.$scope._.collaborators.role
                });
                this.$scope._.collaborators.showFindCollaborator = false;
                this.$scope._.collaborators.role = 'editor';
                this.$scope._.collaborators.query = '';
                this.$scope._.collaborators.info = '';
                this.$scope._.collaborators.user = {headimgurl: 'https://resource.itaotuo.com/puzzle/white.jpg'};
            },
            update: collaboration => {
                this.ApiService.postGameEditorCollaboration(this.$scope._.gameId, {
                    userId: collaboration.userId,
                    role: collaboration.role
                })
                    .then(() => {
                        this.initGameEditorCollaborationList().then();
                    })
                    .catch(error => {
                        console.error(error);
                        this.$alert.error(error.data && error.data.status && error.data.status.message);
                    })
            },
            delete: collaboration => {
                if (collaboration.role === 'creator') return this.$alert.error('游戏创建者不能被移除');
                this.$modal(`确定移除作者 ${collaboration.nickname}？`);
                this.$modal.on_ok = () => {
                    this.ApiService.deleteGameEditorCollaboration(this.$scope._.gameId, collaboration.userId)
                        .then(() => {
                            this.$alert.info('已移除');
                            this.initGameEditorCollaborationList().then();
                            this.$modal.hide();
                        })
                        .catch(error => {
                            console.error(error);
                            this.$alert.error(error.data && error.data.status && error.data.status.message);
                            this.$modal.hide();
                        })
                };
            }
        };
        this.$scope._.testers = {
            showFindCollaborator: false,
            list: [],
            query: '',
            player: {others: {headimgurl: 'https://resource.itaotuo.com/puzzle/white.jpg'}},
            info: '',
            role: 'editor',
            parseUserIdCounts: id => this.$util.addPreZero(id, 7),
            find: () => {
                this.$scope._.testers.showFindCollaborator = false;
                this.$scope._.testers.player = {others: {headimgurl: 'https://resource.itaotuo.com/puzzle/white.jpg'}};
                let uid = this.$scope._.testers.query;
                if (!uid) return;
                let query = {uid: Number(uid)};
                this.$scope._.testers.info = '';
                this.ApiService.getPlayerInfo(query)
                    .then(player => {
                        this.$scope._.testers.showFindCollaborator = true;
                        this.$scope._.testers.player = player;
                    })
                    .catch(error => {
                        this.$scope._.testers.showFindCollaborator = true;
                        if (error.status === 550) {
                            this.$scope._.testers.info = '未查找到玩家';
                        } else {
                            console.error(error);
                            this.$alert.error(error.data && error.data.status && error.data.status.message);
                        }
                    })
            },
            add: player => {
                this.ApiService.postGameTester(this.$scope._.gameId, player.userId)
                    .then(() => {
                        this.initGameTesterList().then();
                    })
                    .catch(error => {
                        console.error(error);
                        this.$alert.error(error.data && error.data.status && error.data.status.message);
                    });

                this.$scope._.testers.showFindCollaborator = false;
                this.$scope._.testers.role = 'editor';
                this.$scope._.testers.query = '';
                this.$scope._.testers.info = '';
                this.$scope._.testers.player = {others: {headimgurl: 'https://resource.itaotuo.com/puzzle/white.jpg'}};
            },
            delete: player => {
                this.$modal(`确定移除测试员 ${player.others.nickname}？`);
                this.$modal.on_ok = () => {
                    this.ApiService.deleteGameTester(this.$scope._.gameId, player.userId)
                        .then(() => {
                            this.initGameTesterList().then();
                            this.$alert.info('已移除');
                            this.$modal.hide();
                        })
                        .catch(error => {
                            console.error(error);
                            this.$alert.error(error.data && error.data.status && error.data.status.message);
                            this.$modal.hide();
                        })
                };
            }
        };
        this.$scope._.notifications = {};

        this.$scope._.generateMultiGame = {
            onButtonClick: () => {
                if (this.$rootScope.__.GAMES[this.$scope._.gameId].role !== 'creator') {
                    return this.$alert.error('只有创建者可以发布多人版本游戏!');
                }
                $('#game_release_multi_confirm_modal').modal('show');
            },
            generateMultiGame: async () => {
                try {
                    this.$progress();
                    await this.ApiService.generateMultiGame(this.$scope._.gameId);
                    this.$alert.info('多人版本游戏已发布');
                    this.$progress.end();
                    this.$rootScope.__.GAMES[this.$scope._.gameId + 'M'] = {role: 'creator'};
                    $('#game_release_multi_confirm_modal').modal('hide');
                } catch (error) {
                    this.$progress.hide();
                    console.error(error);
                    this.$alert.error(error.data && error.data.status && error.data.status.message);
                }
            },
        };
        this.$scope._.import = {
            onUploadBtnClick: () => {
                $('#game_settings_excel_upload_modal').modal('show');
                document.getElementById('inputGameExcel').disabled = '';
            },
            uploadGameExcel: async () => {
                let data = new FormData(this.$window.document.getElementById('importGameExcelForm'));
                data.append('userId', this.$rootScope.__.ME.userId);
                data.append('gameId', this.$scope._.gameId);
                this.$progress();
                try {
                    this.$rootScope.__.GAMES[this.$scope._.gameId][this.$scope._.env] = await this.ApiService.uploadGameExcel(data);
                    this.$alert.info('上传成功');
                    this.$progress.end();
                    $('#game_settings_excel_upload_modal').modal('hide');

                } catch (error) {
                    this.$progress.hide();
                    console.error(error);
                    this.$alert.error(error.data && error.data.status && error.data.status.message);
                }
            }
        };
        this.$scope._.delete = {
            input: '',
            onDeleteButtonClick: () => {
                this.$scope._.delete.input = '';
                if (this.$rootScope.__.GAMES[this.$scope._.gameId].role !== 'creator') {
                    return this.$alert.error('只有创建者可以删除游戏!');
                }
                $('#game_delete_confirm_modal').modal('show');
            },
            deleteGame: async () => {
                try {
                    this.$progress();
                    await this.ApiService.deleteGame(this.$scope._.gameId);
                    delete this.$rootScope.__.GAMES[this.$scope._.gameId];
                    this.$alert.info('游戏已删除');
                    this.$progress.end();
                    $('#game_delete_confirm_modal').modal('hide');
                    this.$timeout(() => this.$rootScope.__.Path('/game/list'), 1000);
                } catch (error) {
                    this.$progress.hide();
                    console.error(error);
                    this.$alert.error(error.data && error.data.status && error.data.status.message);
                }
            },
        };

        await this.loadData();
        this.initGameEditorCollaborationList().then();
        this.initGameTesterList().then();

        this.$scope.$watch('_.tab', (newVal, oldVal) => {
            if (newVal) {
                if (newVal === 'announcement') {
                    this.$scope.Announcement.setData();
                }
            }
        })
    }

    async initGameEditorCollaborationList() {
        try {
            this.$scope._.collaborators.list = await this.ApiService.getGameEditorCollaborationList(this.$scope._.gameId);
            this.$timeout(() => this.$scope.$apply(), 0);
        } catch (error) {
            console.error(error);
            this.$alert.error(error.data && error.data.status && error.data.status.message);
        }
    }

    async initGameTesterList() {
        try {
            this.$scope._.testers.list = await this.ApiService.getGameTesterList(this.$scope._.gameId);
            this.$timeout(() => this.$scope.$apply(), 0);
        } catch (error) {
            console.error(error);
            this.$alert.error(error.data && error.data.status && error.data.status.message);
        }
    }

    async onChangeFile() {
        let file = document.getElementById('inputGameExcel').files[0];
        this.$scope._.import.filename = file.name;
        this.$scope.$apply();
    }

    async loadData() {
        // __.GAMES[_.gameId][_.env].subtitle
        try {
            let devGame = await this.ApiService.getEditorGameSynopsis(this.$scope._.gameId);
            let releaseDate = new Date(devGame.releaseDate);
            releaseDate.setSeconds(0);
            releaseDate.setMilliseconds(0);
            devGame.releaseDate = releaseDate;
            if (!devGame.others.themes) {
                if (devGame.others.theme) {
                    devGame.others.themes = [devGame.others.theme];
                } else {
                    devGame.others.themes = [];
                }
            }
            if (!this.$rootScope.__.GAMES[this.$scope._.gameId]) this.$rootScope.__.GAMES[this.$scope._.gameId] = {};
            this.$rootScope.__.GAMES[this.$scope._.gameId].dev = devGame;
            if (!!this.$rootScope.__.GAMES[this.$scope._.gameId].dev) this.$scope._.envs.dev = '开发版本';

            let testGame;
            try {
                testGame = await this.ApiService.GetTestGameSynopsis(this.$scope._.gameId);
            } catch (e) {
                console.error(e);
            }
            if (testGame) {
                if (!this.$rootScope.__.GAMES[this.$scope._.gameId]) this.$rootScope.__.GAMES[this.$scope._.gameId] = {};
                this.$rootScope.__.GAMES[this.$scope._.gameId].test = testGame;
                if (!!this.$rootScope.__.GAMES[this.$scope._.gameId].test) this.$scope._.envs.test = '测试版本';
            }
            let proGame;
            try {
                proGame = await this.ApiService.getProGameSynopsis(this.$scope._.gameId);
            } catch (e) {
                console.error(e);
            }
            if (proGame) {
                if (!this.$rootScope.__.GAMES[this.$scope._.gameId]) this.$rootScope.__.GAMES[this.$scope._.gameId] = {};
                this.$rootScope.__.GAMES[this.$scope._.gameId].pro = proGame;
                if (!!this.$rootScope.__.GAMES[this.$scope._.gameId].pro) this.$scope._.envs.pro = '正式版本';
            }
            this.$rootScope.$apply();
        } catch (error) {
            console.error(error);
            this.$alert.error(error.data && error.data.status && error.data.status.message);
        }
    }

}

GameDetailSettingsController.$inject = ['$scope', '$rootScope', '$location', '$window', '$routeParams', '$timeout', 'ApiService', '$event', '$alert', '$modal', '$util', '$progress'];
app.controller('GameDetailSettings', GameDetailSettingsController);
