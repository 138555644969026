const app = angular.module('editor');
import GameEditorController from './GameEditorController';

class GameEditorFastController extends GameEditorController {
    constructor($scope, $rootScope, $window, $routeParams, $timeout, $event, $alert, $modal, $storage, ApiService) {
        super($scope, $rootScope, $window, $routeParams, $timeout, $event, $alert, $modal, $storage, ApiService);

        $scope.EditorMode.select = 'fast';

        let self = this;

        self.lockMissionIds = {};

        let _flag = Date.now();
        let _right_menu_id = '';

        $scope.fast = {
            // gameStyle: {backgroundColor: '#000'},
            npc_array: [],
            player_array: [],
            end_array: [],
            textStyle_array: [],
            attr_array: [],
            target_array: [],
            prop_array: [],
            file_array: [],
            gameBranchChooseConfig: this.$storage.getObject('gameBranchChooseConfig_' + this.$scope._.gameId) || {},
            missionMaxIndex: {},
            loop: { from: '', to: '' },
            richTextEditor: '',
            RightMenu: {
                show: false,
                style: { position: 'fixed' },
                module: null,
                open($event, module, $index) {
                    _right_menu_id = '';
                    $('#game_editor_fast_right_menu_d1').dropdown('hide');
                    $('#game_editor_fast_right_menu_d1_u').css('margin-top', 0);
                    $('#game_editor_fast_right_menu_d2').dropdown('hide');
                    $('#game_editor_fast_right_menu_d2_u').css('margin-top', 0);
                    this.module = module;
                    this.index = $index;
                    let top_max = $(window).height() - 200;
                    this.style = {
                        position: 'fixed',
                        top: $event.clientY > top_max ? $event.clientY - 180 : $event.clientY,
                        left: $event.clientX,
                    };
                    this.show = !this.show;
                    $scope.$apply();
                },
                onClickItem(operate_str, type) {
                    if (self.$scope.game.others.serial) {
                        // 连载番已发布章节限制
                        let missionId = this.module.moduleId.split('_')[0];
                        if (self.lockMissionIds[missionId] && type !== 'switch-chapter') {
                            self.$alert.error('章节已发布, 无法增加或删除模块!');
                            return;
                        }
                    }
                    let [operate, position, _type] = operate_str.split('_');
                    if (!type) type = _type;
                    try {
                        if ('add' === operate) {
                            $scope.fast.Module.add(type, position, this.module, this.index);
                        } else if ('delete' === operate) {
                            $scope.fast.Module.delete(this.module, this.index);
                        }
                    } catch (e) {
                        self.$alert.error(e && e.message);
                    }
                    this.show = false;
                    $('#game_editor_fast_right_menu_d1').dropdown('hide');
                    $('#game_editor_fast_right_menu_d2').dropdown('hide');
                },
                cancelRight() {
                    window.event.returnValue = false;
                    return false;
                },
                onDropdownMenuShow(id) {
                    if (_flag) {
                        if (Date.now() - _flag < 20) return;
                        _flag = Date.now();
                    }
                    if (_right_menu_id && _right_menu_id === id) return;
                    _right_menu_id = id;
                    $('#game_editor_fast_right_menu_d1').dropdown('hide');
                    $('#game_editor_fast_right_menu_d1_u').css('margin-top', 0);
                    $('#game_editor_fast_right_menu_d2').dropdown('hide');
                    $('#game_editor_fast_right_menu_d2_u').css('margin-top', 0);
                    $(id).dropdown('show');
                    let top_max = $(window).height() - 389;
                    let d = $(id + '_u').offset().top - top_max;
                    if (d > 0) {
                        $(id + '_u').css('margin-top', -d);
                    } else {
                        $(id + '_u').css('margin-top', 0);
                    }
                },
                onDropdownMenuHide() {
                    $('#game_editor_fast_right_menu_d1').dropdown('hide');
                    $('#game_editor_fast_right_menu_d2').dropdown('hide');
                }
            },

            Module: {
                select: null,
                base_select_string: '',
                select_string: '',
                select_index: 0,
                setting_mode: 'menu',
                types: {
                    // 'BGM_audio': '背景音乐',
                    'video': '视频',
                    'audio': '音频',
                    'ads': '广告',
                    'unlock-mission': '解锁章节',
                    // 'split': '分支',
                    'richText': '文本',
                    'setPaintingTalk': '立绘',
                    'talk': '对话',
                    // 'chapter': '章节起始',
                    'switch-chapter': '切换下章',
                    'gif': '动图',
                    'image': '图片',
                    'choose': '选择',
                    'input': '答题',
                    'hint': '提示',
                    'space': '空行',
                    'condition': '条件判断',
                    'novelStopChoose': '小说卡点',
                    // 'hotImage': '热图',
                    'chooseSetPaintingTalk': '选择(单选) 立绘',
                    'end': '结局',
                    'award': '奖励',
                    'taskAttachment': '任务',
                    // 'appMessage': '优惠卷',
                    'GameMainTaskFinished': '游戏主流程结束',
                    'DemoMainTaskFinished': '试玩结束',
                    'SystemBackgroundBegin': '启用立绘背景图',
                    'SystemBackgroundEnd': '停用立绘背景图',
                },

                onselect(module, $index) {
                    if (module) {
                        this.select_string = this.base_select_string = JSON.stringify(module, '', 4);
                        this.select = JSON.parse(this.select_string);
                        this.setting_mode = 'menu';
                        if (typeof this.select.value === 'object') {
                            this.select.$$value = Object.keys(this.select.value).map(key => ({
                                key,
                                value: this.select.value[key]
                            }));
                            this.select.$$valueIndex = $scope.fast.gameBranchChooseConfig[module.moduleId] || 0;
                        }
                        if (this.select.event) {
                            if (['hotImage'].includes(this.select.type)) {
                                if ($scope.fast.gameBranchChooseConfig.hasOwnProperty(module.moduleId)) {
                                    this.select.$$valueIndex = $scope.fast.gameBranchChooseConfig[module.moduleId];
                                } else {
                                    this.select.$$valueIndex = -1;
                                }
                            }
                            if (this.select.event.onEnterCenter && this.select.event.onEnterCenter.effect) {
                                this.select.$$playEffect = this.select.event.onEnterCenter.effect.find(item => item.eventEffectType === 'playEffect');
                                this.select.$$playBGM = this.select.event.onEnterCenter.effect.find(item => item.eventEffectType === 'playBGM');
                            }
                        }
                        if (this.select.branch_ids) {
                            if (['split'].includes(this.select.type)) {
                                if ($scope.fast.gameBranchChooseConfig.hasOwnProperty(module.moduleId)) {
                                    this.select.$$valueIndex = $scope.fast.gameBranchChooseConfig[module.moduleId];
                                } else {
                                    this.select.$$valueIndex = -1;
                                }
                            }
                        }
                        if (this.select.wayOut) {
                            if (['condition'].includes(this.select.type)) {
                                if ($scope.fast.gameBranchChooseConfig.hasOwnProperty(module.moduleId)) {
                                    this.select.$$valueIndex = $scope.fast.gameBranchChooseConfig[module.moduleId];
                                } else {
                                    this.select.$$valueIndex = 0;
                                }
                                if (this.select.conditions) {
                                    for (let attr in this.select.conditions) {
                                        // fix: conditions recover select value
                                        const _ = this.select.conditions[attr]
                                        this.select.conditions[attr].value = _.value.replace('.number', '')
                                        // fix: conditions recover select value end

                                        if (this.select.conditions[attr].value) {
                                            let prop = $scope.fast.getProp(this.select.conditions[attr].value);
                                            if (['attr', 'system'].includes(prop.type)) {
                                                this.select.conditions[attr].$$p_type = 'attr';
                                            } else if (['target'].includes(prop.type)) {
                                                this.select.conditions[attr].$$p_type = 'target';
                                            } else if (['prop', 'image', 'audio', 'video'].includes(prop.type)) {
                                                this.select.conditions[attr].$$p_type = 'prop';
                                            }
                                        } else {
                                            this.select.conditions[attr].$$p_type = 'attr';
                                        }
                                    }
                                }
                            }
                        }
                        if (this.select.attachment) {
                            if (['award'].includes(this.select.type)) {
                                for (let item of this.select.attachment) {
                                    let $$type = self.$scope.fast.getProp(item.key).type;
                                    if (['image', 'audio', 'video'].includes($$type)) $$type = 'prop';
                                    if (['system', 'attr'].includes($$type)) $$type = 'attr';
                                    item.$$type = $$type;
                                    item.number = Number(item.number);
                                }
                            }
                        }
                        if (this.select.type === 'taskAttachment') {
                            if (this.select.attachment && this.select.attachment.length) {
                                this.select.$$status = 'start';
                            }
                            if (this.select.commands && this.select.commands.length) {
                                this.select.$$status = this.select.commands[0].params[1].value;
                            }
                        }
                    } else {
                        this.select_string = this.base_select_string = '';
                        this.select = null;
                    }
                },
                onkeydown($event, module, $index) {
                    if ($event.ctrlKey && $event.keyCode === 13) {
                        this.add(this.select.type, 'next', module, $index);
                    }
                    if ($event.shiftKey && $event.keyCode === 84) {
                        this.add('richText', 'next', module, $index);
                    }
                    if ($event.ctrlKey && $event.keyCode === 46) {
                        this.delete(module, $index);
                    }
                },
                selectResource(key, type) {
                    $scope.fast.ResourceSelector.show(type, resource => {
                        let arr = key.split('.');
                        if (arr.length === 1) this.select[arr[0]] = resource.key;
                        if (arr.length === 2) {
                            if (!this.select[arr[0]]) {
                                this.select[arr[0]] = {};
                                if ('$$playEffect.value' === key) {
                                    this.select[arr[0]].eventEffectType = 'playEffect';
                                    this.select[arr[0]].delay = 0;
                                    this.select[arr[0]].isLoopTimes = 0;
                                }
                                if ('$$playBGM.value' === key) {
                                    this.select[arr[0]].eventEffectType = 'playBGM';
                                    this.select[arr[0]].delay = 0;
                                    this.select[arr[0]].isLoopTimes = 1;
                                }
                            }
                            this.select[arr[0]][arr[1]] = resource.key;
                        }
                        if (arr.length === 3) this.select[arr[0]][arr[1]][arr[2]] = resource.key;
                    })
                },

                T_split: {
                    addBranch(module) {
                        let len = module.branch_ids.length;
                        if (module.branch_ids[len - 1]) module.branch_ids.push('');
                    },
                    deleteBranch(module, $index) {
                        module.branch_ids.splice($index, 1);
                    },
                },
                T_award: {
                    addAttachment(module) {
                        let len = module.attachment.length;
                        if (!len || module.attachment[len - 1].key) module.attachment.push({
                            key: '',
                            attachmentType: 'set',
                            number: 1,
                            $$type: 'attr',
                        });
                    },
                    deleteAttachment(module, $index) {
                        module.attachment.splice($index, 1);
                    },
                },
                T_richText: {
                    oneEditModuleId: '',
                    parseToHtml(module) {
                        try {
                            return RTtoHTML(module.value);
                        } catch (e) {
                            return module.value;
                        }
                    },
                    createEditor(module, moduleId) {
                        self.$scope.fast.Module.onselect(null);
                        this.oneEditModuleId = moduleId;
                        let height = $('#RT-' + moduleId).height();
                        $scope.fast.richTextEditor = new Jodit('#RE-' + moduleId, {
                            "width": "100%",
                            "height": height + 'px',
                            "language": "zh_cn",
                            "defaultActionOnPaste": "insert_only_text",
                            "disablePlugins": "color",
                            "buttons": "source,bold,strikethrough,underline,italic,|,fontsize,brush,|,align,undo,redo,|,",//insertUnderlineX
                            "buttonsSM": "source,bold,strikethrough,underline,italic,|,fontsize,brush,|,align,undo,redo,|,",
                            "buttonsMD": "source,bold,strikethrough,underline,italic,|,fontsize,brush,|,align,undo,redo,|,",
                            "buttonsXS": "source,bold,strikethrough,underline,italic,|,fontsize,brush,|,align,undo,redo,|,",
                            controls: {
                                insertUnderlineX: {
                                    name: 'insertUnderlineX',
                                    iconURL: 'https://resource.itaotuo.com/lib/jodit/xhx.png',
                                    exec: function (editor) {
                                        let text = editor.selection.getHTML();
                                        if (text) {
                                            editor.selection.insertHTML('<x>' + editor.selection.getHTML() + '</x>');
                                            editor.selection.remove();
                                        }
                                    }
                                }
                            }
                        });
                        $scope.fast.richTextEditor.value = RTtoHTML(module.value);
                        //给编辑器添加阻止事件
                        window.editorClickFunc = function (event) {
                            event.stopPropagation();
                            $scope.fast.richTextEditor.selection.insertHTML('');
                        };
                        $('.jodit_container')[0].setAttribute("onclick", 'editorClickFunc(event)');
                    }
                },
                T_talk: {
                    getTextStyle(id = 'talkDefault') {
                        let textStyle = {};
                        if (id === 'talkSelfDefault' && self.$scope.game.data.props.talkSelfDefault) {
                            textStyle.color = self.$scope.game.data.props.talkSelfDefault.color;
                            textStyle.fontSize = Number(self.$scope.game.data.props.talkSelfDefault.fontSize) / 3;
                            textStyle.textAlign = self.$scope.game.data.props.talkSelfDefault.alignment_h;
                        }
                        if (id === 'talkDefault' && self.$scope.game.data.props.talkDefault) {
                            textStyle.color = self.$scope.game.data.props.talkDefault.color;
                            textStyle.fontSize = Number(self.$scope.game.data.props.talkDefault.fontSize) / 3;
                            textStyle.textAlign = self.$scope.game.data.props.talkDefault.alignment_h;
                        }
                        return textStyle
                    },
                },
                T_choose: {
                    addValue(module) {
                        let len = module.$$value.length;
                        if (!len || module.$$value[len - 1].key) module.$$value.push({ key: '' });
                    },
                    addDefaultValue(module) {
                        if (!module.$$value.find(v => v.key === '_DEFAULT')) {
                            module.$$value.push({ key: '_DEFAULT' });
                        }
                    },
                    deleteValue(module, $index) {
                        module.$$value.splice($index, 1);
                    },
                    getBranchChooseKey(module) {
                        return Object.keys(module.value)[$scope.fast.gameBranchChooseConfig[module.moduleId] || 0]
                    },
                },
                T_condition: {
                    // fix: conditions type: 'value', to type: 'object',
                    addCondition(module) {
                        let i = 0;
                        if (!module.conditions) module.conditions = {};
                        if (!module.wayOut) module.wayOut = {};
                        for (let attr in module.conditions) {
                            if (attr.startsWith('true')) {
                                let a = attr.substring(4);
                                let int_a = parseInt(a);
                                if (!i && int_a === 0) i = 1;
                                else if (int_a && int_a >= i) i = int_a + 1;
                            }
                        }
                        let new_key = 'true' + i;
                        module.conditions[new_key] = {
                            value: '',
                            type: 'object',
                            conditionType: 'true',
                            compareValue: 0,
                            compareType: 'number',
                            $$type: 'attr',
                        };
                        module.wayOut[new_key] = '';
                    },
                    deleteCondition(module, key) {
                        delete module.conditions[key];
                        delete module.wayOut[key];
                    },
                },
                T_hotImage: {
                    lastIndex: null,
                    onEventSelected(i) {
                        if (this.lastIndex === i) {
                            this.lastIndex = null;
                            self.$scope.fast.Module.select.$$valueIndex = -1;
                        } else {
                            this.lastIndex = i;
                        }
                    },
                },
                T_end: {
                    last: null,
                    onChange() {
                        if (this.last === null) {
                            this.last = self.$scope.game.data.modules[self.$scope.fast.Module.select.moduleId];
                        }
                        let new_attachment = {
                            key: self.$scope.fast.Module.select.value,
                            number: 1,
                            attachmentType: 'set',
                        }
                        if (self.$scope.fast.Module.select.attachment && self.$scope.fast.Module.select.attachment.length) {
                            self.$scope.fast.Module.select.attachment = self.$scope.fast.Module.select.attachment.map(item => {
                                if (item.key === this.last) return new_attachment;
                            })
                        } else {
                            self.$scope.fast.Module.select.attachment = [new_attachment];
                        }
                        this.last = self.$scope.fast.Module.select.value;
                    },
                },
                updateSettingMode(mode) {
                    if (this.setting_mode === 'menu' && mode === 'json') {
                        this.select_string = JSON.stringify(this.select, '', 4);
                    }
                    if (this.setting_mode === 'json' && mode === 'menu') {
                        this.select = JSON.parse(this.select_string);
                    }
                    this.setting_mode = mode;
                },
                add(type, position = 'next', module, index) {
                    // console.log('add', type, position, module, index)
                    let new_module = self.__generateModule(type, module.moduleId.split('_')[0]);
                    if ('next' === position) {
                        if (module.type === 'switch-chapter') throw Error('本章节已结束, 无法此后添加模块!');
                        if (module.type === 'GameMainTaskFinished') throw Error('游戏主流程结束, 无法再添加模块!');
                        let new_module_next_moduleId = $scope.fast.branchModuleIdList[index + 1] || '';
                        if (new_module.hasOwnProperty('next')) {
                            new_module.next = new_module_next_moduleId;
                        } else {
                            if (typeof new_module.value === 'object') {
                                new_module.value[Object.keys(new_module.value)[0]] = new_module_next_moduleId;
                            } else if (typeof new_module.wayOut === 'object') {
                                new_module.wayOut[Object.keys(new_module.wayOut)[0]] = new_module_next_moduleId;
                            } else if (['GameMainTaskFinished'].includes(new_module.type)) {

                            } else {
                                throw Error('暂时不支持添加此模块:' + this.types[type] || type);
                            }
                        }
                        if (module.hasOwnProperty('next')) {
                            if (new_module_next_moduleId && module.next !== new_module_next_moduleId) {
                                throw Error('不支持在此处添加模块:' + this.types[module.type] || module.type);
                            }
                            $scope.game.data.modules[module.moduleId].next = new_module.moduleId;
                        } else {
                            if (typeof module.value === 'object') {
                                for (let key in module.value) {
                                    if (module.value[key] === new_module_next_moduleId) {
                                        $scope.game.data.modules[module.moduleId].value[key] = new_module.moduleId;
                                    }
                                }
                            } else {
                                throw Error('不支持在此处添加模块:' + this.types[module.type] || module.type);
                            }
                        }
                        $scope.game.data.modules[new_module.moduleId] = new_module;
                    } else if ('last' === position) {
                        if (new_module.hasOwnProperty('next')) {
                            new_module.next = module.moduleId;
                        } else {
                            if (typeof new_module.value === 'object') {
                                new_module.value[Object.keys(new_module.value)[0]] = module.moduleId;
                            } else {
                                throw Error('暂时不支持添加此模块:' + this.types[type] || type);
                            }
                        }
                        if (index <= 0) throw Error('无法在章节起始之前插入模块!');
                        let last_module = $scope.game.data.modules[$scope.fast.branchModuleIdList[index - 1]];
                        if (last_module.hasOwnProperty('next') && last_module.next === module.moduleId) {
                            last_module.next = new_module.moduleId;
                        } else {
                            if (typeof last_module.value === 'object') {
                                for (let key in last_module.value) {
                                    if (last_module.value[key] === module.moduleId) {
                                        last_module.value[key] = new_module.moduleId;
                                    }
                                }
                            } else {
                                throw Error('不支持在此处添加模块:' + this.types[module.type] || module.type);
                            }
                        }
                        $scope.game.data.modules[new_module.moduleId] = new_module;
                    }
                    $scope.fast.branchModuleIdList = self.loadBranchData($scope.game, $scope.Catalog.select.moduleId, $scope.fast.gameBranchChooseConfig);
                    this.onselect(new_module);
                    $scope.fast.save();
                },
                delete(module, index) {
                    if (module) {
                        if (!index) throw Error('暂时不支持删除此模块, 0!');
                        if (['chapter'].includes(module.type)) throw Error('暂时不支持删除此模块, chapter');
                        let nextModuleId = $scope.fast.branchModuleIdList[index + 1];
                        let last_module = $scope.game.data.modules[$scope.fast.branchModuleIdList[index - 1]];
                        if (last_module.hasOwnProperty('next') && last_module.next === module.moduleId) {
                            last_module.next = nextModuleId || '';
                        } else {
                            if (typeof last_module.value === 'object') {
                                for (let key in last_module.value) {
                                    if (last_module.value[key] === module.moduleId) {
                                        last_module.value[key] = nextModuleId || '';
                                    }
                                }
                            } else {
                                throw Error('暂时不支持删除此模块, ' + last_module.type);
                            }
                        }
                        // delete $scope.game.data.modules[module.moduleId];
                        $scope.fast.branchModuleIdList = self.loadBranchData($scope.game, $scope.Catalog.select.moduleId, $scope.fast.gameBranchChooseConfig);
                        $scope.fast.save();
                    }
                },
                save() {
                    if (this.setting_mode === 'json') {
                        this.select = JSON.parse(this.select_string);
                    }
                    if (this.select.$$value) {
                        this.select.value = {};
                        for (let item of this.select.$$value) {
                            this.select.value[item.key] = item.value || '';
                        }
                        delete this.select.$$value;
                    }
                    if (this.select.hasOwnProperty('$$valueIndex')) {
                        $scope.fast.branchChoose(this.select, this.select.$$valueIndex);
                        delete this.select.$$valueIndex;
                    }
                    if (this.select.attachment) {
                        if (['award'].includes(this.select.type)) {
                            for (let item of this.select.attachment) {
                                delete item.$$type;
                            }
                        }
                    }
                    if (this.select.$$playEffect) {
                        if (!this.select.event) this.select.event = {};
                        if (!this.select.event.onEnterCenter) this.select.event.onEnterCenter = {};
                        if (!this.select.event.onEnterCenter.effect) this.select.event.onEnterCenter.effect = [];
                        let flag = false;
                        this.select.event.onEnterCenter.effect.map(item => {
                            if (item.eventEffectType === 'playEffect') {
                                flag = true;
                                return this.select.$$playEffect;
                            }
                        });
                        if (!flag) {
                            this.select.event.onEnterCenter.effect.push(this.select.$$playEffect);
                        }
                        delete this.select.$$playEffect;
                    }
                    if (this.select.$$playBGM) {
                        if (!this.select.event) this.select.event = {};
                        if (!this.select.event.onEnterCenter) this.select.event.onEnterCenter = {};
                        if (!this.select.event.onEnterCenter.effect) this.select.event.onEnterCenter.effect = [];
                        let flag = false;
                        this.select.event.onEnterCenter.effect.map(item => {
                            if (item.eventEffectType === 'playBGM') {
                                flag = true;
                                return this.select.$$playBGM;
                            }
                        });
                        if (!flag) {
                            this.select.event.onEnterCenter.effect.push(this.select.$$playBGM);
                        }
                        delete this.select.$$playBGM;
                    }
                    // TODO: ads branch_ids
                    if (this.select.type === 'ads') {
                        console.log('ADS_branch_ids', Object.values(this.select.value));
                        this.select.branch_ids = Object.values(this.select.value);
                    }
                    if (this.select.type === 'taskAttachment') {
                        if (!this.select.value) {
                            self.$alert.error('必须选择任务');
                            throw Error('必须选择任务');
                        }
                        if (!this.select.$$status) {
                            self.$alert.error('必须选择任务状态');
                            throw Error('必须选择任务状态');
                        }
                        if (this.select.$$status === 'start') {
                            delete this.select.commands;
                            this.select.attachment = [{ key: this.select.value, number: 1, attachmentType: 'set' }];
                        } else {
                            delete this.select.attachment;
                            this.select.commands = [{
                                params: [
                                    { type: 'object', value: this.select.value + '.status' },
                                    { type: 'string', value: this.select.$$status }
                                ],
                                commandName: 'setValue',
                                commandType: 'common'
                            }];
                        }
                        delete this.select.$$status;
                    }
                    if (this.select.type === 'talk') {
                        if (this.select.speaker) {
                            let prop = self.$scope.fast.getProp(this.select.speaker);
                            if (prop.type === 'NPC' && this.select.textStyle === 'talkSelfDefault') this.select.textStyle = 'talkDefault';
                            if (prop.type === 'player' && this.select.textStyle === 'talkDefault') this.select.textStyle = 'talkSelfDefault';
                        }
                    }
                    if (this.select.type === 'condition') {
                        // fix: conditions select value
                        for (let attr in this.select.conditions) {
                            if (this.select.conditions[attr].type != 'object') {
                                this.select.conditions[attr].type = 'object'
                            }
                            const _ = this.select.conditions[attr]
                            if (['eq', 'ne', 'gt', 'lt', 'gte', 'lte'].includes(_.conditionType)) {
                                this.select.conditions[attr].value += '.number'
                            }
                        }
                    }
                    if (this.select) {
                        let _temp = JSON.parse(JSON.stringify($scope.game.data.modules[this.select.moduleId]));
                        $scope.game.data.modules[this.select.moduleId] = this.select;
                        $scope.fast.save();
                        if (['choose', 'chooseSetPaintingTalk', 'input', 'hotImage', 'split', 'condition', 'novelStopChoose'].includes(this.select.type)) {
                            $scope.fast.branchModuleIdList = self.loadBranchData($scope.game, $scope.Catalog.select.moduleId, $scope.fast.gameBranchChooseConfig);
                        } else if (_temp.next !== this.select.next) {
                            $scope.fast.branchModuleIdList = self.loadBranchData($scope.game, $scope.Catalog.select.moduleId, $scope.fast.gameBranchChooseConfig);
                        }
                        this.onselect(this.select);
                    }
                    self.$alert('已保存', 'success', { time: 1000 });
                }
            },

            ResourceSelector: {
                type: '',
                callback: null,
                show(type, callback) {
                    this.type = type || 'image';
                    this.callback = callback || null;
                    $('#game_editor_fast_resource_modal').modal({
                        show: true,
                        backdrop: 'static',
                    });
                },
                onselect(resource) {
                    if ($scope.fast.ResourceSelector.callback && typeof $scope.fast.ResourceSelector.callback === 'function') $scope.fast.ResourceSelector.callback(resource);
                    $('#game_editor_fast_resource_modal').modal('hide');
                },
            },

            getProp(propId) {
                return self.$scope.game.data.props[propId];
            },
            getNpcHeader(name, index) {
                if (!name) return '';
                let npc = self.$scope.game.data.props[name];
                if (!npc && name === '玩家') npc = self.$scope.game.data.props.player_default;
                if (npc.headImageArray) return npc.headImageArray[index || 0];
                if (npc.headImage) return npc.headImage;
                return npc.value;
            },
            getNpcName(id, index) {
                if (!id) return '';
                let npc = self.$scope.game.data.props[id];
                if (!npc && id === '玩家') npc = self.$scope.game.data.props.player_default;
                if (npc.nameArray) return npc.nameArray[index || 0];
                if (npc.name) return npc.name;
                return npc.value;
            },
            getResource(resourceId) {
                if (!resourceId) return '';
                if (resourceId.startsWith('nrk_')) return self.$scope.game.others.resources[resourceId];
                return { name: resourceId };
            },
            getResourceUrl(resource) {
                if (!resource || !resource.name) return;
                if (resource.name.startsWith('http')) return resource.name;
                return `${self.$scope.game.others.resourcePrefix}${resource.name}`;
            },
            isObjectString(string) {
                try {
                    if (typeof string === 'object') return true;
                    JSON.parse(string);
                    return true;
                } catch (e) {
                    return false;
                }
            },
            parseResourceUrl(value = '') {
                if (value.startsWith('http')) return value;
                return self.$scope.game.others.resourcePrefix + value;
            },
            branchChoose: (module, key) => {
                if (['choose', 'chooseSetPaintingTalk', 'input', 'hotImage', 'split', 'condition'].includes(module.type)) {
                    let storage_gameBranchChooseConfig = this.$storage.getObject('gameBranchChooseConfig_' + this.$scope._.gameId) || {};
                    if (storage_gameBranchChooseConfig[module.moduleId] !== key) {
                        storage_gameBranchChooseConfig[module.moduleId] = key;
                    }
                    this.$storage.setObject('gameBranchChooseConfig_' + this.$scope._.gameId, storage_gameBranchChooseConfig);
                    if (this.$scope.fast.gameBranchChooseConfig[module.moduleId] !== key) {
                        this.$scope.fast.gameBranchChooseConfig[module.moduleId] = key;
                        this.$scope.fast.branchModuleIdList = this.loadBranchData(this.$scope.game, $scope.Catalog.select.moduleId, this.$scope.fast.gameBranchChooseConfig);
                    }
                }
            },
            save() {
                self.$scope._.editorGame = JSON.stringify(angular.copy(self.$scope.game));
            },
        };

        this.$scope.$watch('_.editorGame', game_string => {
            if (!game_string) return console.log('游戏数据加载中...');
            this.$scope.game = JSON.parse(game_string);
            this.$scope.fast.gameBranchChooseConfig = GameEditorFastController.parseGameBranchChooseConfig(this.$scope.game, this.$scope.fast.gameBranchChooseConfig);
            this.loadGameData(this.$scope.game);
        });

        this.$event.on('memory_game_update_' + this.$scope._.gameId, 'GameEditorFastController.memory_game_update', () => {
            this.loadGameData(this.$scope.game);
        });
        this.$event.on('game_resource_update_' + this.$scope._.gameId, 'GameEditorFastController.game_resource_update', () => {
            this.$scope.game.others.resources = JSON.parse(JSON.stringify(this.$rootScope.__.GAMES[this.$scope._.gameId].dev.others.resources));
        });

        this.$scope.$watch('Catalog.select', catalog => {
            if (!catalog || !this.$scope._.editorGame) return console.log('数据加载中...');
            $(".mobile-phone-simulator").scrollTop(0);
            this.$scope.fast.branchModuleIdList = this.loadBranchData(this.$scope.game, catalog.moduleId, this.$scope.fast.gameBranchChooseConfig);
        });

        this.$scope.$watch('fast.Module.select.speaker', speaker => {
            if (speaker) {
                // console.log(speaker, this.$scope.fast.Module.select)
                if (!this.$scope.fast.Module.select.nameIndex) this.$scope.fast.Module.select.nameIndex = 0;
                if (!this.$scope.fast.Module.select.nameHeadIndex) this.$scope.fast.Module.select.nameHeadIndex = 0;
            }
        });

        $scope.fast.onkeydown = $event => {
            if ($event.altKey && $event.keyCode === 74) {
                self.$scope._.editorGame = JSON.stringify(angular.copy(self.$scope.game));
                $('#game_editor_fast_json_modal').modal('show');
            }
        };

        document.onclick = function () {
            // 隐藏右键菜单
            $scope.fast.RightMenu.show = false;
            // 隐藏富文本编辑器&保存修改
            if ($scope.fast.richTextEditor) {
                $scope.game.data.modules[$scope.fast.Module.T_richText.oneEditModuleId].value = HTMLtoRT($scope.fast.richTextEditor.getEditorValue());
                $scope.fast.Module.T_richText.oneEditModuleId = '';
                $scope.fast.richTextEditor.destruct();
                $scope.fast.richTextEditor = null;
                $scope.fast.save();
            }
            $timeout(() => $scope.$apply());
        };
        $('.dropdown-m-toggle').dropdown();
    }


    async loadGameData(game) {
        this.$scope.fast.npc_array = this.__getGamePropsByType(game, 'NPC');
        this.$scope.fast.player_array = this.__getGamePropsByType(game, 'player');
        this.$scope.fast.end_array = this.__getGamePropsByType(game, 'end');
        this.$scope.fast.textStyle_array = this.__getGamePropsByType(game, 'textStyleClass');
        this.$scope.fast.attr_array = this.__getGamePropsByType(game, ['attr', 'system']);
        this.$scope.fast.target_array = this.__getGamePropsByType(game, 'target');
        this.$scope.fast.prop_array = this.__getGamePropsByType(game, ['prop', 'image', 'audio', 'video']);
        this.$scope.fast.file_array = this.__getGamePropsByType(game, ['新闻', '档案', '史料']);
        this.$scope.fast.task_business_array = this.__getGamePropsByType(game, ['task_business']);

        this.$scope.fast.missionMaxIndex = this.__getGameMissionMaxIndex(game);

        let version = this.$rootScope.__.GAMES[game.gameId].version;
        if (game.others.serial && version.pro && version.pro.version) {
            // 连载番锁定目录
            let proGame = await this.ApiService.getAuthorGameSynopsisPro(game.gameId);
            this.lockMissionIds = {};
            if (proGame && proGame.others.catalog) {
                let catalog = proGame.others.catalog;
                for (let item of catalog) {
                    this.lockMissionIds[item.missionId] = item.moduleId;
                }
            }
        }
    }

    loadBranchData(game, startModuleId, gameBranchChooseConfig = {}) {
        this.$scope.fast.loop.from = this.$scope.fast.loop.to = '';
        let moduleIds = [];
        let moduleId = startModuleId;
        let _tempModuleIds = {};
        let i = 0;
        while (moduleId) {
            let isLoop = this.loopProcessor(moduleId, game, _tempModuleIds, gameBranchChooseConfig);
            if (isLoop) {
                this.$scope.fast.loop.from = moduleIds[moduleIds.length - 1];
                this.$scope.fast.loop.to = moduleId;
                return moduleIds;
            }
            if (i++ > 10000) {
                throw Error('未找到结束模块: ' + moduleId);
            }
            moduleIds.push(moduleId);
            moduleId = this._loadNextModuleId(game, moduleId, gameBranchChooseConfig);
            if (!moduleId) return moduleIds;
        }
    }

    loopProcessor(moduleId, game, _tempModuleIds, gameBranchChooseConfig) {
        if (!_tempModuleIds[moduleId]) _tempModuleIds[moduleId] = 0;
        _tempModuleIds[moduleId]++;
        if (_tempModuleIds[moduleId] > 1) return true;
        // for (let moduleId in _tempModuleIds) {
        //     if (_tempModuleIds.hasOwnProperty(moduleId) && _tempModuleIds[moduleId] > 1) {
        //         let module = game.data.modules[moduleId];
        //         if (['choose', 'chooseSetPaintingTalk', 'input'].includes(module.type)) {
        //             if (!gameBranchChooseConfig[moduleId]) gameBranchChooseConfig[moduleId] = 0;
        //             gameBranchChooseConfig[moduleId]++;
        //         }
        //         if (module.type === 'condition') {
        //             if (!gameBranchChooseConfig[moduleId]) gameBranchChooseConfig[moduleId] = Object.keys(module.value)[0];
        //             gameBranchChooseConfig[moduleId] = Object.keys(module.value)[1];
        //         }
        //         _tempModuleIds[moduleId] = 1;
        //     }
        // }
    }

    _loadNextModuleId(game, moduleId, gameBranchChooseConfig = {}) {
        let module = game.data.modules[moduleId];
        if (!module) return console.error('module不存在:' + moduleId);
        if (module.type === 'switch-chapter') return;
        if (module.type === 'hotImage') {
            if (typeof module.event === 'object') {
                if (gameBranchChooseConfig.hasOwnProperty(moduleId) && gameBranchChooseConfig[moduleId] >= 0) {
                    return Object.values(module.event)[gameBranchChooseConfig[moduleId]].next;
                }
                if (module.next) return module.next;
                return Object.values(module.event)[0].next;
            }
        }
        if (module.type === 'split') {
            if (gameBranchChooseConfig.hasOwnProperty(moduleId) && gameBranchChooseConfig[moduleId] >= 0) {
                return module.branch_ids[gameBranchChooseConfig[moduleId]];
            }
            if (module.next) return module.next;
            return module.branch_ids[0];
        }
        if (module.next) return module.next;
        if (module.type === 'hint') return module.next;
        if (typeof module.value === 'object') {
            if (gameBranchChooseConfig.hasOwnProperty(moduleId)) {
                if (['choose', 'chooseSetPaintingTalk', 'input'].includes(module.type)) {
                    return Object.values(module.value)[gameBranchChooseConfig[moduleId]];
                }
            }
            return Object.values(module.value)[0];
        }
        if (module.type === 'condition') {
            if (gameBranchChooseConfig.hasOwnProperty(moduleId)) {
                return Object.values(module.wayOut)[gameBranchChooseConfig[moduleId]];
            }
            return Object.values(module.wayOut)[0];
        }
    }

    static parseGameBranchChooseConfig(game, gameBranchChooseConfig) {
        let modules = game.data.modules;
        for (let moduleId in modules) {
            let module = modules[moduleId];
            if (!gameBranchChooseConfig.hasOwnProperty(moduleId)) {
                if (['choose', 'chooseSetPaintingTalk', 'input', 'condition'].includes(module.type)) {
                    gameBranchChooseConfig[moduleId] = 0;
                }
                if (module.type === 'hotImage') {
                    if (!module.next && module.event && Object.keys(module.event).length) {
                        gameBranchChooseConfig[moduleId] = 0;
                    }
                    gameBranchChooseConfig[moduleId] = -1;
                }
            } else {
                if (['choose', 'input'].includes(module.type)) {
                    if (!Number(gameBranchChooseConfig[moduleId])) gameBranchChooseConfig[moduleId] = 0;
                }
            }
        }
        return gameBranchChooseConfig;
    }

    __getGamePropsByType(game, types) {
        let array = [];
        if (typeof types === 'string') types = [types];
        for (let propId in game.data.props) {
            let prop = game.data.props[propId];
            if (types.includes(prop.type)) array.push(prop);
        }
        return array;
    }

    __generateModule(type, missionId) {
        let module = {
            moduleId: this.__generateModuleId(missionId),
            type
        };
        if (['switch-chapter'].includes(type)) {
            module.value = '';
            module.next = '';
        }
        // fix: condition type: 'value', to type: 'object',
        if (['condition'].includes(type)) {
            module.conditions = {
                true0: {
                    value: '',
                    type: 'object',
                    conditionType: 'true',
                    compareValue: 0,
                    compareType: 'number'
                }
            };
            module.wayOut = {
                true0: ''
            };
        }
        // if (['BGM_audio'].includes(type)) {
        //     module.windowStyle = 'window';
        //     module.value = '';
        //     module.next = '';
        // }
        if (['ads'].includes(type)) {
            module.adsDes = '';
            module.value = { "1": '', "2": '' };
            module.branch_ids = [];
            module.name = 'setPaintingTalkChannel';
        }
        if (['video'].includes(type)) {
            module.windowStyle = 'window';
            module.muteGameAudio = false;
            module.autoplay = false;
            module.value = '';
            module.next = '';
        }
        if (['audio'].includes(type)) {
            module.windowStyle = 'window';
            module.value = '';
            module.next = '';
        }
        if (['unlock-mission'].includes(type)) {
            module.windowStyle = 'window';
            module.jumpToUnlockMissionImmediate = false;
            module.value = '';
            module.next = '';
        }

        if (['split'].includes(type)) {
            module.branch_ids = {};
            module.value = '';
            module.next = '';
        }
        if (['richText', 'space'].includes(type)) {
            module.name = '';
            module.textStyle = this.$scope.game.data.props.gameDefault ? 'gameDefault' : '';
            module.value = '';
            module.next = '';
        }
        if (['talk'].includes(type)) {
            module.name = '';
            module.speaker = '';
            module.textStyle = this.$scope.game.data.props.talkDefault ? 'talkDefault' : '';
            module.value = '';
            module.next = '';
        }
        if (['novelStopChoose'].includes(type)) {
            module.value = { default: '' };
            module.UIType = 'novelStopChoose';
        }
        if (['setPaintingTalk'].includes(type)) {
            module.name = 'setPaintingTalkChannel';
            module.speaker = '';
            module.textStyle = this.$scope.game.data.props.setPaintingDefault ? 'setPaintingDefault' : '';
            module.value = '';
            module.next = '';
        }
        if (['image', 'gif'].includes(type)) {
            module.windowStyle = 'window';
            module.textStyle = '';
            module.value = '';
            module.next = '';
        }

        if (['choose'].includes(type)) {
            module.value = { '默认': '' };
            module.forbidRepeatChoose = false;
        }
        if (['input'].includes(type)) {
            module.value = { '默认': '' };
        }
        if (['chooseSetPaintingTalk'].includes(type)) {
            module.name = 'setPaintingTalkChannel';
            module.speaker = '';
            module.nameIndex = '';
            module.nameHeadIndex = '';
            module.headLineValue = '';
            module.NPCAlignment = 'left';
            module.value = { '默认': '' };
            module.event = {};
            module.forbidRepeatChoose = false;
        }

        if (['hint'].includes(type)) {
            module.value = {
                1: {}, 2: {}, 3: {}
            };
            module.next = '';
        }
        if (['award'].includes(type)) {
            module.value = '';
            module.attachment = [];
            module.next = '';
        }
        if (['taskAttachment'].includes(type)) {
            module.value = '';
            module.next = '';
        }
        if (['end'].includes(type)) {
            module.value = '';
            module.showType = 'none';
            module.attachment = [];
            module.next = '';
        }
        if (['GameMainTaskFinished'].includes(type)) {

        }
        if (['DemoMainTaskFinished'].includes(type)) {
            module.value = '';
            module.next = '';
        }
        if (['appMessage'].includes(type)) {
            module.value = '';
            module.name = 'system';
            module.couponName = '';
            module.value = '';
            module.next = '';
        }
        if (['SystemBackgroundBegin'].includes(type)) {
            module.name = 'system';
            module.image = '';
            module.backgroundType = 'image';
            module.next = '';
        }
        if (['SystemBackgroundEnd'].includes(type)) {
            module.name = 'system';
            module.next = '';
        }

        return module;
    }

    __generateModuleId(missionId) {
        let missionMaxIndex = this.$scope.fast.missionMaxIndex[missionId];
        let index = missionMaxIndex + 1;
        let moduleId = `${missionId}_${index}`
        if (!this.$scope.game.data.modules.hasOwnProperty(moduleId)) return moduleId;
        else {
            this.$scope.fast.missionMaxIndex[missionId]++;
            return this.__generateModuleId(missionId);
        }
    }

    __getGameMissionMaxIndex(game) {
        let missionMaxIndex = {};
        for (let moduleId in game.data.modules) {
            let arr = moduleId.split('_');
            let missionId = arr[0];
            let index = Number(arr[1]) || 0;
            if (!missionMaxIndex[missionId]) missionMaxIndex[missionId] = index;
            if (index > missionMaxIndex[missionId]) missionMaxIndex[missionId] = index;
        }
        return missionMaxIndex;
    }
}

GameEditorFastController.$inject = ['$scope', '$rootScope', '$window', '$routeParams', '$timeout', '$event', '$alert', '$modal', '$storage', 'ApiService'];
app.controller('GameEditorFast', GameEditorFastController);

